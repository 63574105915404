import { api } from "service";
import { useQuery } from "react-query";

export default function useTerm({
  type,
}) {

  const getTerm = useQuery(["term", type], () => {
    return api.term.getTerm(type);
  }, {
    refetchOnWindowFocus: false, 
    enabled: !!type
  });

  return {
    getTerm,
  }
}