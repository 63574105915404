import Styles from "./styles";
import ui from "components/ui";

// img
import IconConfirm from "resources/image/icon/icon_confirm.svg";

function FindIdResult({ result, modalClose, language }) {
  return (
    <Styles.Container>
      <div className="icon">
        <img src={IconConfirm} alt="IconConfirm" />
      </div>

      {language ?
        <p>
          찾으시는 아이디는 <br />
          <span>{result}</span> 입니다.
        </p>
        :
        <p>
          The ID you are <br />
          looking for is <span>{result}</span>.
        </p>
      }

      <div className="loginButton">
        <ui.button.BasicButton
          buttonText={language ? "로그인" : "Log in"}
          onClick={() => {
            modalClose();
          }}
        />
      </div>
    </Styles.Container>
  );
}

export { FindIdResult };

export default FindIdResult;
