import components from "..";
import Styles from "./styles";
import utils from "utils";
import { useInvest } from "hooks";
import { ui, layout } from "components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// img
import IconWarning from "resources/image/icon/icon_warning.svg";

function Shareholder() {
  const [filter, setFilter] = useState({});
  
  const navigate = useNavigate();
  const location = useLocation();
  const investInfo = useInvest({
    type: 1,
    investOption: filter,
  });
  
  const categoryData = investInfo.getInvestCategory?.data?.data || [];
  const investData = investInfo.getInvestList?.data || [];

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page || 1,
      category_info_id: param.select || categoryData[0]?.id,
      recordSize: 15,
      pageSize: 5,
    };
    setFilter({ ...f });
  }, [location, categoryData]);

  return (
    <Styles.Container>
      <div className="title">
        주주총회 정보
        <p className="warningText">
          <span className="icon">
            <img src={IconWarning} alt="IconWarning" />
          </span>
          열리지 않을 경우 PDF, CAD, Word를 <br className="mbOnly" />
          설치 후 사용하세요.
        </p>
        <div className="select">
          <ui.input.BasicSelect
            select={categoryData.filter(v=>v.id == filter.category_info_id)[0]?.title}
            setSelect={(e)=>{
              const selectId = categoryData.filter(v=>v.title === e)[0]?.id;
              navigate(utils.etc.getQueryParamUrl({ select: selectId }, false))
            }}
            optionList={categoryData.map(v => { return { text: v.title } })}
          />
        </div>
      </div>

      <div className="table">
        <ul className="thead">
          <li className="listTitle">{categoryData.filter(v=>v.id == filter.category_info_id)[0]?.title}</li>

          <li className="download">다운로드</li>
        </ul>

        {investData.list?.length > 0 ? (
          <>
            <ul className="tbody">
              {investData.list?.map((item, index) => {
                return (
                  <li className="tr" key={"tr" + index}>
                    <components.DownloadList data={item} />
                  </li>
                );
              })}
            </ul>

            {investData.pagination?.totalRecordCountAll > 15 && (
              <div className="pagination">
                <ui.button.Pagination
                  page={investData.pagination?.page}
                  list={investData.pagination?.page_list}
                  maxPage={investData.pagination?.totalPageCount}
                />
              </div>
            )}
          </>
        ) : (
          <layout.EmptyContainer text={"게시글이 존재하지 않습니다."} />
        )}
      </div>
    </Styles.Container>
  );
}

export default Shareholder;
