import styled from "styled-components";

const Container = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  text-align: center;

  & input {
    width: 100%;
    height: 70px;
    border: 1px solid var(--gray01);

    &:focus + .icon {
      transform: translateY(-50%) rotate(180deg);
    }

    // &:focus ~ .option {
    //   display: block;
    // }
  }

  & .icon {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    filter: invert(78%) sepia(13%) saturate(175%) hue-rotate(182deg)
      brightness(94%) contrast(85%);
  }

  & .option {
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    // display: none;
    width: 100%;
    max-height: 140px;
    background-color: #fff;
    border: 1px solid var(--gray01);
    overflow: auto;
    z-index: 3;

    & .optionList {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70px;
      cursor: pointer;
    }
  }

  @media (max-width: 1740px) {
    font-size: calc(100vw * (16 / 1740));

    & input {
      height: calc(100vw * (70 / 1740));
    }

    & .icon {
      right: calc(100vw * (5 / 1740));
      width: calc(100vw * (16 / 1740));
      height: calc(100vw * (16 / 1740));
    }

    & .option {
      max-height: calc(100vw * (140 / 1740));

      & .optionList {
        height: calc(100vw * (70 / 1740));
      }
    }
  }

  @media (max-width: 768px) {
    font-size: calc(100vw * (16 / 375));

    & input {
      height: calc(100vw * (70 / 375));
    }

    & .icon {
      right: calc(100vw * (5 / 375));
      width: calc(100vw * (16 / 375));
      height: calc(100vw * (16 / 375));
    }

    & .option {
      max-height: calc(100vw * (140 / 375));

      & .optionList {
        height: calc(100vw * (70 / 375));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
