import { useQuery } from "react-query";
import { api } from "service";

export default function useMorgan({
  ERP_listOption,
  ERP_detailOption,
  EPS_listOption,
  EPS_detailOption,
}) {

  const ERP_listQuery = useQuery(["ERP_list", ERP_listOption], () => {
    return api.morgan.ERP_getList(ERP_listOption);
  }, {
    enabled: !!ERP_listOption,
    refetchOnWindowFocus: false,
  });

  const ERP_detailQuery = useQuery(["ERP_detail", ERP_detailOption], () => {
    return api.morgan.ERP_getDetail(ERP_detailOption);
  }, {
    enabled: !!ERP_detailOption,
    refetchOnWindowFocus: false,
  });

  const EPS_listQuery = useQuery(["EPS_list", EPS_listOption], () => {
    return api.morgan.EPS_getList(EPS_listOption);
  }, {
    enabled: !!EPS_listOption,
    refetchOnWindowFocus: false,
  });

  const EPS_detailQuery = useQuery(["EPS_detail", EPS_detailOption], () => {
    return api.morgan.EPS_getDetail(EPS_detailOption);
  }, {
    enabled: !!EPS_detailOption,
    refetchOnWindowFocus: false,
  });


	return {
		ERP_listQuery,
    ERP_detailQuery,
    EPS_listQuery,
    EPS_detailQuery,
	};
}