import { useQuery } from "react-query";
import { api } from "service";

export default function useInvest({
  type,
  investOption,
}) {

  
  const getInvestCategory = useQuery(["investCategory", type], () => {
    return api.invest.getInvestCategory(type);
  }, {
    enabled: !!type,
    refetchOnWindowFocus: false,
  });

  const getInvestList = useQuery(["investList", investOption], () => {
    return api.invest.getInvestList({ ...investOption, type: type });
  }, {
    enabled: !!investOption.category_info_id,
    refetchOnWindowFocus: false,
  });


	return {
    getInvestCategory,
    getInvestList,
	};
}