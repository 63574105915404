import { useQuery } from "react-query";
import { api } from "service";

export default function useAgency({
  type
}) {

  const getAgency = useQuery(["agency", type], () => {
    return api.agency.getAgency(type);
  }, {
    enabled: !!type,
    refetchOnWindowFocus: false,
  });


	return {
		getAgency,
	};
}