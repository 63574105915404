import styled from "styled-components";

// img
import BgMainBanner from "resources/image/search/bg_main_banner.jpg";
import BgMainBannerMb from "resources/image/search/bg_main_banner_mb.jpg";

const Container = styled.div`
  padding: 80px 0 200px;
  min-height: 100vh;

  & .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 280px;
    background: url(${BgMainBanner}) no-repeat center / cover;

    & .narrowInner {
      display: flex;

      & .input {
        width: calc(100% - 133px);

        & input {
          height: 70px;
          background-color: #fff;
          border-right: 0;
        }
      }

      & .searchButton {
        width: 133px;
      }
    }
  }

  & .searchSection {
    & .narrowInner {
      padding-top: 80px;
      margin: 0 auto;

      & h2 {
        padding-bottom: 20px;
        font-size: 36px;
        font-weight: bold;
        border-bottom: 3px solid var(--black);
      }
    }
  }

  @media (max-width: 1740px) {
    padding: calc(100vw * (80 / 1740)) 0 calc(100vw * (200 / 1740));

    & .banner {
      height: calc(100vw * (280 / 1740));

      & .narrowInner {
        & .input {
          width: calc(100% - calc(100vw * (133 / 1740)));

          & input {
            height: calc(100vw * (70 / 1740));
          }
        }

        & .searchButton {
          width: calc(100vw * (133 / 1740));
        }
      }
    }

    & .searchSection {
      & .narrowInner {
        padding-top: calc(100vw * (80 / 1740));

        & h2 {
          padding-bottom: calc(100vw * (20 / 1740));
          font-size: calc(100vw * (36 / 1740));
          border-bottom: calc(100vw * (3 / 1740)) solid var(--black);
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (50 / 375)) 0 calc(100vw * (150 / 375));

    & .banner {
      height: calc(100vw * (310 / 375));
      background-image: url(${BgMainBannerMb});

      & .narrowInner {
        & .input {
          width: calc(100% - calc(100vw * (80 / 375)));

          & input {
            height: calc(100vw * (70 / 375));
          }
        }

        & .searchButton {
          width: calc(100vw * (80 / 375));
        }
      }
    }

    & .searchSection {
      & .narrowInner {
        padding-top: calc(100vw * (50 / 375));

        & h2 {
          padding-bottom: calc(100vw * (20 / 375));
          font-size: calc(100vw * (24 / 375));
          border-bottom: calc(100vw * (3 / 375)) solid var(--black);
        }
      }
    }
  }
`;

export { Container };

export default { Container };
