import connector from "service/connector"
import utils from "utils";


/* -- User-unsign -- */
// 닉네임 중복체크
const getCheckName = async (path) => {
  return await connector.connectFetchController("check/nickname?nickname=" + path, "GET");
};
// 이메일 인증
const emailRegist = async (filter) => {
  return await connector.connectFetchController("email/regist" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
};
// 아이디 찾기
const findId = async (filter) => {
  return await connector.connectFetchController("find/username" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
};
// 로그인
const login = async (data) => {
  return await connector.connectFetchController("login", "POST", data);
};
// 비밀번호 변경 ( 직접 )
const changePassword = async (filter) => {
  // return await connector.authConnector("pwd/change" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
  return await connector.authConnector("pwd/change", "POST", filter);
};
// 임시 비밀번호 변경
const passwordReset = async (filter) => {
  return await connector.connectFetchController("pwd/reset", "POST", filter);
  // return await connector.connectFetchController("pwd/reset" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
};
// 회원가입
const signUp = async (data) => {
  return await connector.authConnector("sign", "POST", data);
};


/* -- User-sign -- */
// 현재 로그인 된 아이디 정보 호출
const getUser = async () => {
  return await connector.authConnector("user", "GET");
};
// 회원상세 정보
const getUserDetail = async (path) => {
  return await connector.authConnector("user/detail?id=" + path, "GET");
};
// 비밀번호 변경
// const changePassword = async (data) => {
//   return await connector.authConnector("user/pwd", "PUT", data);
// };
// 회원정보 변경
const userUpdate = async (data) => {
  return await connector.authConnector("user/update", "POST", data)
}
// 회원탈퇴
const resign = async (data) => {
  return await connector.authConnector("user/withdraw", "POST", data);
};


export {
  getCheckName,
  emailRegist,
  findId,
  login,
  changePassword,
  passwordReset,
  signUp,

  getUser,
  getUserDetail,
  // changePassword,
  userUpdate,
  resign,
}

export default {
  getCheckName,
  emailRegist,
  findId,
  login,
  changePassword,
  passwordReset,
  signUp,

  getUser,
  getUserDetail,
  // changePassword,
  userUpdate,
  resign,
}