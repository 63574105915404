import styled from "styled-components";

const Container = styled.div`
  padding: 80px 0 200px;
  min-height: 100vh;

  & section {
    padding-top: 80px;

    & .logo {
      width: 262px;
      height: 76px;
      margin: 0 auto 20px;
    }

    & h2,
    .title {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
    }

    & h2 {
      margin-bottom: 80px;
    }

    & .title {
      margin-bottom: 20px;
    }

    & .inputWrap .inputList:not(:last-child) {
      margin-bottom: 40px;
    }

    & .affiliation {
      margin-bottom: 80px;
    }

    & .personal {
      margin-bottom: 50px;
    }

    & .terms {
      position: relative;
      margin-bottom: 50px;

      & .termsWrap {
        padding: 35px;
        background-color: var(--gray03);

        & .checkList {
          display: flex;
          align-items: center;
          justify-content: space-between;

          & a {
            font-size: 16px;
            font-weight: bold;
            color: var(--gray01);
            line-height: 1;
            border-bottom: 1px solid var(--gray01);
          }

          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }

      & .errorText {
        position: absolute;
        top: calc(100% + 5px);
        left: 0;
        font-size: 16px;
        color: var(--red);
      }
    }
  }

  @media (max-width: 1740px) {
    padding: calc(100vw * (80 / 1740)) 0 calc(100vw * (200 / 1740));

    & section {
      padding-top: calc(100vw * (80 / 1740));

      & .logo {
        width: calc(100vw * (262 / 1740));
        height: calc(100vw * (76 / 1740));
        margin: 0 auto calc(100vw * (20 / 1740));
      }

      & h2,
      .title {
        font-size: calc(100vw * (24 / 1740));
      }

      & h2 {
        margin-bottom: calc(100vw * (80 / 1740));
      }

      & .title {
        margin-bottom: calc(100vw * (20 / 1740));
      }

      & .inputWrap .inputList:not(:last-child) {
        margin-bottom: calc(100vw * (40 / 1740));
      }

      & .affiliation {
        margin-bottom: calc(100vw * (80 / 1740));
      }

      & .personal {
        margin-bottom: calc(100vw * (50 / 1740));
      }

      & .terms {
        margin-bottom: calc(100vw * (50 / 1740));

        & .termsWrap {
          padding: calc(100vw * (35 / 1740));

          & .checkList {
            & a {
              font-size: calc(100vw * (16 / 1740));
            }

            &:not(:last-child) {
              margin-bottom: calc(100vw * (20 / 1740));
            }
          }
        }

        & .errorText {
          top: calc(100% + calc(100vw * (5 / 1740)));
          font-size: calc(100vw * (16 / 1740));
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (50 / 375)) 0 calc(100vw * (150 / 375));

    & section {
      padding-top: calc(100vw * (50 / 375));

      & .logo {
        width: calc(100vw * (158 / 375));
        height: calc(100vw * (46 / 375));
        margin: 0 auto calc(100vw * (20 / 375));
      }

      & h2,
      .title {
        font-size: calc(100vw * (18 / 375));
      }

      & h2 {
        margin-bottom: calc(100vw * (50 / 375));
      }

      & .title {
        margin-bottom: calc(100vw * (20 / 375));
      }

      & .inputWrap .inputList:not(:last-child) {
        margin-bottom: calc(100vw * (30 / 375));
      }

      & .affiliation,
      .personal,
      .terms {
        margin-bottom: calc(100vw * (50 / 375));
      }

      & .terms {
        & .termsWrap {
          padding: calc(100vw * (30 / 375)) calc(100vw * (17 / 375));

          & .checkList {
            gap: calc(100vw * (50 / 375));
            word-break: keep-all;

            & a {
              flex-shrink: 0;
              font-size: calc(100vw * (16 / 375));
            }

            &:not(:last-child) {
              margin-bottom: calc(100vw * (20 / 375));
            }
          }
        }

        & .errorText {
          top: calc(100% + calc(100vw * (5 / 375)));
          font-size: calc(100vw * (14 / 375));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
