import { useQuery } from "react-query";
import { api } from "service";

export default function usePatent({
    type
}){

    const getPatent = useQuery(["patent", type], () => {
      return api.patent.getPatent(type);
    }, {
      enabled:!!type,
      refetchOnWindowFocus: false,
    });


    return {
        getPatent, 
    };
}