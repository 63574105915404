import styled from "styled-components";

const Container = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  background-color: var(--main);
  z-index: 5;

  & .prevButton,
  .homeButton {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
  }

  & .homeButton {
    display: flex;
    align-items: center;
    gap: 5px;

    & .icon {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 768px) {
    height: calc(100vw * (50 / 375));
    padding: 0 calc(100vw * (20 / 375));

    & .prevButton,
    .homeButton {
      font-size: calc(100vw * (14 / 375));
    }

    & .homeButton {
      gap: calc(100vw * (5 / 375));

      & .icon {
        width: calc(100vw * (20 / 375));
        height: calc(100vw * (20 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
