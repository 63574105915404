import styled from "styled-components";

// img
import BgPay from "resources/image/recruitment/bg_pay.jpg";
import BgPayMb from "resources/image/recruitment/bg_pay_mb.jpg";
import IconArrowTriangle from "resources/image/icon/icon_arrow_triangle.svg";
import IconPlus from "resources/image/icon/icon_plus.svg";

const Container = styled.div`
  padding: 80px 0 200px;
  min-height: 100vh;

  & .inner {
    display: flex;
    gap: 80px;

    & .narrowInner {
      & section {
        &:not(:last-child) {
          margin-bottom: 100px;
        }

        &:not(:first-child) h2 {
          font-size: 20px;
        }
      }

      & .stepSection {
        & .step {
          display: flex;
          gap: 50px;

          &.step01 .stepList:last-child::after {
            top: calc(100% + 12px);
            left: 50%;
            transform: translateX(-50%) rotate(90deg);
          }

          &.step02 {
            flex-direction: row-reverse;
            margin-top: 50px;

            & .stepList {
              &:last-child {
                font-weight: 600;
                color: #fff;
                background-color: var(--main);
                border-color: var(--main);
              }

              &:not(:first-child)::after {
                display: none;
              }

              &:first-child::after {
                left: auto;
                right: calc(100% + 12px);
                transform: translateY(-50%) rotate(180deg);
              }
            }
          }

          & .stepList {
            position: relative;
            display: flex;
            flex-direction: column;
            width: calc(calc(100% - 100px) / 3);
            height: 320px;
            padding: 30px;
            border: 1px solid var(--gray02);
            border-radius: 20px;

            &::after {
              position: absolute;
              top: 50%;
              left: calc(100% + 12px);
              transform: translateY(-50%);
              width: 26px;
              height: 26px;
              background: url(${IconArrowTriangle}) no-repeat center / cover;
              content: "";
            }

            & .icon {
              width: 95px;
              height: 95px;
              margin-left: auto;
            }

            & .listTitle {
              margin: auto 0 19px;
              font-size: 24px;
              font-weight: bold;
            }

            & p {
              font-size: 16px;
              line-height: 1.5;

              &.color {
                color: var(--gray01);
              }
            }
          }
        }
      }

      & .talentSection {
        & .talent {
          display: flex;
          gap: 50px;
          margin-bottom: 50px;

          & .talentList {
            width: calc(calc(100% - 100px) / 3);

            & .img {
              height: 270px;
              margin-bottom: 20px;
            }

            & .listTitle {
              margin-bottom: 19px;
              font-size: 24px;
              font-weight: bold;
            }

            & .description {
              font-size: 16px;
              line-height: 1.5;
            }
          }
        }

        & .typeTitle {
          margin-bottom: 20px;
          font-size: 20px;
          font-weight: bold;
        }

        & .typeWrap {
          padding: 22px;
          border: 1px solid var(--gray02);
          border-radius: 20px;

          & .type {
            display: flex;
            gap: 157px;
            width: fit-content;
            margin: 0 auto;
            padding: 17px 22px;
            background-color: rgba(234, 85, 4, 0.2);
            border-radius: 500px;

            & .typeList {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 126px;
              height: 126px;
              font-size: 24px;
              font-weight: 600;
              color: #fff;
              background-color: var(--main);
              border-radius: 50%;
            }
          }
        }
      }

      & .dutySection {
        & .duty:not(:last-child) {
          margin-bottom: 100px;
        }

        & .listTitle {
          margin-bottom: 30px;
          font-size: 36px;
          font-weight: bold;
          line-height: 1.38;
        }

        & .description {
          margin-bottom: 20px;
          line-height: 1.6;
        }

        & .tag {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin-bottom: 50px;
        
          & .tagList {
            padding: 10px 20px;
            font-size: 16px;
            font-weight: 600;
            color: var(--gray01);
            background-color: var(--gray03);
            border-radius: 500px;
            text-align: center;
          }
        }

        & .img {
          height: 350px;
        }
      }

      & .evaluationSection {
        & .evaluation {
          display: flex;
          gap: 50px;

          & .evaluationList {
            width: calc(calc(100% - 100px) / 3);
            height: 320px;
            padding: 30px 30px 0;
            border: 1px solid var(--gray02);
            border-radius: 20px;

            & .icon {
              width: 95px;
              height: 95px;
              margin: 0 0 7px auto;
            }

            & .listTitle {
              margin-bottom: 19px;
              font-size: 24px;
              font-weight: bold;
            }

            & li {
              margin-left: 11px;
              font-size: 16px;
              word-break: keep-all;
              list-style: "- " outside;
              line-height: 1.5;
            }
          }
        }
      }

      & .paySection {
        & .pay {
          color: #fff;
          padding: 99px 0;
          text-align: center;
          background: url(${BgPay}) no-repeat center / cover;

          & .payTitle {
            margin-bottom: 50px;
            font-size: 36px;
            font-weight: bold;
            line-height: 1.38;
          }

          & .payListWrap {
            display: flex;
            justify-content: center;
            gap: 130px;

            & .payList:not(:last-child) .iconWrap::after {
              position: absolute;
              top: 50%;
              left: calc(100% + 49px);
              transform: translateY(-50%);
              width: 32px;
              height: 32px;
              background: url(${IconPlus}) no-repeat center / cover;
              content: "";
            }

            & .iconWrap {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 182px;
              height: 182px;
              margin-bottom: 20px;
              border: 1px solid #fff;
              border-radius: 50%;

              & .icon {
                width: 80px;
                height: 80px;
              }
            }

            & .listTitle {
              margin-bottom: 19px;
              font-size: 24px;
              font-weight: bold;
            }

            & .description {
              font-size: 16px;
              line-height: 1.5;
            }
          }
        }
      }

      & .benefitSection {
        & .img {
          height: 270px;
        }
      }
    }
  }

  @media (max-width: 1740px) {
    padding: calc(100vw * (80 / 1740)) 0 calc(100vw * (200 / 1740));

    & .inner {
      gap: calc(100vw * (80 / 1740));

      & .narrowInner {
        & section {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (100 / 1740));
          }

          &:not(:first-child) h2 {
            font-size: calc(100vw * (20 / 1740));
          }
        }

        & .stepSection {
          & .step {
            gap: calc(100vw * (50 / 1740));

            &.step01 .stepList:last-child::after {
              top: calc(100% + calc(100vw * (12 / 1740)));
            }

            &.step02 {
              margin-top: calc(100vw * (50 / 1740));

              & .stepList {
                &:first-child::after {
                  right: calc(100% + calc(100vw * (12 / 1740)));
                }
              }
            }

            & .stepList {
              width: calc(calc(100% - calc(100vw * (100 / 1740))) / 3);
              height: calc(100vw * (320 / 1740));
              padding: calc(100vw * (30 / 1740));
              border-radius: calc(100vw * (20 / 1740));

              &::after {
                left: calc(100% + calc(100vw * (12 / 1740)));
                width: calc(100vw * (26 / 1740));
                height: calc(100vw * (26 / 1740));
              }

              & .icon {
                width: calc(100vw * (95 / 1740));
                height: calc(100vw * (95 / 1740));
              }

              & .listTitle {
                margin: auto 0 calc(100vw * (19 / 1740));
                font-size: calc(100vw * (24 / 1740));
              }

              & p {
                font-size: calc(100vw * (16 / 1740));
              }
            }
          }
        }

        & .talentSection {
          & .talent {
            gap: calc(100vw * (50 / 1740));
            margin-bottom: calc(100vw * (50 / 1740));

            & .talentList {
              width: calc(calc(100% - calc(100vw * (100 / 1740))) / 3);

              & .img {
                height: calc(100vw * (270 / 1740));
                margin-bottom: calc(100vw * (20 / 1740));
              }

              & .listTitle {
                margin-bottom: calc(100vw * (19 / 1740));
                font-size: calc(100vw * (24 / 1740));
              }

              & .description {
                font-size: calc(100vw * (16 / 1740));
              }
            }
          }

          & .typeTitle {
            margin-bottom: calc(100vw * (20 / 1740));
            font-size: calc(100vw * (20 / 1740));
          }

          & .typeWrap {
            padding: calc(100vw * (22 / 1740));
            border-radius: calc(100vw * (20 / 1740));

            & .type {
              gap: calc(100vw * (157 / 1740));
              padding: calc(100vw * (17 / 1740)) calc(100vw * (22 / 1740));
              border-radius: calc(100vw * (500 / 1740));

              & .typeList {
                width: calc(100vw * (126 / 1740));
                height: calc(100vw * (126 / 1740));
                font-size: calc(100vw * (24 / 1740));
              }
            }
          }
        }

        & .dutySection {
          & .duty:not(:last-child) {
            margin-bottom: calc(100vw * (100 / 1740));
          }

          & .listTitle {
            margin-bottom: calc(100vw * (30 / 1740));
            font-size: calc(100vw * (36 / 1740));
          }

          & .description {
            margin-bottom: calc(100vw * (20 / 1740));
          }

          & .tag {
            gap: calc(100vw * (10 / 1740));
            margin-bottom: calc(100vw * (50 / 1740));

            & .tagList {
              padding: calc(100vw * (10 / 1740)) calc(100vw * (20 / 1740));
              font-size: calc(100vw * (16 / 1740));
              border-radius: calc(100vw * (500 / 1740));
              max-width: calc(50% - calc(100vw * (10 / 1740)));
            }
          }

          & .img {
            height: calc(100vw * (350 / 1740));
          }
        }

        & .evaluationSection {
          & .evaluation {
            gap: calc(100vw * (50 / 1740));

            & .evaluationList {
              width: calc(calc(100% - calc(100vw * (100 / 1740))) / 3);
              height: calc(100vw * (320 / 1740));
              padding: calc(100vw * (30 / 1740)) calc(100vw * (30 / 1740)) 0;
              border-radius: calc(100vw * (20 / 1740));

              & .icon {
                width: calc(100vw * (95 / 1740));
                height: calc(100vw * (95 / 1740));
                margin: 0 0 calc(100vw * (7 / 1740)) auto;
              }

              & .listTitle {
                margin-bottom: calc(100vw * (19 / 1740));
                font-size: calc(100vw * (24 / 1740));
              }

              & li {
                margin-left: calc(100vw * (11 / 1740));
                font-size: calc(100vw * (16 / 1740));
              }
            }
          }
        }

        & .paySection {
          & .pay {
            padding: calc(100vw * (99 / 1740)) 0;

            & .payTitle {
              margin-bottom: calc(100vw * (50 / 1740));
              font-size: calc(100vw * (36 / 1740));
            }

            & .payListWrap {
              gap: calc(100vw * (130 / 1740));

              & .payList:not(:last-child) .iconWrap::after {
                left: calc(100% + calc(100vw * (49 / 1740)));
                width: calc(100vw * (32 / 1740));
                height: calc(100vw * (32 / 1740));
              }

              & .iconWrap {
                width: calc(100vw * (182 / 1740));
                height: calc(100vw * (182 / 1740));
                margin-bottom: calc(100vw * (20 / 1740));

                & .icon {
                  width: calc(100vw * (80 / 1740));
                  height: calc(100vw * (80 / 1740));
                }
              }

              & .listTitle {
                margin-bottom: calc(100vw * (19 / 1740));
                font-size: calc(100vw * (24 / 1740));
              }

              & .description {
                font-size: calc(100vw * (16 / 1740));
              }
            }
          }
        }

        & .benefitSection {
          & .img {
            height: calc(100vw * (270 / 1740));
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (50 / 375)) 0 calc(100vw * (150 / 375));

    & .inner {
      flex-direction: column;
      gap: calc(100vw * (50 / 375));
      width: 100%;

      & .narrowInner {
        & section {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (80 / 375));
          }

          &:not(:first-child) h2 {
            font-size: calc(100vw * (16 / 375));
          }
        }

        & .scroll-container {
          margin-bottom: calc(100vw * (20 / 375));
        }

        & .stepSection {
          & .step {
            flex-direction: column;
            gap: calc(100vw * (46 / 375));

            &.step01 .stepList:last-child::after {
              top: calc(100% + calc(100vw * (10 / 375)));
            }

            &.step02 {
              flex-direction: column;
              margin-top: calc(100vw * (46 / 375));

              & .stepList {
                &:first-child::after {
                  right: auto;
                  left: 50%;
                  transform: translateX(-50%) rotate(90deg);
                }
              }
            }

            & .stepList {
              width: 100%;
              height: calc(100vw * (290 / 375));
              padding: calc(100vw * (20 / 375)) calc(100vw * (20 / 375));
              border-radius: calc(100vw * (20 / 375));

              &::after {
                top: calc(100% + calc(100vw * (10 / 375)));
                left: 50%;
                transform: translateX(-50%) rotate(90deg);
                width: calc(100vw * (26 / 375));
                height: calc(100vw * (26 / 375));
              }

              & .icon {
                width: calc(100vw * (75 / 375));
                height: calc(100vw * (75 / 375));
              }

              & .listTitle {
                margin: auto 0 calc(100vw * (19 / 375));
                font-size: calc(100vw * (18 / 375));
              }

              & p {
                font-size: calc(100vw * (14 / 375));
              }
            }
          }
        }

        & .talentSection {
          & .talent {
            flex-direction: column;
            gap: calc(100vw * (30 / 375));
            margin-bottom: calc(100vw * (30 / 375));

            & .talentList {
              width: 100%;

              & .img {
                height: calc(100vw * (221 / 375));
                margin-bottom: calc(100vw * (20 / 375));
              }

              & .listTitle {
                margin-bottom: calc(100vw * (10 / 375));
                font-size: calc(100vw * (18 / 375));
              }

              & .description {
                font-size: calc(100vw * (14 / 375));
              }
            }
          }

          & .typeTitle {
            margin-bottom: calc(100vw * (10 / 375));
            font-size: calc(100vw * (14 / 375));
          }

          & .typeWrap {
            padding: calc(100vw * (20 / 375));
            border-radius: calc(100vw * (20 / 375));

            & .type {
              flex-direction: column;
              gap: calc(100vw * (50 / 375));
              padding: calc(100vw * (17 / 375));
              border-radius: calc(100vw * (500 / 375));

              & .typeList {
                width: calc(100vw * (126 / 365));
                height: calc(100vw * (126 / 365));
                font-size: calc(100vw * (18 / 365));
              }
            }
          }
        }

        & .dutySection {
          & .duty:not(:last-child) {
            margin-bottom: calc(100vw * (50 / 375));
          }

          & .listTitle {
            margin-bottom: calc(100vw * (30 / 375));
            font-size: calc(100vw * (24 / 375));
          }

          & .description {
            margin-bottom: calc(100vw * (20 / 375));
          }

          & .tag {
            flex-wrap: wrap;
            gap: calc(100vw * (10 / 375));
            margin-bottom: calc(100vw * (30 / 375));

            & .tagList {
              padding: calc(100vw * (10 / 375));
              font-size: calc(100vw * (14 / 375));
              border-radius: calc(100vw * (500 / 375));
              max-width: calc(50% - calc(100vw * (10 / 375)));
            }
          }

          & .img {
            height: calc(100vw * (88 / 375));
          }
        }

        & .evaluationSection {
          & .evaluation {
            flex-direction: column;
            gap: calc(100vw * (20 / 375));

            & .evaluationList {
              width: 100%;
              height: calc(100vw * (307 / 375));
              padding: calc(100vw * (20 / 375));
              border-radius: calc(100vw * (20 / 375));

              & .icon {
                width: calc(100vw * (95 / 375));
                height: calc(100vw * (95 / 375));
                margin: 0 0 calc(100vw * (17 / 375)) auto;
              }

              & .listTitle {
                margin-bottom: calc(100vw * (10 / 375));
                font-size: calc(100vw * (18 / 375));
              }

              & li {
                margin-left: calc(100vw * (11 / 375));
                font-size: calc(100vw * (14 / 375));
              }
            }
          }
        }

        & .paySection {
          & .pay {
            padding: calc(100vw * (73 / 375)) 0;
            background-image: url(${BgPayMb});

            & .payTitle {
              margin-bottom: calc(100vw * (30 / 375));
              font-size: calc(100vw * (24 / 375));
            }

            & .payListWrap {
              flex-direction: column;
              gap: calc(100vw * (62 / 375));

              & .payList:not(:last-child) .iconWrap::after {
                top: calc(100% + calc(100vw * (122 / 375)));
                left: 50%;
                transform: translateX(-50%);
                width: calc(100vw * (22 / 375));
                height: calc(100vw * (22 / 375));
              }

              & .iconWrap {
                width: calc(100vw * (142 / 375));
                height: calc(100vw * (142 / 375));
                margin: 0 auto calc(100vw * (10 / 375));

                & .icon {
                  width: calc(100vw * (62 / 375));
                  height: calc(100vw * (62 / 375));
                }
              }

              & .listTitle {
                margin-bottom: calc(100vw * (10 / 375));
                font-size: calc(100vw * (18 / 375));
              }

              & .description {
                font-size: calc(100vw * (14 / 375));
              }
            }
          }
        }

        & .benefitSection {
          & .img {
            height: calc(100vw * (270 / 375));
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
