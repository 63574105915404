import Styles from "./styles";
import components from "./components";
import { useMorgan } from "hooks";
import { common, ui } from "components";
import { useNavigate, useParams } from "react-router-dom";

function MorganDetailPage() {
  const navigate = useNavigate();
  const param = useParams();

  const morganInfo = useMorgan({
    ERP_detailOption: param?.type === "ERP" && { ord_detail: param.id },
    EPS_detailOption: param?.type === "EPS" && { ord_detail: param.id },
  });
  const data = param?.type === "ERP"
    ? morganInfo.ERP_detailQuery?.data?.data || []
    : morganInfo.EPS_detailQuery?.data?.data || []

  const erpData = [
    {
      specTitle: "기계제작 사양서",
      specList: [
        {
          title: "ACTUATOR 사양",
          list: [
            {
              title: "작업번호",
              text: data.작업번호
            },
            {
              title: "시리얼번호",
              text: data.시리얼번호
            },
            {
              title: "모델명",
              text: data.모델명
            },
            {
              title: "TYPE",
              text: data.type
            },
            {
              title: "수량",
              text: data.수량
            },
            {
              title: "발주처",
              text: data.발주처
            },
            {
              title: "건명",
              text: data.건명
            },
            {
              title: "핸들 우회전시",
              text: data.핸들_우회전시
            },
            {
              title: "MOTOR",
              text: data.motor
            },
            {
              title: "보호구조",
              text: data.보호구조
            },
            {
              title: "RPM",
              text: data.rpm
            },
            {
              title: "WORM A/B GEAR",
              text: data.worm_AB_GEAR
            },
            {
              title: "수동감속기",
              text: data.수동감속기
            },
            {
              title: "개폐시간",
              text: data.개폐시간
            },
            {
              title: "토크값(MAX\n/OPEN/CLOSE)",
              text: data.토크값_MAX_OPEN_CLOSE
            },
            {
              title: "토크스프링",
              text: data.토크스프링
            },
            {
              title: "재질",
              text: data.재질
            },
            {
              title: "내경가공",
              text: data.내경가공
            },
            {
              title: "KEY WAY",
              text: data.key_WAY
            },
            {
              title: "STEM COVER",
              text: data.stem_COVER
            },
          ],
          text: data.작업번호
        },
        {
          title: "밸브 사양",
          list: [
            {
              title: "밸브TAG",
              text: data.밸브TAG
            },
            {
              title: "밸브서비스",
              text: data.밸브서비스
            },
            {
              title: "밸브타입",
              text: data.밸브타입
            },
            {
              title: "밸브사이즈",
              text: data.밸브사이즈
            },
            {
              title: "STEM DIA",
              text: data.stem_DIA
            },
            {
              title: "STROKE",
              text: data.stroke
            },
            {
              title: "나사방향",
              text: data.나사방향
            },
            {
              title: "나사LEAD",
              text: data.나사LEAD
            },
            {
              title: "줄 수",
              text: data.줄수
            },
            {
              title: "TPI",
              text: data.tpi
            },
            {
              title: "밸브구입 유/무",
              text: data.밸브구입_유_무
            },
          ],
          text: data.작업번호
        },
        {
          title: "개도계사양",
          list: [
            {
              title: "출력회전수",
              text: data.출력회전수
            },
            {
              title: "개도계각도",
              text: data.개도계각도
            },
            {
              title: "개도계 감속비",
              text: data.개도계_감속비
            },
            {
              title: "리미트 S/W",
              text: data.리미트_S_W
            },
            {
              title: "토크 S/W",
              text: data.토크_S_W
            },
            {
              title: "POTENTIOMETER",
              text: data.potentiometer
            },
            {
              title: "R/I CONVERTER",
              text: data.ri_CONVERTER_1
            },
            {
              title: "저항",
              text: data.저항
            },
            {
              title: "ARRESTER",
              text: data.arrester
            },
            {
              title: "비례제어",
              text: data.비례제어
            },
            {
              title: "POT GEAR",
              text: data.pot_GEAR
            },
          ],
          text: data.작업번호
        },
        {
          title: "공급전원사양",
          list: [
            {
              title: "POWER",
              text: data.power
            },
            {
              title: "MC",
              text: data.mc
            },
            {
              title: "CONTROL",
              text: data.control
            },
            {
              title: "DC",
              text: data.dc
            },
            {
              title: "HEATER",
              text: data.heater
            },
            {
              title: "용량",
              text: data.용량
            },
            {
              title: "R/I CONVERTER",
              text: data.ri_CONVERTER_2
            },
            {
              title: "CABLE ENTRY",
              text: data.cable_ENTRY
            },
            {
              title: "페인트",
              text: data.페인트
            },
            {
              title: "메모1",
              text: data.메모1
            },
            {
              title: "메모2",
              text: data.메모2
            },
            {
              title: "메모3",
              text: data.메모3
            },
            {
              title: "메모4",
              text: data.메모4
            },
            {
              title: "개정내역1",
              text: data.개정내역1
            },
            {
              title: "개정내역2",
              text: data.개정내역2
            },
            {
              title: "개정내역3",
              text: data.개정내역3
            },
          ],
          text: data.작업번호
        },
        {
          title: "감속기사양",
          list: [
            {
              title: "감속기종류",
              text: data.감속기종류
            },
            {
              title: "감속기모델",
              text: data.감속기모델
            },
            {
              title: "단동연동",
              text: data.단동연동
            },
            {
              title: "스텐드",
              text: data.밸브TAG
            },
            {
              title: "조인트",
              text: data.조인트
            },
            {
              title: "감속기 KEY",
              text: data.감속기_KEY
            },
          ],
          text: data.작업번호
        },
      ],
    },
    {
      specTitle: "전기제작 사양서",
      specList: [
        {
          title: "전기사양",
          list: [
            {
              title: "회로도 번호",
              text: data.회로도_번호
            },
            {
              title: "LIMIT S/W",
              text: data.limit_SW
            },
            {
              title: "TORQUE S/W",
              text: data.torque_SW
            },
            {
              title: "SPACE HEATER",
              text: data.space_HEATER
            },
            {
              title: "TERMINAL",
              text: data.terminal
            },
          ],
          text: data.작업번호
        },
        {
          title: "옵션",
          list: [
            {
              title: "R/I CONVERTER",
              text: data.ri_CONVERTER_3
            },
            {
              title: "ARRESTER",
              text: data.e_ARRESTER
            },
            {
              title: "E_POSITIONER",
              text: data.e_POSITIONER
            },
          ],
          text: data.작업번호
        },
        {
          title: "CONTROLLER",
          subCategory: [
            {
              subCategoryTitle: "INTEGRAL",
              list: [
                {
                  title: "TRANSFORMER",
                  text: data.int_TRANSFORMER
                },
                {
                  title: "정역 CONTACTOR",
                  text: data.int_정역_CONTACTOR
                },
                {
                  title: "상보호기",
                  text: data.int_상보호기
                },
                {
                  title: "PIN-CONNECTOR",
                  text: data.int_PIN_CONNECTOR
                },
                {
                  title: "OPERATING PCB",
                  text: data.int_OPERATING_PCB
                },
                {
                  title: "LAMP",
                  text: data.int_LAMP
                },
                {
                  title: "조작 S/W",
                  text: data.int_조작_SW
                },
                {
                  title: "FUSE",
                  text: data.int_FUSE
                },
                {
                  title: "INTERPOSING RELAY",
                  text: data.int_INTERPOSING_RELAY
                },
                {
                  title: "모터보호계전기",
                  text: data.int_모터보호계전기
                },
                {
                  title: "SPARK KILLER",
                  text: data.int_SPARK_KILLER
                },
                {
                  title: "CONDENSER",
                  text: data.int_CONDENSER
                },
              ],
            },
            {
              subCategoryTitle: "SEMI INTEGRAL",
              list: [
                {
                  title: "PIN-CONNECTOR",
                  text: data.semi_PIN_CONNECTOR
                },
                {
                  title: "OPERATING PCB",
                  text: data.semi_OPERATING_PCB
                },
                {
                  title: "조작 S/W",
                  text: data.semi_조작_SW
                },
                {
                  title: "LAMP",
                  text: data.semi_LAMP
                },
              ],
            },
            {
              subCategoryTitle: "LOCAL PANEL",
              list: [
                {
                  title: "PANEL 재질",
                  text: data.local_PANEL_재질
                },
                {
                  title: "PANEL 형식",
                  text: data.local_PANEL_형식
                },
                {
                  title: "TRANSFORMER",
                  text: data.local_TRANSFORMER
                },
                {
                  title: "정역 CONTACTOR",
                  text: data.local_정역_CONTACTOR
                },
                {
                  title: "RELAY",
                  text: data.local_RELAY
                },
                {
                  title: "EOCR",
                  text: data.local_EOCR
                },
                {
                  title: "LAMP",
                  text: data.local_LAMP
                },
                {
                  title: "SELECTOR S/W",
                  text: data.local_SELECTOR_SW
                },
                {
                  title: "INDICATOR",
                  text: data.local_INDICATOR
                },
                {
                  title: "NFB",
                  text: data.local_NFB
                },
                {
                  title: "VOLT METER",
                  text: data.local_VOLT_METER
                },
                {
                  title: "AMP METER",
                  text: data.local_AMP_METER
                },
                {
                  title: "CABLE ENTRY",
                  text: data.local_CABLE_ENTRY
                },
              ],
            },
          ],
          text: data.작업번호
        },
      ],
    },
  ];
  const epsData = [
    {
      title: "ACTUATOR 정보",
      list: [
        {
          title: "작업번호",
          text: data.작업번호
        },
        {
          title: "SER-NO",
          text: data.serial_NO
        },
        {
          title: "공정 범위",
          text: data.공정범위
        },
        {
          title: "발주처",
          text: data.발주처
        },
        {
          title: "수량",
          text: data.수량
        },
        {
          title: "납기일",
          text: data.납기일
        },
        {
          title: "건명",
          text: data.건명
        },
      ],
    },

    {
      title: "밸브 사양",
      list: [
        {
          title: "TAG NO.",
          text: data.tag_NO
        },
        {
          title: "회전방향",
          text: data.회전방향
        },
        {
          title: "밸브 TYPE",
          text: data.밸브_TYPE
        },
        {
          title: "CLASS(RATING)",
          text: data.class_RATING
        },
        {
          title: "SIZE",
          text: data.size
        },
        {
          title: "STEM DIA",
          text: data.stem_DIA
        },
        {
          title: "STROKE (mm)",
          text: data.stroke_mm
        },
        {
          title: "나사방향",
          text: data.나사방향
        },
        {
          title: "나사 LEAD",
          text: data.나사_LEAD
        },
        {
          title: "줄 수",
          text: data.줄수
        },
        {
          title: "밸브구매 유/무",
          text: data.밸브구매_유무
        },
        {
          title: "사급밸브 유/무",
          text: data.사급밸브_유무
        },
        {
          title: "밸브조립 유/무",
          text: data.밸브조립_유무
        },
        {
          title: "설치자재 공급 유/무",
          text: data.설치자재_공급_유무
        },
        {
          title: "이상식자재",
          text: data.이상식자재
        },
        {
          title: "연동식자재",
          text: data.연동식자재
        },
        {
          title: "공급분리형",
          text: data.공급분리형
        },
        {
          title: "과부하계전기",
          text: data.과부하계전기
        },
        {
          title: "S.S.R",
          text: data.s_S_R
        },
        {
          title: "외부단자함 유/무",
          text: data.외부단자함_유무
        },
        {
          title: "원자력",
          text: data.원자력
        },
        {
          title: "램프 비표준",
          text: data.램프_비표준
        },
      ],
    },

    {
      title: "기본 ACTUATOR 사양",
      list: [
        {
          title: "MODEL",
          text: data.model
        },
        {
          title: "MOTOR (KW)",
          text: data.motor_KW
        },
        {
          title: "MOTOR HEATER \n유/무",
          text: data.motor_HEATER_유무
        },
        {
          title: "MOTOR TH X 3 \n유/무",
          text: data.motor_TH_X_3_유무
        },
        {
          title: "MOTOR S4 유/무",
          text: data.motor_S4_유무
        },
        {
          title: "MOTOR H종 유/무",
          text: data.motor_H종_유무
        },
        {
          title: "MOTOR 30분 정격 \n유/무",
          text: data.motor_30분_정격_유무
        },
        {
          title: "MOTOR 저관성 \n유/무",
          text: data.motor_저관성_유무
        },
        {
          title: "MOTOR 2 POLE \n유/무",
          text: data.motor_2_POLE_유무
        },
        {
          title: "제어방식",
          text: data.제어방식
        },
        {
          title: "제어옵션",
          text: data.제어옵션
        },
        {
          title: "OPERTING TYPE",
          text: data.operting_TYPE
        },
        {
          title: "제어옵션",
          text: data.제어옵션
        },
        {
          title: "보호구조",
          text: data.보호구조
        },
        {
          title: "주전원",
          text: data.주전원
        },
        {
          title: "제어전원",
          text: data.제어전원
        },
        {
          title: "Hammer Blow",
          text: data.hammer_Blow
        },
        {
          title: "RPM",
          text: data.rpm
        },
        {
          title: "A/B GEAR",
          text: data.ab_GEAR
        },
        {
          title: "WORM",
          text: data.worm
        },
        {
          title: "토크스프링",
          text: data.토크스프링
        },
        {
          title: "세팅 범위 (klgf.m)",
          text: data.세팅_범위_Kgf_m
        },
        {
          title: "개폐시간 (SEC)",
          text: data.개폐시간_SEC
        },
        {
          title: "최대 TORQUE (kgf.m)",
          text: data.최대_TORQUE_kgf_m
        },
        {
          title: "SETTING TORQUE \nOPEN (kgf.m)",
          text: data.setting_TORQUE_OPEN_kgf_m
        },
        {
          title: "SETTING TORQUE \nCLOSE (kgf.m)",
          text: data.setting_TORQUE_CLOSE_kgf_m
        },
        {
          title: "SETTING TORQUE \nOPEN",
          text: data.setting_TORQUE_OPEN
        },
        {
          title: "SETTING TORQUE \nCLOSE",
          text: data.setting_TORQUE_CLOSE
        },
        {
          title: "Seating Method",
          text: data.seating_Method
        },
      ],
    },

    {
      title: "Masking Part",
      list: [
        {
          title: "THRUST UNIT",
          text: data.thrust_UNIT
        },
        {
          title: "재질",
          text: data.재질
        },
        {
          title: "SIZE",
          text: data.size2
        },
        {
          title: "KEY",
          text: data.key_
        },
        {
          title: "공급방식",
          text: data.공급방식
        },
        {
          title: "STEM COVER",
          text: data.stem_COVER
        },
        {
          title: "핸들휠",
          text: data.핸들휠
        },
      ],
    },

    {
      title: "Switch Unit Part",
      list: [
        {
          title: "출력회전수",
          text: data.출력회전수
        },
        {
          title: "개도계기어",
          text: data.개도계기어
        },
        {
          title: "각도",
          text: data.각도
        },
        {
          title: "SWITCH UNIT",
          text: data.switch_UNIT
        },
        {
          title: "LIMIT SWITCH",
          text: data.limit_SWITCH
        },
        {
          title: "TORQUE SWITCH",
          text: data.torque_SWITCH
        },
        {
          title: "TORQUE HOLDING",
          text: data.torque_HOLDING
        },
      ],
    },

    {
      title: "Integral Part",
      list: [
        {
          title: "정역 접촉기",
          text: data.정역_접촉기
        },
        {
          title: "정역 접촉기 용량",
          text: data.정역_접촉기_용량
        },
        {
          title: "TYPE",
          text: data.type
        },
        {
          title: "TRANSFOMER",
          text: data.transformer
        },
        {
          title: "분리형 유/무",
          text: data.분리형_유무
        },
        {
          title: "EOCR 유/무",
          text: data.eocr_유무
        },
      ],
    },

    {
      title: "Pot Part",
      list: [
        {
          title: "OP COVER",
          text: data.op_COVER
        },
        {
          title: "OP BOARD",
          text: data.op_BOARD
        },
        {
          title: "POT PART",
          text: data.pot_PART
        },
        {
          title: "보조 POT 유/무",
          text: data.보조_POT_유무
        },
        {
          title: "R/I CONV.",
          text: data.ri_CONV_
        },
        {
          title: "ARRESTER 유/무",
          text: data.arrester_유무
        },
        {
          title: "TERMINAL",
          text: data.terminal
        },
        {
          title: "SURGE PROTECTOR \n유/무",
          text: data.surge_PROTECTOR_유무
        },
      ],
    },

    {
      title: "완성제품 PART",
      list: [
        {
          title: "COMMON CASE",
          text: data.common_CASE
        },
        {
          title: "SWITCH COVER",
          text: data.switch_COVER
        },
        {
          title: "TERMINAL COVER",
          text: data.terminal_COVER
        },
        {
          title: "INTEGRAL CASE",
          text: data.integral_CASE
        },
        {
          title: "표준/비표준",
          text: data.표준비표준
        },
        {
          title: "ADAPTOR / REDUCER 조립 \n유/무",
          text: data.adaptorreducer조립_유무
        },
        {
          title: "ENTRY 수량",
          text: data.entry__수량
        },
        {
          title: "ENTRY SIZE",
          text: data.entry_SIZE
        },
        {
          title: "도장",
          text: data.도장
        },
        {
          title: "재질",
          text: data.재질
        },
        {
          title: "색상",
          text: data.색상
        },
        {
          title: "도막두께 (um)",
          text: data.도막두께_um
        },
      ],
    },

    {
      title: "감속기 PART",
      list: [
        {
          title: "MAKER",
          text: data.maker
        },
        {
          title: "종류",
          text: data.종류
        },
        {
          title: "감속기",
          text: data.감속기
        },
        {
          title: "STEM BUSH",
          text: data.stem_BUSH
        },
        {
          title: "ACT + 감속기",
          text: data.act_감속기
        },
      ],
    },

    {
      title: "특기사항",
      list: [
        {
          title: "특기사항",
          text: data.특기사항
        },
      ],
    },
  ];

  return (
    <Styles.Container>
      <common.MorganHeader />

      <div className="thinInner">
        <section>
          {param.type === "ERP" && (
            <>
              {erpData?.map((item, index) => {
                return (
                  <div className="erp" key={"erp" + index}>
                    <p className="specTitle">{item.specTitle}</p>
                    <ul className="morganListWrap">
                      {item?.specList?.map((item, index) => {
                        return (
                          <li className="morganList" key={"morganList" + index}>
                            <components.MorganList data={item} />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </>
          )}

          {param.type === "EPS" && (
            <ul className="morganListWrap">
              {epsData?.map((item, index) => {
                return (
                  <li className="morganList" key={"morganList" + index}>
                    <components.MorganList data={item} />
                  </li>
                );
              })}
            </ul>
          )}

          <div className="prevButton">
            <ui.button.BasicButton
              buttonText={"이전으로"}
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
        </section>
      </div>
    </Styles.Container>
  );
}

export default MorganDetailPage;
