import Styles from "./styles";

function BasicTextarea({ data, setInputs }) {
  const { labelText, essential, name, value, placeholder, errorText } = data;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs((e) => {
      return { ...e, [name]: value };
    });
  };

  return (
    <Styles.Container>
      {labelText && (
        <div className="inputLabel">
          <label>
            {essential && <span className="essential">[필수]</span>}
            {labelText}
          </label>
        </div>
      )}

      <textarea
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      ></textarea>

      {errorText && <p className="errorText">{errorText}</p>}
    </Styles.Container>
  );
}

export default BasicTextarea;
