import { useAtom } from "jotai";
import { modalAtom } from "store/atoms";

export default function useModals() {
  const [defaultModal, setDefaultModal] = useAtom(modalAtom.defaultModalAtom);
  const [findIdModal, setFindIdModal] = useAtom(modalAtom.findIdModalAtom);
  const [findPwModal, setFindPwModal] = useAtom(modalAtom.findPwModalAtom);
  const [optionModal, setOptionModal] = useAtom(modalAtom.optionModalAtom);

  const clearModals = () => {
    setDefaultModal({ show: false });
    setFindIdModal({ show: false });
    setFindPwModal({ show: false });
    setOptionModal({ show: false });
  };

  const clearSubModals = (callback) => {
    setDefaultModal((e) => !e);
    setTimeout(() => {
      callback();
    }, [0]);
  };

  return {
    clearModals,
    clearSubModals,
    defaultModal,
    setDefaultModal,
    findIdModal,
    setFindIdModal,
    findPwModal,
    setFindPwModal,
    optionModal,
    setOptionModal,
  };
}
