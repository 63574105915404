import Styles from "./styles";
import { useState } from "react";
import { common, ui } from "components";
import { useNavigate } from "react-router-dom";
import { useAuth, useLanguage, useModals } from "hooks";

// img
import Logo from "resources/image/icon/logo_gray.svg";

function LoginPage() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  const authInfo = useAuth();

  const navigate = useNavigate();
  const modalOption = useModals();

  const [isShow, setIsShow] = useState(false);
  const [errorText, setErrorText] = useState({});
  const [inputs, setInputs] = useState({
    id: "",
    password: "",
  });

  const inputList = [
    {
      name: "id",
      value: inputs.id,
      labelText: languageQuarter ? "아이디(이메일)" : "ID (E-mail)",
      placeholder: languageQuarter
        ? "이메일을 입력해주세요."
        : "Please enter your E-mail.",
      errorText: errorText.id,
    },
    {
      name: "password",
      value: inputs.password,
      type: isShow ? "text" : "password",
      labelText: languageQuarter ? "비밀번호" : "Password",
      placeholder: languageQuarter
        ? "비밀번호를 입력해주세요."
        : "Please enter your password.",
      errorText: errorText.password,
      guideText: languageQuarter
        ? "영문자, 숫자, 특수문자 포함 8자 이상"
        : <>
          8 or more characters, including <br className="mbOnly"/>
          English, numbers, special symbol
        </>,
    },
  ];
  const linkList = [
    {
      text: languageQuarter ? "아이디 찾기" : "Find ID",
      click: () => {
        modalOption.setFindIdModal((e) => {
          e.show = true;

          return { ...e };
        });
      },
    },
    {
      text: languageQuarter ? "비밀번호 찾기" : "Find password",
      click: () => {
        modalOption.setFindPwModal((e) => {
          e.show = true;

          return { ...e };
        });
      },
    },
    {
      text: languageQuarter ? "회원가입" : "Join membership",
      click: () => {
        navigate("/signup");
      },
    },
  ];

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs((e) => {
      return { ...e, [name]: value };
    });
  };

  const validate = () => {
    const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const passwordRegEx = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[$&+,:;=?@#|'<>.^*()%!-])[0-9A-Za-z$&+,:;=?@#|'<>.^*()%!-]{8,30}$/;

    let isError = false;
    if (inputs.id === "") {
      setErrorText(e => {
        return { ...e,
          id: languageQuarter
            ? "아이디을 입력해주세요."
            : "Please enter your ID."
        }
      })
      isError = true;
    } else if (emailRegEx.test(inputs.id) === false) {
      setErrorText(e => {
        return { ...e,
          id: languageQuarter
            ? "이메일 형식이 올바르지 않습니다."
            : "The email format is not valid."
        }
      })
      isError = true;
    } else setErrorText(e => { return { ...e, id: "" } })

    if (inputs.password === "") {
      setErrorText(e => {
        return { ...e,
          password: languageQuarter
            ? "비밀번호를 입력해주세요."
            : "Please enter your password."
        }
      })
      isError = true;
    } else if (passwordRegEx.test(inputs.password) === false) {
      setErrorText(e => {
        return { ...e,
          password:
            languageQuarter
              ? "비밀번호 조건을 확인해주세요."
              : "Please check the password conditions."
        }
      })
      isError = true;
    } else setErrorText(e => { return { ...e, password: "" } })

    if (isError) return false;
    return true;
  }
  
  const onNext = () => {
    if (validate()) {
      authInfo.login(
        inputs.password,
        inputs.id,
        setErrorText,
      )
    }
  }

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section>
          <div className="formInner">
            <form>
              <div className="logo">
                <img src={Logo} alt="logo" />
              </div>
              <h2>{languageQuarter ? "로그인" : "Login"}</h2>

              <ul className="inputWrap">
                {inputList.map((item, index) => {
                  return (
                    <li className="inputList" key={"inputList" + index}>
                      <ui.input.BasicInput
                        data={item}
                        onChange={onChange}
                        isShow={isShow}
                        setIsShow={setIsShow}
                      />
                    </li>
                  );
                })}
              </ul>

              <div className="loginButton">
                <ui.button.BasicButton
                  buttonText={languageQuarter ? "로그인" : "Login"}
                  onClick={onNext}
                />
              </div>

              <ul className="link">
                {linkList.map((item, index) => {
                  return (
                    <li className="linkList" key={"linkList" + index}>
                      <button type="button" onClick={item.click}>
                        {item.text}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </form>
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default LoginPage;
