import Styles from "./styles";
import { Link } from "react-router-dom";
import { useState } from "react";
import { common, ui } from "components";
import { useAuth, useLanguage } from "hooks";

// img
import Logo from "resources/image/icon/logo_gray.svg";

function SignupPage() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  const authInfo = useAuth();

  const [isShow, setIsShow] = useState({ password: false, confirmPassword: false });
  const [inputs, setInputs] = useState({
    company: "",
    division: "",
    name: "",
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [check, setCheck] = useState([]);
  const [errorText, setErrorText] = useState({});

  // 체크박스 단일 선택
  const handleSingleCheck = (checked, id) => {
    if (checked) {
      // 단일 선택 시 체크된 아이템을 배열에 추가
      setCheck(check.concat(id));
    } else {
      // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
      setCheck(check.filter((el) => el !== id));
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs((e) => {
      return { ...e, [name]: value };
    });
  };

  // data
  const affiliationList = [
    {
      name: "company",
      value: inputs.company,
      essential: true,
      labelText: languageQuarter ? "회사" : "Company",
      placeholder: languageQuarter
        ? "회사명을 입력해주세요."
        : "Please enter the company name.",
      errorText: errorText.company,
    },
    {
      name: "division",
      value: inputs.division,
      essential: true,
      labelText: languageQuarter ? "부서" : "Department",
      placeholder: languageQuarter
        ? "부서명을 입력해주세요."
        : "Please enter your department name.",
      errorText: errorText.division,
    },
  ];
  const personalList = [
    {
      name: "name",
      value: inputs.name,
      essential: true,
      labelText: languageQuarter ? "이름" : "Name",
      placeholder: languageQuarter
        ? "이름을 입력해주세요."
        : "Please enter your password.",
      errorText: errorText.name,
    },
    {
      name: "username",
      value: inputs.username,
      essential: true,
      labelText: languageQuarter ? "아이디(이메일)" : "ID (E-mail)",
      placeholder: languageQuarter
        ? "이메일을 입력해주세요."
        : "Please enter your E-mail.",
      errorText: errorText.username,
    },
    {
      name: "password",
      value: inputs.password,
      essential: true,
      labelText: languageQuarter ? "비밀번호" : "Password",
      type: isShow.password ? "text" : "password",
      guideText: languageQuarter
        ? "영문자, 숫자, 특수문자 포함 8자 이상"
        : <>
            8 or more characters, including <br className="pcOnly" />
            English, numbers, special symbol
          </>,
      placeholder: languageQuarter
        ? "비밀번호를 입력해주세요."
        : "Please enter your password.",
      errorText: errorText.password,
    },
    {
      name: "confirmPassword",
      value: inputs.confirmPassword,
      essential: true,
      labelText: languageQuarter ? "비밀번호 확인" : "Verifying the password",
      type: isShow.confirmPassword ? "text" : "password",
      placeholder: languageQuarter
        ? "비밀번호를 입력해주세요."
        : "Please enter your password.",
      errorText: errorText.confirmPassword,
    },
  ];
  const checkList = [
    {
      id: "service",
      labelText: languageQuarter
        ? "에너토크 서비스 이용약관에 동의합니다."
        : <>I agree to the terms and <br className="pcOnly" /> conditions of the Enertork service.</>,
      essential: true,
      link: "/terms/service",
    },
    {
      id: "privacy",
      labelText: languageQuarter
        ? "개인정보 처리방침에 동의합니다."
        : "Agree with the privacy policy.",
      essential: true,
      link: "/terms/privacy",
    },
  ];

  const validate = () => {
    const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const passwordRegEx = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[$&+,:;=?@#|'<>.^*()%!-])[0-9A-Za-z$&+,:;=?@#|'<>.^*()%!-]{8,30}$/;

    let isError = false;
    if (inputs.company === "") {
      setErrorText(e => {
        return { ...e,
          company: languageQuarter
            ? "회사명을 입력해주세요."
            : "Please enter the company name."
        }
      })
      isError = true;
    } else setErrorText(e => { return { ...e, company: "" } })

    if (inputs.division === "") {
      setErrorText(e => {
        return { ...e,
          division:
            languageQuarter
              ? "부서명을 입력해주세요."
              : "Please enter your department name."
        }
      })
      isError = true;
    } else setErrorText(e => { return { ...e, division: "" } })

    if (inputs.name === "") {
      setErrorText(e => {
        return { ...e,
          name:
            languageQuarter
              ? "이름을 입력해주세요."
              : "Please enter your name."
        }
      })
      isError = true;
    } else setErrorText(e => { return { ...e, name: "" } })

    if (inputs.username === "") {
      setErrorText(e => {
        return { ...e,
          username:
            languageQuarter
              ? "이메일을 입력해주세요."
              : "Please enter your E-mail."
        }
      })
      isError = true;
    } else if (emailRegEx.test(inputs.username) === false) {
      setErrorText(e => {
        return { ...e,
          username:
            languageQuarter
              ? "이메일 형식이 올바르지 않습니다."
              : "The email format is not valid."
        }
      })
      isError = true;
    } else setErrorText(e => { return { ...e, username: "" } })

    if (inputs.password === "") {
      setErrorText(e => {
        return { ...e,
          password:
            languageQuarter
              ? "비밀번호를 입력해주세요."
              : "Please enter your password."
        }
      })
      isError = true;
    } else if (passwordRegEx.test(inputs.password) === false) {
      setErrorText(e => {
        return { ...e,
          password:
            languageQuarter
              ? "비밀번호 조건을 확인해주세요."
              : "Please check the password conditions."
        }
      })
      isError = true;
    } else setErrorText(e => { return { ...e, password: "" } })

    if (inputs.confirmPassword !== inputs.password) {
      setErrorText(e => {
        return { ...e,
          confirmPassword:
            languageQuarter
              ? "비밀번호가 일치하지 않습니다."
              : "Please agree to the terms and conditions."
        }
      })
      isError = true;
    } else setErrorText(e => { return { ...e, confirmPassword: "" } })

    if (check.length !== 2) {
      setErrorText(e => {
        return { ...e,
          check:
            languageQuarter
              ? "이용약관에 동의해주세요."
              : "Please agree to the terms and conditions."
        }
      })
      isError = true;
    } else setErrorText(e => { return { ...e, check: "" } })
    
    if (isError) return false;
    return true;
  }

  const onNext = () => {
    if (validate()) {
      const postData = new FormData();
      postData.append("company", inputs.company);
      postData.append("division", inputs.division);
      postData.append("name", inputs.name);
      postData.append("password", inputs.password);
      postData.append("username", inputs.username);

      authInfo.signUp(postData)
    }
  }

  return (
    <>
      <common.Header />
      <Styles.Container>
        <section>
          <div className="formInner">
            <form>
              <div className="logo">
                <img src={Logo} alt="logo" />
              </div>
              <h2>{languageQuarter ? "회원가입" : "Join membership"}</h2>

              <div className="affiliation">
                <p className="title">{languageQuarter ? "소속 정보" : "Affiliate information"}</p>

                <ul className="inputWrap">
                  {affiliationList.map((item, index) => {
                    return (
                      <li className="inputList" key={"inputList" + index}>
                        <ui.input.BasicInput data={item} onChange={onChange}/>
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="personal">
                <p className="title">{languageQuarter ? "개인정보" : "Personal information"}</p>

                <ul className="inputWrap">
                  {personalList.map((item, index) => {
                    return (
                      <li className="inputList" key={"inputList" + index}>
                        <ui.input.BasicInput
                          data={item}
                          onChange={onChange}
                          isShow={isShow[item.name]}
                          setIsShow={() => setIsShow(e => ({ ...e, [item.name]: !isShow[item.name] }))}
                         />
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div className="terms">
                <p className="title">{languageQuarter ? "약관 동의" : "Agree to Terms and Conditions"}</p>

                <ul className="termsWrap">
                  {checkList?.map((item, index) => {
                    return (
                      <li className="checkList" key={"checkList" + index}>
                        <ui.input.BasicCheckbox
                          data={item}
                          checked={check.includes(item.id) ? true : false}
                          onChange={(e) => {
                            handleSingleCheck(e.target.checked, e.target.id);
                          }}
                        />

                        <Link to={item.link} target="_blank">{languageQuarter ? "내용보기" : "View Content"}</Link>
                      </li>
                    );
                  })}
                </ul>

                <p className="errorText">{errorText.check}</p>
              </div>

              <div className="loginButton">
                <ui.button.BasicButton
                  buttonText={languageQuarter ? "인증메일 보내기" : "Send authentication E-mail"}
                  onClick={onNext}
                />
              </div>
            </form>
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default SignupPage;
