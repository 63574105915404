import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  width: 300px;

  & .downloadList {
    width: 45px;
    height: 30px;
  }

  & button {
    cursor: pointer;
  }

  @media (max-width: 1740px) {
    gap: calc(100vw * (15 / 1740));
    width: calc(100vw * (300 / 1740));

    & .downloadList {
      width: calc(100vw * (45 / 1740));
      height: calc(100vw * (30 / 1740));
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    gap: calc(100vw * (10 / 375));
    width: 100%;

    & .downloadList {
      width: calc(100vw * (45 / 375));
      height: calc(100vw * (30 / 375));
    }
  }
`;

export { Container };

export default { Container };
