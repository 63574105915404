import Stock from "./Stock";
import Public from "./Public";
import Shareholder from "./Shareholder";
import Internal from "./Internal";
import Settlement from "./Settlement";
import DownloadList from "./DownloadList";

export { Stock, Public, Shareholder, Internal, Settlement, DownloadList };

export default {
  Stock,
  Public,
  Shareholder,
  Internal,
  Settlement,
  DownloadList,
};
