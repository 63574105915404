import Styles from "./styles";

function TitleContainer({ data }) {
  const { h2, title, description } = data;
  return (
    <Styles.Container>
      <h2>{h2}</h2>

      <p className="mainTitle">{title}</p>

      {description && <p className="mainDescription">{description}</p>}
    </Styles.Container>
  );
}

export default TitleContainer;
