import Styles from "./styles";
import ScrollContainer from "react-indiana-drag-scroll";
import { api } from "service";
import { layout } from "components";
import { useAgency, useLanguage } from "hooks";

// img
import ImgAgency01 from "resources/image/companyIntroduction/agency/img_agency01.jpg";
import ImgAgency02 from "resources/image/companyIntroduction/agency/img_agency02.jpg";
import ImgAgency03 from "resources/image/companyIntroduction/agency/img_agency03.jpg";

function Agency() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  const domesticInfo = useAgency({ type: 1 });
  const foreignInfo = useAgency({ type: 2 });

  const domestiData = domesticInfo.getAgency?.data?.data || [];
  const foreignData = foreignInfo.getAgency?.data?.data || [];

  const titleData = {
    h2: languageQuarter ? "대리점" : "Our Agencies",
    title: (languageQuarter ? 
      <>
        국내 시장 점유율 확대와 <br />
        글로벌 시장으로의 확장을 위한 교두보
      </> : <>
        The role of serving as a leverage for expanding domestic market share and global markets 
      </>
    ),
    description: (languageQuarter ? 
      <>
        ㈜에너토크는 국내 시장 점유율 확대를 위해 21개의 국내 대리점과 협업
        중이며, 해외 시장 진출 강화를 위해 19개의 해외 대리점 네트워크를
        구축하고 있습니다. <br />
        해외 대리점은 지역별로 아시아 9개국, 중동 8개국, 아메리카와 유럽에 각 각
        1개국에 개설되어 있습니다.
      </> : <>
        We’re collaborating with 17 domestic agencies to expand Korean market share and additionally establishing a network of
        19 international agencies to strengthen global market penetration.
        Overseas agencies are opened in 9 countries in Asia, 8 in the Middle East, and 1 in the Americas and Europe by region.
      </>
    ),
  };

  const list = [
    {
      id:"agency_step_01",
      img: ImgAgency01,
      title: languageQuarter ? "국내 대리점" : "Domestic Agencies",
      description: (languageQuarter ? 
        <>
         ㈜에너토크는 전국적인 대리점 네트워크를 운영하고 있으며 신규 설치와 더불어 제품교체, AS 기능을 수행하고 있습니다.
          {/* ㈜에너토크 영업실적의 약 80%를 담당하고 있으며, 신규 설치와 더불어
          제품교체, <br />
          AS 기능을 수행하고 있습니다. */}
        </> : <>
        ENERTORK Co., Ltd. operates a nationwide network of distributors and provides installation, product replacement, and after-sales service.
        </>
      ),
      listTitle: (languageQuarter ? 
        <>
          국내 대리점 <br />
          네트워크
        </> : <>
          Domestic <br />
          Network
        </>
      ),
      agencyList: domestiData,
    },
    {
      id:"agency_step_02",
      img: ImgAgency02,
      title: languageQuarter ? "해외 대리점" : "Overseas Agencies",
      description: (languageQuarter ? 
        <>
        ㈜에너토크는 지속적으로 증가하는 제품의 해외 수요에 대응하기 위해 국제 영업 및 A/S 네트워크를 꾸준히 확장하고 있습니다.
        이를 통해 서비스 품질을 유지하고 향상시켜, 고객사의 만족도와 양적 성장을 이끌어내고 있습니다.
        </> : <>
        ENERTORK Co., Ltd. is consistently expanding its international sales and after-sales service network to meet the increasing global demand for its products. This effort ensures the maintenance and enhancement of service quality, thereby achieving greater customer satisfaction and quantitative growth for our clients.
        </>
      ),
      listTitle: (languageQuarter ? 
        <>
          해외 대리점 <br />
          네트워크
        </> : <>
          Overseas <br />
          Network
        </>
      ),
      agencyList: foreignData,
    },
  ];

  return (
    <Styles.Container>
      <layout.TitleContainer data={titleData} />

      <section className="agencySection">
        {list.map((item, index) => {
          return (
            <div
              id={item.id}
              className={"agency" + (" agency0" + (index + 1))}
              key={"agency" + index}
            >
              <div className="img">
                <img src={item.img} alt="agencyImg" />
              </div>

              <div className="titleContainer">
                <div className="left">{item.title}</div>
                <div className="right">
                  <p className="description">{item.description}</p>
                </div>
              </div>

              <div className="agencyListContainer">
                <div className="left">{item.listTitle}</div>

                <div className="right">
                  <ul className="agencyListWrap">
                    {item.agencyList.map((current, idx) => {
                      return (
                        <li className="agencyList" key={"agencyList" + idx}>
                          <p className="listTitle">{current["title" + languageInfo.suffix]}</p>
                          <p className="companyName">{current["name" + languageInfo.suffix]}</p>
                          <p className="detail">{current["description" + languageInfo.suffix]}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          );
        })}

        <div className="imgWrap">
          <ScrollContainer className="scroll-container">
            <div className="mapImg">
              <img src={ImgAgency03} alt="ImgAgency03" />
            </div>
          </ScrollContainer>

          <layout.ScrollGuide />
        </div>
      </section>
    </Styles.Container>
  );
}

export default Agency;
