import styled from "styled-components";

const Container = styled.div`
  & .place {
    display: flex;
    align-items: flex-end;
    gap: 80px;

    &:not(:last-child) {
      margin-bottom: 100px;
    }

    & .map {
      width: 657px;
      height: 400px;
      background-color: var(--main);
    }

    & .right {
      width: calc(100% - 737px);

      & .title {
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: 3px solid var(--black);
      }

      & .listTitle {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: bold;
        color: var(--main);
      }

      & ul {
        font-size: 16px;
        line-height: 1.5;

        &:not(:last-of-type) {
          margin-bottom: 30px;
        }
      }
    }
  }

  @media (max-width: 1740px) {
    & .place {
      gap: calc(100vw * (80 / 1740));

      &:not(:last-child) {
        margin-bottom: calc(100vw * (100 / 1740));
      }

      & .map {
        width: calc(100vw * (657 / 1740));
        height: calc(100vw * (400 / 1740));
      }

      & .right {
        width: calc(100% - calc(100vw * (737 / 1740)));

        & .title {
          font-size: calc(100vw * (36 / 1740));
          margin-bottom: calc(100vw * (30 / 1740));
          padding-bottom: calc(100vw * (30 / 1740));
          border-bottom: calc(100vw * (3 / 1740)) solid var(--black);
        }

        & .listTitle {
          margin-bottom: calc(100vw * (20 / 1740));
          font-size: calc(100vw * (16 / 1740));
        }

        & ul {
          font-size: calc(100vw * (16 / 1740));

          &:not(:last-of-type) {
            margin-bottom: calc(100vw * (30 / 1740));
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .placeSection {
      margin-top: calc(100vw * (50 / 375));
    }

    & .place {
      flex-direction: column;
      gap: calc(100vw * (30 / 375));

      &:not(:last-child) {
        margin-bottom: calc(100vw * (80 / 375));
      }

      & .map {
        width: 100%;
        height: calc(100vw * (204 / 375));
      }

      & .right {
        width: 100%;

        & .title {
          font-size: calc(100vw * (24 / 375));
          margin-bottom: calc(100vw * (20 / 375));
          padding-bottom: calc(100vw * (20 / 375));
          border-bottom: calc(100vw * (3 / 375)) solid var(--black);
        }

        & .listTitle {
          margin-bottom: calc(100vw * (10 / 375));
          font-size: calc(100vw * (16 / 375));
        }

        & ul {
          font-size: calc(100vw * (14 / 375));

          &:not(:last-of-type) {
            margin-bottom: calc(100vw * (30 / 375));
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
