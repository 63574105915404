import Styles from "./styles";
import { layout } from "components";
import { useLanguage } from "hooks";

function Search() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  return (
    <Styles.Container>
      <h2>Search</h2>

      <layout.EmptyContainer text={languageQuarter ? "검색어를 입력해주세요." : "Enter a search word."} search />
    </Styles.Container>
  );
}

export default Search;
