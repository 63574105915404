import { useQuery } from "react-query";
import { api } from "service";

export default function useBanner(){

  const getBanner = useQuery(["banner"], () => {
    return api.banner.getBanner();
  }, {
    enabled:!!true,
    refetchOnWindowFocus: false,
  });

  return { getBanner }
}