import styled from "styled-components";

const Button = styled.button`
  width: 100%;
  height: 70px;
  font-weight: 600;
  color: #fff;
  background-color: var(--main);
  transition: 0.3s background;

  &:hover {
    background-color: #ff7b33;
  }

  @media (max-width: 1740px) {
    height: calc(100vw * (70 / 1740));
  }

  @media (max-width: 768px) {
    height: calc(100vw * (70 / 375));
  }
`;

export { Button };

export default {
  Button,
};
