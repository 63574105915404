import Styles from "./styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom.svg";

function SideMenu({ data }) {
  const { title, firstDepthList } = data;

  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 728px)" });

  const pageNumber =
    firstDepthList?.length > 1
      ? firstDepthList.findIndex((v) => v.link === location.pathname)
      : 0;

  const [pageName, setPageName] = useState(firstDepthList[pageNumber]?.text);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scrollTopClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Styles.Container className={isMobile && isMenuOpen ? " on" : ""}>
      <div className="title">{title}</div>

      <div
        className="pageName"
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        {pageName}

        <div className="icon">
          <img src={IconArrowBottom} alt="IconArrowBottom" />
        </div>
      </div>

      <ul className="firstDepth">
        {firstDepthList?.map((item, index) => {
          return (
            <li className="firstDepthList" key={"firstDepthList" + index}>
              <a
                onClick={() => {
                  navigate(item.link + (item.focus ? "?focus=" + item.focus : ""), { replace : true });
                  if (isMobile) {
                    setPageName(item.text);
                    setIsMenuOpen(false);
                  } else {
                    !item.focus && scrollTopClick();
                  }
                }}
              >
                {item.text}

                {item.secondDepthList && (
                  <div
                    className={
                      "icon" +
                      (item.secondDepthList && location.pathname === item.link
                        ? " on"
                        : ""
                      )
                    }
                  >
                    <img src={IconArrowBottom} alt="IconArrowBottom" />
                  </div>
                )}
              </a>

              {item.secondDepthList && location.pathname === item.link && (
                <ul className="secondDepth">
                  {item.secondDepthList?.map((current, idx) => {
                    return (
                      <li
                        className="secondDepthList"
                        key={"secondDepthList" + idx}
                      >
                        <a
                          onClick={()=>{
                            navigate(item.link + (current.focus ? "?focus=" + current.focus : ""), { replace : true });
                            if (isMobile) {
                              setIsMenuOpen(false);
                            }
                          }}
                        >
                          {current.text}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </Styles.Container>
  );
}

export default SideMenu;
