import Styles from "./styles";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, useLanguage, useModals } from "hooks";

// img
import IconMenu from "resources/image/icon/icon_menu.svg";
import IconClose from "resources/image/icon/icon_close_black.svg";
import IconSearch from "resources/image/icon/icon_search.svg";
import IconHome from "resources/image/icon/icon_home.svg";
import IconStockPrice from "resources/image/icon/icon_stock_price.svg";
import IconGW from "resources/image/icon/icon_gw.svg";
import IconUser from "resources/image/icon/icon_user.svg";
import IconDocument from "resources/image/icon/icon_document.svg";
import IconGlobal from "resources/image/icon/icon_global.svg";

function Header({ main }) {
  const authInfo = useAuth();

  // 로그인 여부
  const login = authInfo.isLogin;
  // const login = true;
  
  // 회원 권한 여부
  const condition = authInfo.userData.access_yn === "Y";
  
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  const modalOption = useModals();
  const navigate = useNavigate();

  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // map
  const gnbList = [
    {
      url: "/company/introduction/info",
      text: languageQuarter ? "회사소개" : "About Us",
    },
    {
      url: "/investment/stock",
      text: languageQuarter ? "투자정보" : "Investment Information",
    },
    {
      url: "/recruitment",
      text: languageQuarter ? "채용정보" : "Careers",
    },
    {
      // url 제품에 따라 유동적
      url: "/product/0/0",
      text: languageQuarter ? "제품소개" : "Products",
    },
    {
      url: "/service/reference/catalog",
      text: languageQuarter ? "서비스 · 지원" : "Service & Support",
    },
  ];
  const utilList = [
    {
      url: "/investment/stock",
      icon: IconStockPrice,
    },
    {
      onClick: () => {
        window.open("https://enertork.groupware.pro/")
      },
      icon: IconGW,
    },
    {
      url: login ? "/mypage" : "/login",
      icon: IconUser,
    },
    {
      onClick: () => {
        login
          ? condition
            ? navigate("/morgan", {state: window.location.pathname})
            : modalOption.setDefaultModal((e) => {
                e.show = true;
                e.text = languageQuarter ? "접속 권한이 없습니다." : "You do not have permission to access";
                e.buttonText = languageQuarter ? "확인" : "Check";
                e.confirmButtonClick = false;
                e.closeButton = false;

                return { ...e };
              })
          : authInfo.certification();
      },
      icon: IconDocument,
    },
  ];
  const languageList = ["KOR", "ENG"];

  return (
    <>
      <Styles.Container
        className={(main ? "main" : "") + (isMenuOpen ? " on" : "")}
      >
        <div className="inner">
          <div className="gnbWrap">
            <button
              type="button"
              className="menuButton"
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);
              }}
            >
              <img src={isMenuOpen ? IconClose : IconMenu} alt="IconMenu" />
            </button>
            <ul className="gnb">
              {gnbList.map((item, index) => {
                return (
                  <li className="gnbList" key={"gnbList" + index}>
                    <Link
                      to={item.url}
                      onClick={() => {
                        setIsMenuOpen(false);
                      }}
                    >
                      {item.text}
                    </Link>
                  </li>
                );
              })}
            </ul>

            <Link to={"/search"} className="searchButton">
              <img src={IconSearch} alt="IconSearch" />
            </Link>
          </div>

          <Link
            to={"/"}
            onClick={() => {
              setIsMenuOpen(false);
            }}
            className="homeButton"
          >
            <div className="icon">
              <img src={IconHome} alt="IconHome" />
            </div>
            HOME
          </Link>

          <ul className="utilListWrap">
            {utilList.map((item, index) => {
              return (
                <li className="utilList" key={"utilList" + index}>
                  {item.onClick ? (
                    <button
                      type="button"
                      onClick={item.onClick}
                      className="icon"
                    >
                      <img src={item.icon} alt="utilIcon" />
                    </button>
                  ) : (
                    <Link
                      to={item.url}
                      state={item.url === "/mypage" && true}
                      className="icon"
                    >
                      <img src={item.icon} alt="utilIcon" />
                    </Link>
                  )}
                </li>
              );
            })}
            <li className="utilList global">
              <button
                type="button"
                className="globalButton"
                onClick={() => {
                  setIsLanguageOpen(!isLanguageOpen);
                }}
              >
                <span className="icon">
                  <img src={IconGlobal} alt="IconGlobal" />
                </span>
                {languageQuarter ? "KOR" : "ENG"}
              </button>

              {isLanguageOpen && (
                <ul className="languageListWrap">
                  {languageList.map((item, index) => {
                    return (
                      <li className="languageList" key={"languageList" + index}>
                        <button
                          type="button"
                          className={(languageQuarter ? "KOR" : "ENG") === item ? "on" : ""}
                          onClick={() => {
                            languageInfo.setLanguage(item);
                            setIsLanguageOpen(false);
                            window.location.reload();
                          }}
                        >
                          {item}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          </ul>
        </div>

        {isMenuOpen && <div className="overlay"></div>}
      </Styles.Container>
    </>
  );
}

export default Header;
