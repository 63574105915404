import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  font-weight: 600;
  border-bottom: 1px solid var(--gray02);

  & .number,
  .category {
    width: 100px;
    font-size: 18px;
    text-align: center;
  }

  & .listTitle, .linkTitle {
    width: calc(100% - 500px);
    padding: 0 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & .linkTitle {
    text-decoration: underline;
    color: blue;
  }

  & .linkTitle:hover, .linkTitle:visited {
    color: purple;
  }

  @media (max-width: 1740px) {
    height: calc(100vw * (80 / 1740));

    & .number,
    .category {
      width: calc(100vw * (100 / 1740));
      font-size: calc(100vw * (18 / 1740));
    }

    & .listTitle, .linkTitle {
      width: calc(100% - calc(100vw * (500 / 1740)));
      padding: 0 calc(100vw * (20 / 1740));
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    align-items: flex-start;
    gap: calc(100vw * (8 / 375)) calc(100vw * (15 / 375));
    height: auto;
    padding: calc(100vw * (15 / 375)) 0;

    & .number,
    .category {
      width: auto;
      font-size: calc(100vw * (14 / 375));
    }

    & .listTitle, .linkTitle {
      width: 100%;
      padding: 0;
    }
  }
`;

export { Container };

export default { Container };
