import Styles from "./styles";

function EmptyContainer({ search, text }) {
  return (
    <Styles.Container className={search ? "search" : ""}>
      {text}
    </Styles.Container>
  );
}

export default EmptyContainer;
