import styled from "styled-components";

const Container = styled.div`
  padding: 80px 0 200px;
  min-height: 100vh;

  & section {
    padding-top: 80px;

    & .logo {
      width: 262px;
      height: 76px;
      margin: 0 auto 20px;
    }

    & h2 {
      margin-bottom: 50px;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
    }

    & .inputWrap {
      margin-bottom: 50px;

      & .inputList:not(:last-child) {
        margin-bottom: 40px;
      }
    }

    & .loginButton {
      margin-bottom: 10px;
    }

    & .link {
      display: flex;
      justify-content: center;
      gap: 30px;

      & .linkList {
        &:not(:last-child) {
          position: relative;

          &::after {
            position: absolute;
            top: 50%;
            right: -15px;
            transform: translateY(-50%);
            width: 1px;
            height: 11px;
            background-color: var(--main);
            content: "";
          }
        }
      }

      & button {
        font-size: 16px;
        color: var(--main);
      }
    }
  }

  @media (max-width: 1740px) {
    padding: calc(100vw * (80 / 1740)) 0 calc(100vw * (200 / 1740));

    & section {
      padding-top: calc(100vw * (80 / 1740));

      & .logo {
        width: calc(100vw * (262 / 1740));
        height: calc(100vw * (76 / 1740));
        margin: 0 auto calc(100vw * (20 / 1740));
      }

      & h2 {
        margin-bottom: calc(100vw * (50 / 1740));
        font-size: calc(100vw * (24 / 1740));
      }

      & .inputWrap {
        margin-bottom: calc(100vw * (50 / 1740));

        & .inputList:not(:last-child) {
          margin-bottom: calc(100vw * (40 / 1740));
        }
      }

      & .loginButton {
        margin-bottom: calc(100vw * (10 / 1740));
      }

      & .link {
        gap: calc(100vw * (30 / 1740));

        & .linkList {
          &:not(:last-child) {
            &::after {
              right: calc(100vw * (-15 / 1740));
              height: calc(100vw * (11 / 1740));
            }
          }
        }

        & button {
          font-size: calc(100vw * (16 / 1740));
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (50 / 375)) 0 calc(100vw * (150 / 375));

    & section {
      padding-top: calc(100vw * (50 / 375));

      & .logo {
        width: calc(100vw * (158 / 375));
        height: calc(100vw * (46 / 375));
        margin: 0 auto calc(100vw * (20 / 375));
      }

      & h2 {
        margin-bottom: calc(100vw * (50 / 375));
        font-size: calc(100vw * (18 / 375));
      }

      & .inputWrap {
        margin-bottom: calc(100vw * (50 / 375));

        & .inputList:not(:last-child) {
          margin-bottom: calc(100vw * (30 / 375));
        }
      }

      & .loginButton {
        margin-bottom: calc(100vw * (10 / 375));
      }

      & .link {
        gap: calc(100vw * (20 / 375));

        & .linkList {
          &:not(:last-child) {
            &::after {
              right: calc(100vw * (-10 / 375));
              height: calc(100vw * (11 / 375));
            }
          }
        }

        & button {
          font-size: calc(100vw * (14 / 375));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
