import styled from "styled-components";

const Container = styled.div`
  padding: 80px 0 200px;
  min-height: 100vh;

  & .inner {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 1740px) {
    padding: calc(100vw * (80 / 1740)) 0 calc(100vw * (200 / 1740));
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (50 / 375)) 0 calc(100vw * (150 / 375));

    & .inner {
      flex-direction: column;
      width: 100%;
    }

    & .narrowInner {
      padding-top: calc(100vw * (80 / 375));
    }
  }
`;

export { Container };

export default { Container };
