import Styles from "./styles";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useLocation } from "react-router-dom";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom.svg";

function ProductSideMenu({ data, isService }) {
  const { title, link, firstDepthList } = data;

  const navigate = useNavigate();
  const location = useLocation();

  const isMobile = useMediaQuery({ query: "(max-width: 728px)" });

  const [firstDepthIndex, setFirstDepthIndex] = useState(0);
  const [pageName, setPageName] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const pathName = location.pathname.split("/");

  const scrollTopClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    setPageName(firstDepthList[firstDepthIndex]?.text)
  },[location]);

  return (
    <Styles.Container className={isMobile && isMenuOpen ? " on" : ""}>
      <div className="title">{title}</div>

      <div
        className="pageName"
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        {pageName}

        <div className="icon">
          <img src={IconArrowBottom} alt="IconArrowBottom" />
        </div>
      </div>

      <ul className="firstDepthWrap">
        {firstDepthList?.map((item, index) => {
          return (
            <li className="firstDepthList" key={"firstDepthList" + index}>
              <div
                className={
                  "firstDepth" +
                  (item.secondDepthList && firstDepthIndex == index
                    ? " on"
                    : "")
                }
                onClick={() => {
                  setPageName(item.text);
                  setFirstDepthIndex(index);
                  scrollTopClick();
                }}
              >
                {item.text}

                {item.secondDepthList && (
                  <div className="icon">
                    <img src={IconArrowBottom} alt="IconArrowBottom" />
                  </div>
                )}
              </div>

              {item.secondDepthList && firstDepthIndex == index && (
                <ul className="secondDepthWrap">
                  {item.secondDepthList?.map((current, idx) => {
                    return (
                      <li
                        className="secondDepthList"
                        key={"secondDepthList" + idx}
                      >
                        <div
                          className={
                            "secondDepth" +
                            (pathName[2] == item.link &&
                            pathName[3] == current.link
                              ? " on"
                              : "")
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsMenuOpen(false);
                            if(isService){
                              navigate(`/${link}/${item.link}/${current.link}`);
                            }else
                              navigate(`/${link}/${item.link}/${current.link}?disableScrollTop=true&focus=product_title_id&focus_block=center`);
                          }}
                        >
                          {current.text}

                          {current.thirdDepthList && (
                            <div
                              className={
                                "icon" +
                                (current.thirdDepthList &&
                                pathName[2] == item.link &&
                                pathName[3] == current.link
                                  ? " on"
                                  : "")
                              }
                            >
                              <img
                                src={IconArrowBottom}
                                alt="IconArrowBottom"
                              />
                            </div>
                          )}
                        </div>

                        {current.thirdDepthList &&
                          pathName[2] == item.link &&
                          pathName[3] == current.link && (
                            <ul className="thirdDepthWrap">
                              {current.thirdDepthList?.map((v, i) => {
                                return (
                                  <li
                                    className="thirdDepthList"
                                    key={"thirdDepthList" + i}
                                  >
                                    <div
                                      className={
                                        "thirdDepth" +
                                        (pathName[2] == item.link &&
                                        pathName[3] == current.link &&
                                        pathName[4] == v.link
                                          ? " on"
                                          : "")
                                      }
                                      onClick={() => {
                                        setIsMenuOpen(false);
                                        if(isService){
                                          navigate(`/${link}/${item.link}/${current.link}/${v.link}`);
                                        }else
                                          navigate(`/${link}/${item.link}/${current.link}/${v.link}?disableScrollTop=true&focus=product_title_id&focus_block=center`);
                                      }}
                                    >
                                      {v.text}
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </Styles.Container>
  );
}

export default ProductSideMenu;
