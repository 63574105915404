import styled from "styled-components";

const Container = styled.div`
  position: relative;

  & .inputLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;

    & .essential {
      color: var(--main);
    }
  }

  & textarea {
    width: 100%;
    height: 272px;
    padding: 20px;
    border: 1px solid var(--gray01);
  }

  & .errorText {
    position: absolute;
    top: calc(100% + 5px);
    font-size: 16px;
    color: var(--red);
  }

  @media (max-width: 1740px) {
    & .inputLabel {
      margin-bottom: calc(100vw * (5 / 1740));
      font-size: calc(100vw * (16 / 1740));
    }

    & textarea {
      height: calc(100vw * (272 / 1740));
      padding: calc(100vw * (20 / 1740));
    }

    & .errorText {
      top: calc(100% + calc(100vw * (5 / 1740)));
      font-size: calc(100vw * (16 / 1740));
    }
  }

  @media (max-width: 768px) {
    & .inputLabel {
      margin-bottom: calc(100vw * (5 / 375));
      font-size: calc(100vw * (14 / 375));
    }

    & textarea {
      height: calc(100vw * (320 / 375));
      padding: calc(100vw * (20 / 375));
    }

    & .errorText {
      top: calc(100% + calc(100vw * (5 / 375)));
      font-size: calc(100vw * (14 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
