import Styles from "./styles";
import { useLanguage } from "hooks";
import { useMediaQuery } from "react-responsive";
import { common, layout } from "components";

// img
import BgMainBanner from "resources/image/recruitment/bg_main_banner.jpg";
import BgMainBannerMb from "resources/image/recruitment/bg_main_banner_mb.jpg";
import IconStep01 from "resources/image/icon/icon_step01.svg";
import IconStep02 from "resources/image/icon/icon_step02.svg";
import IconStep03 from "resources/image/icon/icon_step03.svg";
import IconStep04 from "resources/image/icon/icon_step04.svg";
import IconStep05 from "resources/image/icon/icon_step05.svg";
import IconStep06 from "resources/image/icon/icon_step06.svg";
import ImgTalent01 from "resources/image/recruitment/img_talent01.jpg";
import ImgTalent02 from "resources/image/recruitment/img_talent02.jpg";
import ImgTalent03 from "resources/image/recruitment/img_talent03.jpg";
import ImgDuty01 from "resources/image/recruitment/img_duty01.jpg";
import ImgDuty02 from "resources/image/recruitment/img_duty02.jpg";
import ImgDuty03 from "resources/image/recruitment/img_duty03.jpg";
import ImgDuty04 from "resources/image/recruitment/img_duty04.jpg";
import ImgDuty05 from "resources/image/recruitment/img_duty05.jpg";
import ImgDuty06 from "resources/image/recruitment/img_duty06.jpg";
import ImgDuty07 from "resources/image/recruitment/img_duty07.jpg";
import IconEvaluation01 from "resources/image/icon/icon_evaluation01.svg";
import IconEvaluation02 from "resources/image/icon/icon_evaluation02.svg";
import IconEvaluation03 from "resources/image/icon/icon_evaluation03.svg";
import IconPay01 from "resources/image/icon/icon_pay01.svg";
import IconPay02 from "resources/image/icon/icon_pay02.svg";
import ImgBenefit from "resources/image/recruitment/img_benefit.jpg";
import ImgBenefitMb from "resources/image/recruitment/img_benefit_mb.jpg";

function RecruitmentPage() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const isMobile = useMediaQuery({ query: "(max-width: 728px)" });

  const data = {
    img: isMobile ? BgMainBannerMb : BgMainBanner,
    title: languageQuarter ? "채용 정보" : "Recruitment information",
    firstDepthList: [
      {
        text: languageQuarter ? "채용 정보" : "Recruitment information",
        link: "/recruitment",
        secondDepthList: languageQuarter ? [
          { text: "인재상", focus : "recruitment_step_01" },
          { text: "직무 소개", focus : "recruitment_step_02" },
          { text: "평가 제도", focus : "recruitment_step_03" },
          { text: "급여 체계", focus : "recruitment_step_04" },
          { text: "복리 후생", focus : "recruitment_step_05" },
        ] : [
          { text: "Type of talent", focus : "recruitment_step_01" },
          { text: "Job introduction", focus : "recruitment_step_02" },
          { text: "Evaluation system" , focus : "recruitment_step_03" },
          { text: "Pay system" , focus : "recruitment_step_04" },
          { text: "Benefits" , focus : "recruitment_step_05" },
        ],
      }
    ]
  };


  const secondDepthList = data.firstDepthList[0].secondDepthList
  const titleData = [
    {
      h2: languageQuarter ? "채용 절차" : "Recruitment procedures",
      title: languageQuarter
        ? "투명하고 공정한 절차로 당사와 적합한 인재 채용"
        : "We hire the right talent for our company through a transparent and fair process",
    },
    {
      id: secondDepthList[0]?.focus,
      h2: secondDepthList[0]?.text,
      title: (languageQuarter ? 
        <>
          기본에 충실한 인재, <br className="mbOnly" />
          책임을 다하는 인재, <br />
          변화를 주도하는 인재
        </> : <>
          A one who is faithful to the basics,
          fulfills his responsibilities, <br className="pcOnly" />
          and leads the change
        </>
      ),
    },
    {
      id: secondDepthList[1]?.focus,
      h2: secondDepthList[1]?.text,
      title: languageQuarter
        ? "각각의 직무가 결합하고, 협업하여 최고의 제품과 서비스를 창출"
        : "Each job combines and collaborates to create the best products and services",
    },
    {
      id: secondDepthList[2]?.focus,
      h2: secondDepthList[2]?.text,
      title: (languageQuarter ? 
        <>
          경영계획을 기반으로 개인의 업무목표가 수립되며,{" "}
          <br className="pcOnly" />
          연간 2회 평가
        </> : <>
          An individual's work objectives are established based on the management plan, and evaluation is conducted twice a year
        </>
      ),
    },
    {
      id: secondDepthList[3]?.focus,
      h2: secondDepthList[3]?.text,
      title: languageQuarter
        ? "연봉제로 성과에 따라 성과급 지급"
        : "Annual salary system, performance pay based on performance",
    },
    {
      id: secondDepthList[4]?.focus,
      h2: secondDepthList[4]?.text,
      title: (languageQuarter ? 
        <>
          4대보험 · 의료비 · 통신비 · 자녀 <br className="mbOnly" />
          교육비 · 동호회비 · 휴가비 · 장기 <br className="mbOnly" />
          근속 <br className="pcOnly" />
          정년 퇴직 · 각종 경조사비 · 명절 선물 · 생일 선물 · 자기 개발비 ·
          도서구입비
        </> : <>
          4 major insurance, medical expenses, communication expenses,
          Child education expenses, Club membership fee, vacation expenses,
          long-term service, Retirement at age, congratulatory and condolence expenses,
          holiday gifts, birthday gifts, Self-development expenses, and book purchase expenses.
        </>
      ),
    },
  ];

  const stepList = [
    [
      {
        icon: IconStep01,
        listTitle: languageQuarter ? "서류 전형" : "Resume",
        description: (languageQuarter ? 
          <>
            각종 경로에서 접수된 입사 서류를 기반으로 <br />
            직무(경력, 전공 등) 및 적성 등을 확인한 후 <br />
            실무면접 진행여부를 결정하는 단계
          </> : <>
            The step of determining whether to conduct a practical interview after checking the job
            (experience, major, etc.) and aptitude based on the employment documents received from various channels
          </>
        ),
        color: languageQuarter ? "전화로 면접 일정 조정 및 확정" : "Rescheduling and confirming interviews over the phone",
      },
      {
        icon: IconStep02,
        listTitle: languageQuarter ? "실무 면접" : "Practical Interview",
        description: (languageQuarter ? 
          <>
            서류전형 합격자를 대상으로 리더십, 직무, <br className="mbOnly" />
            업무 스킬, <br className="pcOnly" />
            태도 등을 대면으로 확인하는 단계
          </> : <>
            The step of face-to-face confirmation of leadership, duties,
            work skills, attitudes, etc. for those who passed the document screening
          </>
        ),
        color: languageQuarter ? "근무 예정지에서 면접 진행" : "At the place of work",
      },
      {
        icon: IconStep03,
        listTitle: languageQuarter ? "임원 면접(필요시)" : "Executive interview(if necessary)",
        description: (languageQuarter ? 
          <>
            실무 면접 합격자를 대상으로 태도, 인성 등을{" "}
            <br className="mbOnly" />
            중심으로 <br className="pcOnly" />
            임원 및 CEO가 최종 합격 여부를 <br className="mbOnly" />
            결정하는 단계
          </> : <>
            The step for executives and CEOs to decide whether to pass the interviewee, focusing on attitudes, personality.
          </>
        ),
        color: languageQuarter ? "임원 면접 후 합격 여부 전화 안내" : "The result will be notified by phone",
      },
    ],
    [
      {
        icon: IconStep04,
        listTitle: languageQuarter ? "건강 검진" : "Medical Examination",
        description: (languageQuarter ? 
          <>
            합격자에 한해 <br />
            건강 검진 진행
          </> : <>
            Medical examinations are conducted only for successful applicants
          </>
        ),
      },
      {
        icon: IconStep05,
        listTitle: languageQuarter ? "입사" : "Join",
        description: (languageQuarter ? 
          <>
            연봉/직급 등 결정 <br />
            입사 서류 제출
          </> : <>
            Determining annual salary/job, etc <br />
            Submission of employment documents
          </>
        ),
      },
      {
        icon: IconStep06,
        description: (languageQuarter ? 
          <>
            위 채용 절차는 표준절차로 특정 직무/직책에 <br />
            대해서는 추가적인 과정이 있을 수 있으며, <br />
            해당하는 후보자의 경우에는 사전에 확인 <br className="mbOnly" />
            드립니다.
          </> : <>
            The above recruitment process is standard procedure,
            and there may be additional processes for specific jobs/ positions.
            In the case of applicable candidates, we will confirm in advance.
          </>
        ),
      },
    ],
  ];

  const talentList = [
    {
      img: ImgTalent01,
      listTitle: languageQuarter ? "기본에 충실한 인재" : "Faithful to the basics",
      description: (languageQuarter ? 
        <>
          바른 인성과 업무 전문성을 바탕으로 <br />
          개인과 회사의 성장을 이끌어 가는 인재
        </> : <>
          To lead the growth of individuals and companies based on good personality and professionalism
        </>
      ),
    },
    {
      img: ImgTalent02,
      listTitle: languageQuarter ? "책임을 다하는 인재" : "Fulfilling one's responsibilities",
      description: (languageQuarter ? 
        <>
          자신의 역할을 다하고, <br />
          이해관계자들과 협업을 통해 목표를 달성하는 인재
        </> : <>
          To fulfill one's role and achieve one's goals through collaboration with stakeholders
        </>
      ),
    },
    {
      img: ImgTalent03,
      listTitle: languageQuarter ? "변화를 주도하는 인재" : "Leading a change",
      description: (languageQuarter ? 
        <>
          새로운 시대가 요구하는 지식과 스킬을 습득하여 <br />
          미래를 개척해 나가는 인재
        </> : <>
          To explore the future by acquiring the knowledge and skills required by the new era
        </>
      ),
    },
  ];

  const dutyList = [
    {
      listTitle: languageQuarter ? "인사" : "HR",
      description: languageQuarter
        ? "임직원이 효율적, 효과적으로 업무를 수행할 수 있도록 인적자원을 개발, 관리"
        : "Developing and managing human resources so that executives and employees can perform their duties efficiently and effectively",
      tagList: languageQuarter ? [
        "조직 디자인, 발령 업무",
        "채용, 교육 업무",
        "성과관리, 급여, 복리후생 업무",
        "노사 관리, 총무 업무",
      ] : [
        "Organization design, appointment",
        "Recruitment, education",
        "Performance management, salaries, and benefits",
        "Management of labor and management",
      ],
      img: ImgDuty01,
    },
    {
      listTitle: languageQuarter ? "재무" : "Finance",
      description: languageQuarter
        ? "국제 회계기준을 충족할 수 있도록 재무자원을 관리"
        : "Managing financial resources to meet international accounting standards",
      tagList: languageQuarter
        ? ["회계", "경리", "결산", "세무", "자금"]
        : ["Accounting", "Bookkeeping", "Settlement accounts", "Taxation", "Capital"],
      img: ImgDuty02,
    },
    {
      listTitle: "ESG",
      description: languageQuarter
        ? "글로벌 스탠다드에 맞도록 환경, 사회, 지배구조 측면에서 경영을 관리"
        : "Managing management in terms of environmental, social and governance to meet global standards",
      tagList: languageQuarter
        ? ["ESG 전략 개발", "ESG KPI 개발, 관리", "ESG Report 발간 등"]
        : ["ESG strategy development", "ESG KPI development and managenet", "Publishing ESG Report, etc"],
      img: ImgDuty03,
    },
    {
      listTitle: languageQuarter ? "개발" : "Development",
      description: languageQuarter
        ? "고객과 시장이 원하는 최고의 경쟁력을 갖춘 제품을 지속적으로 개발"
        : "Continuous developing of the best competitive products that customers and markets want",
      tagList: languageQuarter
        ? ["기존 제품 업데이트", "신규 제품 개발"]
        : ["Upgrading existing product", "Developing a new product"],
      img: ImgDuty04,
    },
    {
      listTitle: languageQuarter ? "생산, 품질" : "Production , Quality",
      description: languageQuarter
        ? "제품 완성을 위해 원자재 구매 - 생산 - 테스트 - 출고를 전반적으로 실행"
        : "Running “Purchase raw materials – Production – Test – Shipment” process in general to complete the products",
      tagList: languageQuarter ? [
        "원부자재 구매, 수불관리",
        "새로운 생산기법 도입",
        "제품 생산",
        "제품 품질 관리",
        "인증 관리",
        "AS",
        "전산시스템 개발, 유지 보수",
      ] : [
        "Purchase of raw and subsidiary materials, payment management",
        "Introduction of new production techniques",
        "Production",
        "Product Quality Control",
        "Authentication Management",
        "Warrantee service",
        "Computer system development and maintenance",
      ],
      img: ImgDuty05,
    },
    {
      listTitle: languageQuarter ? "영업" : "Sales",
      description: languageQuarter
        ? "당사 제품과 서비스 경쟁력을 소구하여 고객 니즈를 충족시켜 수주"
        : "Winning orders to meet customer needs by competing in our products and service",
      tagList: languageQuarter ? [
        "기존 고객 관리 및 수주",
        "신규 고객/시장 개발",
        "국내 영업",
        "해외 영업",
      ] : [
        "Managing Existing customer and obtaining contract",
        "New customer/market development",
        "Domestic sales",
        "Overseas sales",
      ],
      img: ImgDuty06,
    },
    {
      listTitle: languageQuarter ? "신사업" : "New Business",
      description: languageQuarter ? "미래 먹거리를 발굴" : "Discovering the Future Industry",
      tagList: languageQuarter
        ? ["기존 사업과 시너지 창출 가능 사업 발굴", "신규 사업 추진"]
        : ["Finding a business that can create synergy with the zone business", "Promoting a new business"],
      img: ImgDuty07,
    },
  ];

  const evaluationList = [
    {
      icon: IconEvaluation01,
      listTitle: languageQuarter ? "1. 목표 수립 (1월)" : "Establishing a goal (January)",
      list: languageQuarter ? [
        "전직원은 경영계획을 참조하여 연간 업무목표와 개인개발목표 수립",
        "새로운 일이나 기존과 다른 방식으로 진행할 업무 중심으로 목표 수립",
        "각 목표에 대해 달성 수준 합의",
      ] : [
        "All employees establish annual business and personal development goals by referring to management plans",
        "Set goals around new or different tasks",
        "Agree on the level of achievement for each goal",
      ],
    },
    {
      icon: IconEvaluation02,
      listTitle: languageQuarter ? "2. 진척도 리뷰 (6월)" : "Progress Review (June)",
      list: languageQuarter ? [
        "연초에 수립한 목표가 순조롭게 실행되는지 진척도 리뷰",
        <>
          진척도 리뷰는 중간 평가의 개념과 더불어 상사의 <br /> 코칭이 중심
        </>,
        <>
          필요 시 목표 조정 가능 <br className="mbOnly" />
          (상향/하향, 기한, 업무 품질 등)
        </>,
      ] : [
        "Review progress to see if the goals set out in January are being implemented smoothly",
        <>
          Progress review focuses on coaching from the supervisor along with the concept of interim evaluation
        </>,
        <>
          Target adjustment is possible if necessary (up/down, deadline, quality of work, etc.)
        </>,
      ],
    },
    {
      icon: IconEvaluation03,
      listTitle: languageQuarter ? "3. 성과 평가 (12월)" : "Performance evaluation (December)",
      list: languageQuarter ? [
        "각 목표에 대해 달성 수준을 계량화하여 평가",
        "S-A-B-C-D 5단계로 평가",
        "승진 및 연봉, 성과급 지급의 기본 자료로 활용",
      ] : [
        "Evaluate the achievement level for each goal",
        "Assessment in 5 stages S-A-B-C-D",
        "Result will be used as basic data for promotion, annual salary, and performance pay",
      ],
    },
  ];

  const payList = [
    {
      icon: IconPay01,
      listTitle: languageQuarter ? "고정급" : "Fixed salary",
      description: (languageQuarter ? 
        <>
          기본급 <br />
          식대 (현물 지급) <br />
          직책 · 직급 등
        </> : <>
          Base pay <br />
          Dining table (in kind) <br />
          Positional level, etc
        </>
      ),
    },
    {
      icon: IconPay02,
      listTitle: languageQuarter ? "성과급" : "Performance pay",
      description: (languageQuarter ? 
        <>
          상반기 <br />
          하반기 <br />
          성과에 따라 지급 여부 결정
        </> : <>
          The first half year <br />
          The second half year <br />
          Determined based on performance
        </>
      ),
    },
  ];

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Banner data={data} />

        <div className="inner">
          <common.SideMenu data={data} />

          <div className="narrowInner">
            <section id={titleData[0].id} className="stepSection">
              <layout.TitleContainer data={titleData[0]} />

              <ul className="step step01">
                {stepList[0].map((item, index) => {
                  return (
                    <li className="stepList" key={"stepList" + index}>
                      <div className="icon">
                        <img src={item.icon} alt="icon" />
                      </div>

                      <p className="listTitle">{item.listTitle}</p>

                      <p className="description">{item.description}</p>

                      <p className="color">{item.color}</p>
                    </li>
                  );
                })}
              </ul>

              <ul className="step step02">
                {stepList[1].map((item, index) => {
                  return (
                    <li className="stepList" key={"stepList" + index}>
                      <div className="icon">
                        <img src={item.icon} alt="icon" />
                      </div>

                      <p className="listTitle">{item.listTitle}</p>

                      <p className="description">{item.description}</p>

                      <p className="color">{item.color}</p>
                    </li>
                  );
                })}
              </ul>
            </section>

            <section id={titleData[1].id} className="talentSection">
              <layout.TitleContainer data={titleData[1]} />

              <ul className="talent">
                {talentList.map((item, index) => {
                  return (
                    <li className="talentList" key={"talentList" + index}>
                      <div className="img">
                        <img src={item.img} alt="talentImg" />
                      </div>

                      <p className="listTitle">{item.listTitle}</p>

                      <p className="description">{item.description}</p>
                    </li>
                  );
                })}
              </ul>

              <div>
                <p className="typeTitle">{languageQuarter ? "에너토크의 인재상" : "Enertork's talent"}</p>

                <div className="typeWrap">
                  <ul className="type">
                    <li className="typeList">{languageQuarter ? "기본" : "basics"}</li>
                    <li className="typeList">{languageQuarter ? "책임" : "responsibility"}</li>
                    <li className="typeList">{languageQuarter ? "변화" : "change"}</li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="dutySection">
              <div id={titleData[2].id} ></div>
              <layout.TitleContainer data={titleData[2]} />

              {dutyList.map((item, index) => {
                return (
                  <div className="duty" key={"duty" + index}>
                    <p className="listTitle">{item.listTitle}</p>

                    <p className="description">{item.description}</p>

                    <ul className="tag">
                      {item.tagList.map((current, idx) => {
                        return (
                          <li className="tagList" key={"tagList" + idx}>
                            {current}
                          </li>
                        );
                      })}
                    </ul>

                    <div className="img">
                      <img src={item.img} alt="dutyImg" />
                    </div>
                  </div>
                );
              })}
            </section>

            <section id={titleData[3].id} className="evaluationSection">
              <layout.TitleContainer data={titleData[3]} />

              <ul className="evaluation">
                {evaluationList.map((item, index) => {
                  return (
                    <li
                      className="evaluationList"
                      key={"evaluationList" + index}
                    >
                      <div className="icon">
                        <img src={item.icon} alt="evaluationIcon" />
                      </div>

                      <p className="listTitle">{item.listTitle}</p>

                      <ul>
                        {item.list.map((current, idx) => {
                          return (
                            <li key={"evaluationDetailList" + idx}>
                              {current}
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </section>

            <section id={titleData[4].id} className="paySection">
              <layout.TitleContainer data={titleData[4]} />

              <div className="pay">
                <p className="payTitle">{languageQuarter ? "급여체계(연봉제)" : "Pay system (annual salary system)"}</p>

                <ul className="payListWrap">
                  {payList.map((item, index) => {
                    return (
                      <li className="payList" key={"payList" + index}>
                        <div className="iconWrap">
                          <div className="icon">
                            <img src={item.icon} alt="payIcon" />
                          </div>
                        </div>

                        <p className="listTitle">{item.listTitle}</p>

                        <p className="description">{item.description}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </section>

            <section id={titleData[5].id} className="benefitSection">
              <layout.TitleContainer data={titleData[5]} />

              <div className="img">
                <img
                  src={isMobile ? ImgBenefitMb : ImgBenefit}
                  alt="ImgBenefit"
                />
              </div>
            </section>
          </div>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default RecruitmentPage;
