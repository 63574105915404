import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;

  & .modalInner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 530px;
    min-height: 270px;
    padding: 60px 45px 48px;
    text-align: center;
    background-color: #fff;

    & .closeButton {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 24px;
      height: 24px;
    }

    & .modalText {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.44;
    }

    & .confirmButton {
      margin-top: auto;
    }
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }

  @media (max-width: 1740px) {
    & .modalInner {
      width: calc(100vw * (530 / 1740));
      min-height: calc(100vw * (270 / 1740));
      padding: calc(100vw * (60 / 1740)) calc(100vw * (45 / 1740))
        calc(100vw * (48 / 1740));

      & .closeButton {
        top: calc(100vw * (20 / 1740));
        right: calc(100vw * (20 / 1740));
        width: calc(100vw * (24 / 1740));
        height: calc(100vw * (24 / 1740));
      }

      & .modalText {
        font-size: calc(100vw * (18 / 1740));
      }
    }
  }

  @media (max-width: 768px) {
    & .modalInner {
      width: calc(100vw * (304 / 375));
      min-height: calc(100vw * (244 / 375));
      padding: calc(100vw * (50 / 375)) calc(100vw * (27 / 375));

      & .closeButton {
        top: calc(100vw * (10 / 375));
        right: calc(100vw * (10 / 375));
        width: calc(100vw * (24 / 375));
        height: calc(100vw * (24 / 375));
      }

      & .modalText {
        font-size: calc(100vw * (16 / 375));
      }

      & .confirmButton {
        margin-top: calc(100vw * (50 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
