import Styles from "./styles";
import React from "react";
import { api } from "service";
import { common } from "components";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useBanner, useLanguage, useMain } from "hooks";

// scroll drag
import ScrollContainer from "react-indiana-drag-scroll";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css";
import { Pagination, Autoplay, Navigation } from "swiper";

// fullpage
import ReactFullpage from "@fullpage/react-fullpage";

// img
import Logo from "resources/image/icon/logo.svg";
// import IconBottom from "resources/image/icon/icon_bottom.svg";
import IconPaperAirplane from "resources/image/icon/icon_paper_airplane.svg";
import IconTime from "resources/image/icon/icon_time.svg";
import IconSmile from "resources/image/icon/icon_smile.svg";
import IconTopButton from "resources/image/icon/icon_top.svg";

function MainPage() {
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ query: "(max-width: 728px)" });
  const bannerInfo = useBanner();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  const mainInfo = useMain({
    productList: true,
    youtubeList: true,
  });

  const bannerData = bannerInfo.getBanner?.data?.data || [];
  const productData = mainInfo.getProductList?.data?.data || null;
  const youtubeData = mainInfo.getYoutubeList?.data?.data || [];

  // map
  const contactList = [
    {
      title: languageQuarter ? "영업문의 전화" : "Sales Inquiry Contact No.",
      description: "02-555-0883",
    },
    {
      title: languageQuarter ? "영업문의 이메일" : "Sales Inquiry Contact No.",
      description: "sales@enertork.com",
    },
    {
      title: languageQuarter ? (
        <>
          A/S 문의 전화<span>평일 09:00 - 18:00</span>
        </>
      ) : (
        <>
          After Sales Inquiry Phone No.
          <span>Weekdays: 09:00 - 18:00</span>
        </>
      ),
      description: "031-880-2860~2",
    },
  ];

  const philosophyList = [
    {
      icon: IconPaperAirplane,
      description: languageQuarter ? (
        <>
          빠른납기 <br />
          Quick Delivery
        </>
      ) : (
        <>Quick Delivery</>
      ),
    },
    {
      icon: IconTime,
      description: languageQuarter ? (
        <>
          빠른서비스 <br />
          Quick Service
        </>
      ) : (
        <>Quick Service</>
      ),
    },
    {
      icon: IconSmile,
      description: languageQuarter ? (
        <>
          빠른응대 <br />
          Quick Response
        </>
      ) : (
        <>Quick Response</>
      ),
    },
  ];

  return (
    <>
      <common.Header main />
      <Styles.Container>
        <ReactFullpage
          licenseKey={"4ZMJ8-99LJK-8K1L7-JVTQ6-OMUKM"}
          scrollingSpeed={1000}
          render={() => {
            return (
              <ReactFullpage.Wrapper>
                <section className="section bannerSection">
                  <div className="logoWrap">
                    <img src={Logo} alt="Logo" />
                  </div>

                  {/* <div className="bottomIconWrap">
                    <img src={IconBottom} alt="IconBottom" />
                  </div> */}

                  <Swiper
                    className="bannerSlider"
                    slidesPerView={1}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    pagination={{ clickable: true }}
                    allowTouchMove={false}
                    loop={true}
                    initialSlide={1}
                    modules={[Pagination, Autoplay]}
                  >
                    {bannerData.map((item, index) => {
                      return (
                        <SwiperSlide
                          className="slideList"
                          key={"slideList" + index}
                        >
                          <img
                            src={
                              languageQuarter
                                ? isMobile ? item.mb_image : item.pc_image
                                : isMobile ? item.mb_image_en : item.pc_image_en
                            }
                            alt={"bannerImg" + index}
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </section>

                {productData && (
                  <section className="section productSection">
                    <h2 className="inner">
                      {languageQuarter ? "제품 소개" : "Introduction of item"}
                    </h2>

                    <Swiper
                      className="productSlider"
                      slidesPerView={"auto"}
                      spaceBetween={45}
                      navigation={true}
                      centeredSlides={true}
                      allowTouchMove={false}
                      autoplay={{delay:1500}}
                      breakpoints={{ 768: { spaceBetween: 55 } }}
                      loop={true}
                      modules={[Navigation,Autoplay]}
                    >
                      {productData.map((item, index) => {
                        return (
                          <SwiperSlide
                            key={"productList" + index}
                            onClick={() => {
                              navigate(item.url);
                            }}
                          >
                            <div className="img">
                              <img style={{objectFit :"contain"}} src={item.image} alt="productImg" />
                            </div>

                            <div className="info">
                              <p className="title">
                                {item["title" + languageInfo.suffix]}
                              </p>
                              <p className="description">
                                {item["introduce" + languageInfo.suffix]}
                              </p>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </section>
                )}

                <section className="section newsSection">
                  <div className="inner">
                    <div className="left">
                      <div className="title">News</div>

                      <ScrollContainer
                        className="scroll-container"
                        vertical={false}
                      >
                        <ul>
                          {youtubeData.map((item, index) => {
                            return (
                              <li key={"newsList" + index}>
                                <iframe
                                  src={item["url" + languageInfo.suffix]}
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  referrerPolicy="strict-origin-when-cross-origin"
                                  allowFullScreen
                                ></iframe>
                              </li>
                            );
                          })}
                        </ul>
                      </ScrollContainer>
                    </div>

                    <div className="right">
                      <div className="title">Customer Center</div>

                      <div className="customerCenterContainer">
                        <ul className="contact">
                          {contactList.map((item, index) => {
                            return (
                              <li
                                className="contactList"
                                key={"contactList" + index}
                              >
                                <p className="listTitle">{item.title}</p>
                                <p className="description">
                                  {item.description}
                                </p>
                              </li>
                            );
                          })}
                        </ul>

                        <div className="philosophy">
                          <p className="philosophyTitle">
                            {languageQuarter
                              ? "에너토크의 3Q 철학"
                              : "Enertork's 3Q Philosophy"}
                          </p>

                          <ul className="philosophyListWrap">
                            {philosophyList.map((item, index) => {
                              return (
                                <li
                                  className="philosophyList"
                                  key={"philosophyList" + index}
                                >
                                  <div className="icon">
                                    <img
                                      src={item.icon}
                                      alt={"philosophy" + index}
                                    />
                                  </div>
                                  <p className="description">
                                    {item.description}
                                  </p>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <div className="section fp-auto-height footer">
                  <common.Footer main />
                </div>
              </ReactFullpage.Wrapper>
            );
          }}
        />
      </Styles.Container>

      <button
        type="button"
        className="topButton"
        onClick={() => {
          window.fullpage_api.moveTo(1, 0);
        }}
      >
        <img src={IconTopButton} alt="IconTopButton" />
      </button>
    </>
  );
}

export default MainPage;
