import { useState } from "react";
import { useQuery } from "react-query";
import { api } from "service";

export default function useSupport({
  listTextOption,
  optionListOption,
  serialOption,
}) {
  const [updateData, setUpdateData] = useState({ list: [], option: [] });
  const [optionKeyMap, setOptionKeyMap] = useState({});
  //상단 표준 전기회로도 조회 리스트
  const [list, setList] = useState([]);

  useQuery(["serviceList", listTextOption], () => {
    return api.support.getListText(listTextOption);
  }, {
    enabled: !!listTextOption?.recordSize,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setUpdateData(e => { return { ...e, list: listTextOption.keyword == null ? [] : data } });
    }
  });

  useQuery(["serviceOption", optionListOption], () => {
    return api.support.getOptionList(optionListOption)
  }, {
    enabled: !!optionListOption,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setUpdateData(e => { return { ...e, option: data } });
      initServiceOption(data.data)
    }
  });

  const getSerial = useQuery(["serial", serialOption], () => {
    return api.support.getSerial(serialOption);
  }, {
    enabled: !!(serialOption?.code1 && serialOption?.code2),
    refetchOnWindowFocus: false,
  });

  const initServiceOption = (_list) => {
    setList([..._list]);

    let resultKey = {};
    _list.map((l)=>{ l?.option_list?.map((value)=>{
      resultKey[String(value.id)] = value;
    })})
    setOptionKeyMap(resultKey);
  }

	return {
    list,
    getSerial,
    updateData,
    optionKeyMap,
	};
}