import {
  DefaultModal,
  FindIdModal,
  FindPwModal,
  OptionModal,
} from "components/modal";
import { useModals } from "hooks";
import { useEffect } from "react";

// img
export default function ModalGeneratePage() {
  const modalOption = useModals();

  useEffect(() => {
    const body = document.body;
    const html = document.querySelector("html");
    if (
      modalOption.defaultModal.show ||
      modalOption.findIdModal.show ||
      modalOption.findPwModal.show ||
      modalOption.optionModal.show
    ) {
      body.className = "hidden";
      html.className = "hidden";
    } else {
      body.classList.remove("hidden");
      html.classList.remove("hidden");
    }
  }, [modalOption]);

  return (
    <>
      {
        //기본 모달
        modalOption.defaultModal.show ? (
          <DefaultModal
            modalOption={modalOption.defaultModal}
            modalClose={() => {
              modalOption.setDefaultModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //아이디 찾기 모달
        modalOption.findIdModal.show ? (
          <FindIdModal
            modalOption={modalOption.findIdModal}
            modalClose={() => {
              modalOption.setFindIdModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //비밀번호 찾기 모달
        modalOption.findPwModal.show ? (
          <FindPwModal
            modalOption={modalOption.findPwModal}
            modalClose={() => {
              modalOption.setFindPwModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //제품 소개 옵션 모달
        modalOption.optionModal.show ? (
          <OptionModal
            modalOption={modalOption.optionModal}
            modalClose={() => {
              modalOption.setOptionModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
    </>
  );
}
