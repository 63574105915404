import { useLanguage } from "hooks";

const StockRoute = ({ children }) => {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  if (languageQuarter) {
    return children;
  } else {
    window.location.replace("/investment/stock");
    return <></>;
  }
}

export default StockRoute