import Styles from "./styles";
import ui from "components/ui";
import { useState } from "react";
import { useAuth } from "hooks";

function FindIdForm({ setResult, language }) {
  const [errorText, setErrorText] = useState({});
  const [inputs, setInputs] = useState({
    company: "",
    division: "",
    name: "",
  });

  const authInfo = useAuth();

  const inputList = [
    {
      name: "company",
      value: inputs.company,
      labelText: language ? "회사" : "Company",
      placeholder: language
        ? "회사명을 입력해주세요."
        : "Please enter the company name.",
      errorText: errorText.company,
    },
    {
      name: "division",
      value: inputs.division,
      labelText: language ? "부서" : "Department",
      placeholder: language
        ? "부서명을 입력해주세요."
        : "Please enter your department name.",
      errorText: errorText.division,
    },
    {
      name: "name",
      value: inputs.name,
      labelText: language ? "이름" : "Name",
      placeholder: language
        ? "이름을 입력해주세요."
        : "Please enter your name.",
      errorText: errorText.name,
    },
  ];

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs((e) => {
      return { ...e, [name]: value };
    });
  };

  const validate = () => {
    let isError = false;
    if (inputs.company === "") {
      setErrorText(e => {
        return { ...e,
          company: language
            ? "회사명을 입력해주세요."
            : "Please enter the company name."
        }
      })
      isError = true;
    } else setErrorText(e => { return { ...e, company: "" } })

    if (inputs.division === "") {
      setErrorText(e => {
        return { ...e,
          division: language
            ? "부서명을 입력해주세요."
            : "Please enter your department name."
        }
      })
      isError = true;
    } else setErrorText(e => { return { ...e, division: "" } })

    if (inputs.name === "") {
      setErrorText(e => {
        return { ...e,
          name: language
            ? "이름을 입력해주세요."
            : "Please enter your name."
        }
      })
      isError = true;
    } else setErrorText(e => { return { ...e, name: "" } })
  
    if (isError) return false;
    return true;
  }
  
  const onNext = async () => {
    if (validate()) {
      const idResult = await authInfo.findId(inputs);
      
      if (idResult.success) setResult(idResult.data)
      else { setErrorText(e => {
        return { ...e, find: language
          ? "일치하는 정보가 없습니다."
          : "No matching information."
        }
      })}
    }
  }

  return (
    <Styles.Container>
      <form>
        <ul className="inputWrap">
          {inputList.map((item, index) => {
            return (
              <li className="inputList" key={"inputList" + index}>
                <ui.input.BasicInput data={item} onChange={onChange} />
              </li>
            );
          })}
        </ul>

        <div className="findButton">
          <p className="errorText">{errorText.find}</p>

          <ui.button.BasicButton
            buttonText={language ? "아이디 찾기" : "Find your ID"}
            onClick={onNext}
          />
        </div>
      </form>
    </Styles.Container>
  );
}

export { FindIdForm };

export default FindIdForm;
