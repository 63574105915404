import ModalPage from "./ModalPage";
import MainPage from "./MainPage";
import TermsPage from "./TermsPage";
import CompanyIntroductionPage from "./CompanyIntroductionPage";
import RecruitmentPage from "./RecruitmentPage";
import SearchPage from "./SearchPage";
import LoginPage from "./LoginPage";
import SignupPage from "./SignupPage";
import ConfirmPage from "./ConfirmPage";
import MyPage from "./MyPage";
import InvestmentPage from "./InvestmentPage";
import ProductPage from "./ProductPage";
import RelatedDataPage from "./RelatedDataPage";
import ServicePage from "./ServicePage";
import MorganPage from "./MorganPage";
import MorganDetailPage from "./MorganDetailPage";

export default {
  ModalPage,
  MainPage,
  TermsPage,
  CompanyIntroductionPage,
  RecruitmentPage,
  SearchPage,
  LoginPage,
  SignupPage,
  ConfirmPage,
  MyPage,
  InvestmentPage,
  ProductPage,
  RelatedDataPage,
  ServicePage,
  MorganPage,
  MorganDetailPage,
};

export {
  ModalPage,
  MainPage,
  TermsPage,
  CompanyIntroductionPage,
  RecruitmentPage,
  SearchPage,
  LoginPage,
  SignupPage,
  ConfirmPage,
  MyPage,
  InvestmentPage,
  ProductPage,
  RelatedDataPage,
  ServicePage,
  MorganPage,
  MorganDetailPage,
};
