import Styles from "./styles";
// import { api } from "service";
// import { ui, layout } from "components";
// import { useState } from "react";
import components from "..";

// img
import IconWarning from "resources/image/icon/icon_warning.svg";

function Internal() {
  // const [page, setPage] = useState(1);

  const data = [{
    title: "내부정보규정관리",
    data_list: [
      {
        icon: "PDF",
        file_name: "data/upload/orig_a89a76ef-8d45-4ca0-9257-0247d760cb85.pdf",
        origin_file_name: "내부정보규정관리.pdf",
      },
    ],
  }]

  return (
    <Styles.Container>
      <div className="title">
        내부정보 규정관리
        <p className="warningText">
          <span className="icon">
            <img src={IconWarning} alt="IconWarning" />
          </span>
          열리지 않을 경우 PDF, CAD, Word를 <br className="mbOnly" />
          설치 후 사용하세요.
        </p>
      </div>

      <div className="table">
        <ul className="thead">
          <li className="listTitle">내부정보 규정관리</li>

          <li className="download">다운로드</li>
        </ul>

        {/* {data.length > 0 ? (
          <> */}
            <ul className="tbody">
              {data?.slice(0, 15).map((item, index) => {
                return (
                  <li className="tr" key={"tr" + index}>
                    <components.DownloadList data={item} />
                  </li>
                );
              })}
            </ul>

            {/* {data.length > 15 && (
              <div className="pagination">
                <ui.button.Pagination
                  page={page}
                  setPage={setPage}
                  list={[1, 2, 3, 4, 5]}
                />
              </div>
            )}
          </>
        ) : (
          <layout.EmptyContainer text={"게시글이 존재하지 않습니다."} />
        )} */}
      </div>
    </Styles.Container>
  );
}

export default Internal;
