import connector from "service/connector"


const getHistory = async () => {
  return await connector.connectFetchController("history", "GET");
}


export {
  getHistory,
};

export default {
  getHistory,
}