import styled from "styled-components";

const Container = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  & .nowSelect {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    padding: 0 20px;
    background-color: var(--gray03);

    & .iconWrap {
      width: 18px;
      height: 18px;
    }

    &.open .iconWrap {
      transform: rotate(180deg);
    }
  }

  @media (max-width: 1740px) {
    font-size: calc(100vw * (16 / 1740));

    & .nowSelect {
      height: calc(100vw * (70 / 1740));
      padding: 0 calc(100vw * (20 / 1740));

      & .iconWrap {
        width: calc(100vw * (18 / 1740));
        height: calc(100vw * (18 / 1740));
      }
    }
  }

  @media (max-width: 768px) {
    font-size: calc(100vw * (16 / 375));

    & .nowSelect {
      height: calc(100vw * (70 / 375));
      padding: 0 calc(100vw * (20 / 375));

      & .iconWrap {
        width: calc(100vw * (18 / 375));
        height: calc(100vw * (18 / 375));
      }
    }
  }
`;

const OptionContainer = styled.div`
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  width: 100%;
  max-height: 250px;
  background-color: var(--gray02);
  z-index: 2;

  & .menuContainer {
    max-height: inherit;
  }

  & .optionList {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 20px;
  }

  @media (max-width: 1740px) {
    max-height: calc(100vw * (250 / 1740));

    & .optionList {
      height: calc(100vw * (40 / 1740));
      padding: 0 calc(100vw * (20 / 1740));
    }
  }

  @media (max-width: 768px) {
    max-height: calc(100vw * (250 / 375));

    & .optionList {
      height: calc(100vw * (40 / 375));
      padding: 0 calc(100vw * (20 / 375));
    }
  }
`;

export { Container, OptionContainer };

export default { Container, OptionContainer };
