import Styles from "./styles";
import { layout } from "components";

function RelatedDataList({ data, type, number, suffix }) {
  return (
    <Styles.Container>
      <div className="number">{number}</div>

      <div className="category">{type}</div>

      {data["url" + suffix]
        ? <a href={data["url" + suffix]} target="_blank" className="linkTitle">{data["title" + suffix] || data.title}</a>
        : <div className="listTitle">{data["title" + suffix] || data.title}</div>
      }

      <layout.DownloadButtonList data={data.file_list} />
    </Styles.Container>
  );
}

export default RelatedDataList;
