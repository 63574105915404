import { useLanguage } from "hooks";
import Styles from "./styles";

// img
import IconArrowHorizon from "resources/image/icon/icon_arrow_horizon.svg";

function ScrollGuide() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  
  return (
    <Styles.Container>
      <div className="icon">
        <img src={IconArrowHorizon} alt="IconArrowHorizon" />
      </div>
      {/* 좌우 스크롤로 내용을 보실 수 있습니다. */}
      {languageQuarter ? "좌우 스크롤로 내용을 확인해보세요." : "Please scroll left and right"}
    </Styles.Container>
  );
}

export default ScrollGuide;
