import Styles from "./styles";
import { useState } from "react";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom.svg";

function MorganList({ data }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Styles.Container>
      <div
        className={"title" + (isOpen ? " on" : "")}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {data.title}

        <div className="icon">
          <img src={IconArrowBottom} alt="IconArrowBottom" />
        </div>
      </div>

      {isOpen &&
        (data.subCategory ? (
          data.subCategory.map((item, index) => {
            return (
              <div className="tableWrap" key={"tableWrap" + index}>
                <p className="subCategoryTitle">{item.subCategoryTitle}</p>

                <ul className="table">
                  {item.list.map((current, idx) => {
                    return (
                      <li className="tableList" key={"tableList" + idx}>
                        <div className="th">{current.title}</div>

                        <div className="td">
                          {current.text ? current.text : "-"}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })
        ) : (
          <ul className="table">
            {data.list.map((item, index) => {
              return (
                <li className="tableList" key={"tableList" + index}>
                  <div className="th">{item.title}</div>

                  <div className="td">{item.text ? item.text : "-"}</div>
                </li>
              );
            })}
          </ul>
        ))}
    </Styles.Container>
  );
}

export default MorganList;
