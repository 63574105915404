import utils from "utils";
import Styles from "./styles";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import components from "./components";
import { useMediaQuery } from "react-responsive";
import { common, layout, ui } from "components";
import { useEffect, useState } from "react";
import { useAuth, useLanguage, useSupport } from "hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// img
import BgMainBanner from "resources/image/service/bg_main_banner.jpg";
import BgMainBannerMb from "resources/image/service/bg_main_banner_mb.jpg";

function ServicePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const param = useParams();
  const isMobile = useMediaQuery({ query: "(max-width: 728px)" });

  // const authInfo = useAuth();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const data = {
    img: isMobile ? BgMainBannerMb : BgMainBanner,
    title: languageQuarter ? "서비스 · 지원" : "Serviceㆍsupport",
    link: "service",
    firstDepthList: [
      {
        text: languageQuarter ? "자료실" : "Reference room",
        link: "reference",
        secondDepthList: [
          {
            text: languageQuarter ? "카다로그" : "Catalog",
            link: "catalog",
          },
          {
            text: languageQuarter ? "회로도" : "Circuit diagram",
            link: "circuit-diagram",
          },
          {
            text: languageQuarter ? "외형도" : "Appearance chart",
            link: "external-map",
          },
          {
            text: languageQuarter ? "토크 테이블" : "Torque Table",
            link: "table",
          },
          {
            text: languageQuarter ? "소프트웨어" : "Software",
            link: "software",
          },
          {
            text: languageQuarter ? "인증사항" : "Certification Matters",
            link: "certification",
          },
        ],
      },
      {
        text: languageQuarter ? "A/S 지원" : "A/S support",
        link: "as",
        secondDepthList: [
          {
            text: languageQuarter ? "자주 묻는 질문" : "Frequently asked questions",
            link: "faq",
          },
          {
            text: languageQuarter ? "운영자 교육자료" : "Operator training materials",
            link: "educational-materials",
          },
          {
            text: languageQuarter ? "유지보수 지침서" : "Maintenance Guidelines",
            link: "guidelines",
          },
          {
            text: languageQuarter ? "셋팅" : "Setting",
            link: "setting",
          },
          {
            text: languageQuarter ? "교체/수리" : "Replacement/repair",
            link: "as",
          },
          {
            text: languageQuarter ? "원자력 A/S" : "Nuclear Power A/S",
            link: "nuclear-as",
          },
        ],
      },
    ],
  };

  // side menu 에 필요한 url 구분
  const firstPathNumber = data.firstDepthList.findIndex(
    (v) => v.link === location.pathname.split("/")[2]
  );
  const secondPathNumber = data.firstDepthList[
    firstPathNumber
  ].secondDepthList.findIndex(
    (v) => v.link === location.pathname.split("/")[3]
  );

  const [filter, setFilter] = useState(null);
  const [selectOption, setSelectOption] = useState({});
  
  const supportInfo = useSupport({
    optionListOption: {
      option_id_list: Object.values(selectOption)?.map((value)=> value.id),
      type: (secondPathNumber + (firstPathNumber * 6)) + 1,
    },
    listTextOption: filter && {
      keyword: (filter.keyword == "" || filter.keyword) ? filter.keyword : null,
      option_id_list: filter.option_id_list ? filter.option_id_list : '',
      page: filter.page,
      recordSize: 5,
      pageSize: 5,
      type: (secondPathNumber + (firstPathNumber * 6)) + 1,
    },
    serialOption: filter && {
      code1: filter.code?.split("-")[0] || "",
      code2: filter.code?.split("-")[1] || "",
      page: filter.page,
      recordSize: 5,
      pageSize: 5,
    }
  });

  const resultData = supportInfo.updateData?.list || [];
  const optionData = supportInfo.updateData?.option?.data || [];
  const serialData = supportInfo.getSerial?.data || [];

  const [inputs, setInputs] = useState({
    search: "",
    firstSerialNumber: "",
    secondSerialNumber: "",
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
    sixth: "",
    seventh: "",
    eighth: "",
    ninth: "",
  });


  const replaceNone = () => { 
    return inputs.search?.split(" ").filter((value) => { return value != '' && String(value).toLowerCase() != 'none' }).join(" ");
  }

  const inputData = {
    search: {
      name: "search",
      value: replaceNone(),
      placeholder: languageQuarter ? "검색어를 입력해주세요." : "Enter a search word.",
    },
    serial: [
      {
        name: "firstSerialNumber",
        type: "number",
        value: inputs.firstSerialNumber,
        placeholder: "00",
      },
      {
        name: "secondSerialNumber",
        type: "number",
        value: inputs.secondSerialNumber,
        placeholder: "0000",
      },
    ],
    normal: supportInfo.list.filter((v,i)=> i != 0 )
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    if (name !== "secondSerialNumber" && name !== "search" && value.length > 2) return;
    if (name === "secondSerialNumber" && value.length > 4) return;

    setInputs((e) => {
      return { ...e, [name]: value };
    });
  };

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? param.page : 1,
    };
    if (param.search) {
      param.search = param.search.replace(/\+/gi,' ');
      f.search = param.search;
    }
    if (param.code) {
      f.code = param.code;
    }
    if(param.option_id_list && param.option_id_list != "0"){
      f.option_id_list = param.option_id_list;
    }
    if(param.secondDepth === "circuit-diagram" && param.option_id_list){
      let keyword = (param.option_id_list?.split(",") || []).map((value)=>{ 
        return supportInfo.optionKeyMap[String(value)]?.title || "" 
      }).filter((value)=>{ return value != '' && String(value).toLowerCase() != 'none' }).join(",");
      f.keyword = keyword;
    }else if(param.secondDepth != "circuit-diagram" && param.search){
      f.keyword = param.search.split(" ").filter(value=> String(value).toLowerCase() != 'none').join(" ");
    }else{
      if(param.search == ''){
        f.keyword = '';
      }else
        f.keyword = null;
    }
    setFilter({ ...f });
  }, [location])

  useEffect(() => {
    setSelectOption({})
    setInputs({
      search: "",
      firstSerialNumber: "",
      secondSerialNumber: "",
    });
  }, [secondPathNumber]);

  const SerialSearch = () => {
    // if (authInfo.certification() && inputs.firstSerialNumber != "" && inputs.secondSerialNumber != "") {
    navigate(utils.etc.getQueryParamUrl({
      focus: "",
      code: inputs.firstSerialNumber + (inputs.secondSerialNumber ? "-" : "") + inputs.secondSerialNumber,
      page: 1,
    }, false))
    // }
  }

  const search = () => {
    navigate(utils.etc.getQueryParamUrl({
      option_id_list: ((Object.values(selectOption).map((value)=> value.id).join(",")) || "0"),
      focus: "search_result_bottom",
      search: inputs.search,
      page: 1,
      disableScrollTop : true,
    }, true))
  }

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Banner data={data} />

        <div className="inner">
          <common.ProductSideMenu data={data} isService={true}/>

          <div className="narrowInner">
            {param.secondDepth === "circuit-diagram" && (
              <section className="serialSection">
                <h2 className="sectionTitle">{languageQuarter ? "회로도 조회" : "Circuit diagram inquiry"}</h2>
                <p className="subSectionTitle">{languageQuarter ? "시리얼번호별 전기회로도 조회" : "Electrical circuit diagram search for each serial"}</p>

                <div className="serialFilter">
                  <div className="inputWrap">
                    {inputData?.serial?.map((item, index) => {
                      return (
                        <div className="input" key={"serialInput" + index}>
                          <ui.input.BasicInput
                            data={item}
                            onChange={onChange}
                            onKeyUp={SerialSearch}
                          />
                        </div>
                      );
                    })}
                  </div>

                  <div className="buttonWrap">
                    <ui.button.BasicButton
                      buttonText={languageQuarter ? "검색" : "Search"}
                      onClick={SerialSearch}
                    />

                    <button
                      type="button"
                      className="resetButton"
                      onClick={() => {
                        navigate(location.pathname + "?disableScrollTop=true")
                        setInputs({
                          search: "",
                          firstSerialNumber: "",
                          secondSerialNumber: "",
                        });
                      }}
                    >
                      {languageQuarter ? "초기화" : "Initialization"}
                    </button>
                  </div>
                </div>

                <components.ServiceSearch
                  data={serialData}
                  language={languageInfo}
                  disableScrollTop={true}
                  type={0}
                />
              </section>
            )}

            <section className="totalSearchSection">
              {param.secondDepth === "circuit-diagram" ? (
                <>
                  <h2 className="sectionTitle">{languageQuarter ? "회로도 조회" : "Circuit diagram inquiry"}</h2>
                  <p className="subSectionTitle">{languageQuarter ? "표준 전기회로도 조회" : "Standard electrical circuit diagram inquiry"}</p>

                  <div className="normalFilter">
                    <div className="modelSelect">
                      <ui.input.ModelSelect
                        placeholder={languageQuarter ? "모델명" : "Model name"}
                        // select={(optionData[0]?.option_list?.filter(v => selectOption[v.id])[0] || { title: "-" })["title" + languageInfo.suffix]}
                        select={(optionData[0]?.option_list?.filter(v => selectOption[v.id])[0] || { title: "-" })["code"]}
                        setSelect={(e)=>{
                          let _item = optionData[0]?.option_list.filter((v)=> (languageQuarter ? v.title : v.title_en) == e)[0] || {};
                          setSelectOption(obj => { 
                            if(!obj[_item.id]){
                              obj[_item.id] = _item;
                            }else{
                              delete obj[_item.id]
                            }
                            return {...obj }
                          });
                        }}
                        optionList={optionData[0]?.option_list?.filter((v)=> v.show_yn == "Y")}
                      />
                    </div>

                    {inputData.normal?.map((item, index) => {
                      return (
                        <div
                          className="inputWrap"
                          key={"modelInputWrap" + index}
                        >
                          <div className="input" key={"modelInput" + item.id}>
                            <ui.input.InputSelect
                              data={{
                                // value : (item.option_list.filter(v => selectOption[v.id])[0] || { title : "-" })["title" + languageInfo.suffix],
                                value : (item.option_list.filter(v => selectOption[v.id])[0] || { title : "-" })["code"] || "-",
                                name : item.id,
                                list: item.option_list?.filter(v=> v.show_yn == "Y").map((value)=>{ return {
                                  title : value.code,
                                  name : value.name,
                                  id : value.id,
                                  code : value.title
                                };})
                              }}
                              onChange={(_item)=>{ 
                                setSelectOption(obj => { 
                                  if(!obj[_item.id]){
                                    _item.temp = _item.code;
                                    _item.code = _item.title;
                                    _item.title = _item.temp;
                                    obj[_item.id] = _item;
                                  }else{
                                    delete obj[_item.id]
                                  }
                                  return {...obj }
                                });
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}

                    <div className="buttonWrap">
                      <ui.button.BasicButton
                        buttonText={languageQuarter ? "검색" : "Search"}
                        onClick={()=>{
                          navigate(utils.etc.getQueryParamUrl({
                            disableScrollTop : true,
                            focus:"search_result_bottom",
                            option_id_list: ((Object.values(selectOption).map((value)=> value.id).join(",")) || "0"),
                            search: inputs.search,
                            page: 1,
                          }, true));
                        }}
                      />

                      <button
                        type="button"
                        className="resetButton"
                        onClick={() => {
                          navigate(location.pathname + "?disableScrollTop=true")
                          setInputs({
                            search: "",
                          });
                          setSelectOption({});
                        }}
                      >
                        {languageQuarter ? "초기화" : "Initialization"}
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h2 className="sectionTitle">
                    {
                      data.firstDepthList[firstPathNumber]
                        .secondDepthList[secondPathNumber].text
                    }
                  </h2>

                  <div className="inputContainer totalInputContainer">
                    <div className="input">
                      <ui.input.BasicInput
                        data={inputData.search}
                        onChange={onChange}
                        onKeyUp={search}
                      />
                    </div>

                    <div className="buttonWrap">
                      <ui.button.BasicButton
                        buttonText={languageQuarter ? "검색" : "Search"}
                        onClick={search}
                      />

                      <button
                        type="button"
                        className="resetButton"
                        onClick={() => {
                          navigate(location.pathname +"?disableScrollTop=true")
                          setInputs({
                            search: "",
                          });
                          setSelectOption({});
                        }}
                      >
                        {languageQuarter ? "초기화" : "Initialization"}
                      </button>
                    </div>
                  </div>
                </>
              )}

              <div className="checkArea">
                <ResponsiveMasonry
                  className="serviceListWrap"
                  columnsCountBreakPoints={{ 767: 1, 768: 2 }}
                >
                  <Masonry>
                    {supportInfo?.list?.map((item, index) => {
                      return (
                        <div
                          className="serviceList"
                          key={"serviceList" + index}
                        >
                          <div className="title">{item["title" + languageInfo.suffix]}</div>

                          <ul className="checkListWrap">
                            {item.option_list?.map((current, idx) => {
                              return (
                                <li
                                  className="checkList"
                                  key={"checkList" + idx}
                                >
                                  <ui.input.ServiceCheckbox
                                    suffix={languageInfo.suffix}
                                    data={current}
                                    checked={
                                      selectOption[current.id] ? true : false
                                    }
                                    onChange={(e) => {
                                      setInputs(v => {
                                        const selectSearch = (optionData.map((v, index) => {
                                          return selectOption[Object.keys(selectOption)[index]]?.title
                                        }));
                                        return { ...v, search: selectSearch.join(" ").trim() };
                                      })
                                      setSelectOption(obj=> { 
                                        if(e.target.checked){
                                          obj[current.id] = current;
                                        }else{
                                          delete obj[current.id]
                                        }
                                        return {...obj }
                                      });
                                    }}
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    })}
                  </Masonry>
                </ResponsiveMasonry>
              </div>
              <div id={"search_result_bottom"}>
                <components.ServiceSearch
                  data={resultData}
                  language={languageInfo}
                  disableScrollTop={true}
                  type={1}
                />
              </div>
            </section>
          </div>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default ServicePage;
