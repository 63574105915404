import Styles from "./styles";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { common, layout, ui } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import { useLanguage, useModals, useProduct } from "hooks";

// img
import BgMainBanner from "resources/image/product/bg_main_banner.jpg";
import BgMainBannerMb from "resources/image/product/bg_main_banner_mb.jpg";

function ProductPage() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const location = useLocation();
  const navigate = useNavigate();
  const modalOption = useModals();
  const isMobile = useMediaQuery({ query: "(max-width: 728px)" });

  const productInfo = useProduct({
    id: location.pathname.split("/")[location.pathname.split("/").length - 1],
    product: true,
  });
  const menuData = productInfo.listQuery?.data?.data || [];
  const productData = productInfo.detailQuery?.data?.data || [];

  const [imgIndex, setImgIndex] = useState(0);

  const data = {
    img: isMobile ? BgMainBannerMb : BgMainBanner,
    title: languageQuarter ? "제품 소개" : "Product descriptions",
    link: "product",
    firstDepthList: menuData.map(firstItem => {
      return {
        text: firstItem["title" + languageInfo.suffix],
        link: firstItem.id,
        secondDepthList: firstItem.product_list.length > 0 && firstItem.product_list.map(sceondItem => {
          return {
            text: sceondItem["title" + languageInfo.suffix],
            link: sceondItem.id,
            thirdDepthList: sceondItem.product_list.length > 0 && sceondItem.product_list.map(thirdItem => {
              return {
                text: thirdItem["title" + languageInfo.suffix],
                link: thirdItem.id,
              }
            })
          }
        })
      }
    })
  }

  useEffect(() => {
    if (data?.firstDepthList?.[0]?.secondDepthList?.[0]?.link) {
      if (location.pathname === "/product/0/0") {
        navigate(
          `/product/${data?.firstDepthList?.[0]?.link}/${data?.firstDepthList?.[0]?.secondDepthList?.[0]?.link}`,
          { replace: true }
        )
      }
    }
  }, [data]);

  useEffect(() => {
    setImgIndex(0);
  }, [location]);

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Banner data={data} />

        <div className="inner">
          <common.ProductSideMenu data={data} />

          <div className="narrowInner">
            <section className="productSection">
              <h2 id={"product_title_id"} className="productName">{productData["title" + languageInfo.suffix]}</h2>
              <p className="description">{productData["introduce" + languageInfo.suffix]}</p>

              <div className="imgContainer">
                <div className="thumbnail">
                  <img src={productData.image_list?.[imgIndex]?.path} />
                </div>

                <ul className="imgListWrap">
                  {productData.image_list?.slice(0, 6).map((item, index) => {
                    return (
                      <li
                        className={
                          "imgList" + (imgIndex === index ? " on" : "")
                        }
                        onClick={() => {
                          setImgIndex(index);
                        }}
                        key={"imgList" + index}
                      >
                        <img src={item.path} alt="productImg" />
                      </li>
                    );
                  })}
                </ul>
              </div>

              <p className="detailDescription">
                {productData["description" + languageInfo.suffix]}
              </p>
            </section>

            <section className="categorySection">
              <div className="wrap">
                <div className="categoryContainer">
                  <div className="categoryWrap">
                    <h2 className="categoryTitle">
                      {productData["item" + languageInfo.suffix]}
                    </h2>

                    <p className="categoryDescription">
                      {productData["item_introduce" + languageInfo.suffix]}
                    </p>
                  </div>
                </div>

                {productData.type == 1 ? (
                  <div className="itemContainer">
                    <h2>{languageQuarter ? "종류" : "type"}</h2>

                    <ul className="itemListWrap">
                      {productData.relate_list?.map((item, index) => {
                        return (
                          <li
                            className="itemList"
                            key={"itemList" + index}
                            onClick={()=>{
                              navigate(window.location.pathname + "/" + item.id)
                            }}
                            // 클릭 시 layoutType 1로 변경
                          >
                            <div className="itemImg">
                              <img src={item.image_path} alt="itemImg" />
                            </div>

                            <p className="itemName">{item["title" + languageInfo.suffix]}</p>

                            <p className="itemDescription">
                              {item["introduce" + languageInfo.suffix]}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  <div className="optionContainer">
                    {
                      productData?.option_list && productData?.option_list.length > 0 ?
                      <h2>{languageQuarter ? "옵션" : "Options"}</h2>
                      :
                      null
                    }

                    <ul className="optionListWrap">
                      {productData?.option_list?.map((item, index) => {
                        return (
                          <li
                            className="optionList"
                            onClick={() => {
                              modalOption.setOptionModal((e) => {
                                e.show = true;
                                e.modalTitle = item["title" + languageInfo.suffix];
                                e.modalText = item["content" + languageInfo.suffix]

                                return { ...e };
                              });
                            }}
                            key={"optionList" + index}
                          >
                            <p className="optionTitle">{item["title" + languageInfo.suffix]}</p>

                            <p className="moreText">{languageQuarter ? "자세히 보기 >" : "Read more >"}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>

              {productData.type == 2 && (
                <div className="relateButton">
                  <ui.button.BasicButton
                    buttonText={languageQuarter ? "관련자료" : "reference"}
                    onClick={() => {
                      navigate("/related-data?id=" + productData.id, {state: location.pathname});
                    }}
                  />
                </div>
              )}
            </section>
          </div>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default ProductPage;
