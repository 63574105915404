import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  font-weight: 600;
  border-bottom: 1px solid var(--gray02);

  & .listTitle {
    width: calc(100% - 300px);
    padding: 0 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media (max-width: 1740px) {
    height: calc(100vw * (80 / 1740));

    & .listTitle {
      width: calc(100% - calc(100vw * (300 / 1740)));
      padding: 0 calc(100vw * (20 / 1740));
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: calc(100vw * (15 / 375));
    height: auto;
    padding: calc(100vw * (15 / 375)) 0;

    & .listTitle {
      width: 100%;
      padding: 0;
    }
  }
`;

export { Container };

export default { Container };
