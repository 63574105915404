import connector from "service/connector"


const getTerm = async (path) => {
  return await connector.connectFetchController("terms?type=" + path, "GET");
}


export {
  getTerm,
};


export default {
  getTerm,
}