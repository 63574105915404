import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 280px;
  margin-bottom: 80px;
  font-size: 46px;
  font-weight: 800;
  color: #fff;
  letter-spacing: -0.03em;
  background: no-repeat center / cover;

  @media (max-width: 1740px) {
    height: calc(100vw * (280 / 1740));
    margin-bottom: calc(100vw * (80 / 1740));
    font-size: calc(100vw * (46 / 1740));
  }

  @media (max-width: 768px) {
    height: calc(100vw * (${({isMain})=>{ return isMain ? 155 : 310 }} / 375));
    margin-bottom: 0;
    font-size: calc(100vw * (30 / 375));
  }
`;

export { Container };

export default { Container };
