import Styles from "./styles";
import ui from "components/ui";

function FindPwResult({ modalClose, language }) {
  return (
    <Styles.Container>
      <p className="text">
        {language ? <>
          입력하신 이메일 주소로 <br />
          <span>임시 비밀번호</span>가 발송되었습니다.
        </> : <>
          <span>A temporary password</span> has been sent to <br />
          the E-mail address you entered.
        </>}
      </p>

      <p className="grayText">
        {language
          ? "임시 비밀번호로 로그인 후 비밀번호를 변경해주세요."
          : "Please change your password after logging in with a temporary password."
        }
      </p>

      <div className="confirmButton">
        <ui.button.BasicButton
          buttonText={language ? "확인" : "Check"}
          onClick={() => {
            modalClose();
          }}
        />
      </div>
    </Styles.Container>
  );
}

export { FindPwResult };

export default FindPwResult;
