import styled from "styled-components";

const Container = styled.div`
  & .title {
    margin-bottom: 30px;
  }

  @media (max-width: 1740px) {
  }

  @media (max-width: 768px) {
  }
`;

export { Container };

export default { Container };
