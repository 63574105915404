import styled from "styled-components";

const Container = styled.div`
  padding: 80px 0 200px;
  min-height: 100vh;

  & section {
    padding-top: 80px;

    & .logo {
      width: 262px;
      height: 76px;
      margin: 0 auto 20px;
    }

    & h2,
    .title {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
    }

    & h2 {
      margin-bottom: 80px;
    }

    & .title {
      margin-bottom: 20px;

      & p {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 500;
        color: var(--gray01);

        & .icon {
          display: inline-block;
          width: 20px;
          height: 20px;
        }
      }
    }

    & .inputWrap .inputList:not(:last-child) {
      margin-bottom: 40px;
    }

    & .inputContainer {
      margin-bottom: 50px;
    }

    & .buttonWrap {
      display: flex;
      justify-content: center;
      gap: 40px;
      margin-top: 10px;
    }

    & .logoutButton,
    .accountDeleteButton {
      font-size: 16px;
      color: var(--red);
    }

    & .logoutButton {
      position: relative;

      &::after {
        position: absolute;
        top: 50%;
        right: -20px;
        transform: translateY(-50%);
        width: 1px;
        height: 14px;
        background-color: var(--main);
        content: "";
      }
    }
  }

  @media (max-width: 1740px) {
    padding: calc(100vw * (80 / 1740)) 0 calc(100vw * (200 / 1740));

    & section {
      padding-top: calc(100vw * (80 / 1740));

      & .logo {
        width: calc(100vw * (262 / 1740));
        height: calc(100vw * (76 / 1740));
        margin: 0 auto calc(100vw * (20 / 1740));
      }

      & h2,
      .title {
        font-size: calc(100vw * (24 / 1740));
      }

      & h2 {
        margin-bottom: calc(100vw * (80 / 1740));
      }

      & .title {
        margin-bottom: calc(100vw * (20 / 1740));

        & p {
          gap: calc(100vw * (5 / 1740));
          margin-top: calc(100vw * (10 / 1740));
          font-size: calc(100vw * (16 / 1740));

          & .icon {
            width: calc(100vw * (20 / 1740));
            height: calc(100vw * (20 / 1740));
          }
        }
      }

      & .inputWrap .inputList:not(:last-child) {
        margin-bottom: calc(100vw * (40 / 1740));
      }

      & .inputContainer {
        margin-bottom: calc(100vw * (50 / 1740));
      }

      & .buttonWrap {
        gap: calc(100vw * (40 / 1740));
        margin-top: calc(100vw * (10 / 1740));
      }

      & .logoutButton,
      .accountDeleteButton {
        font-size: calc(100vw * (16 / 1740));
      }

      & .logoutButton {
        &::after {
          right: calc(100vw * (-20 / 1740));
          height: calc(100vw * (14 / 1740));
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (50 / 375)) 0 calc(100vw * (150 / 375));

    & section {
      padding-top: calc(100vw * (50 / 375));

      & .logo {
        width: calc(100vw * (158 / 375));
        height: calc(100vw * (46 / 375));
        margin: 0 auto calc(100vw * (20 / 375));
      }

      & h2,
      .title {
        font-size: calc(100vw * (18 / 375));
      }

      & h2 {
        margin-bottom: calc(100vw * (50 / 375));
      }

      & .title {
        margin-bottom: calc(100vw * (20 / 375));

        & p {
          gap: calc(100vw * (5 / 375));
          margin-top: calc(100vw * (5 / 375));
          font-size: calc(100vw * (14 / 375));

          & .icon {
            width: calc(100vw * (20 / 375));
            height: calc(100vw * (20 / 375));
          }
        }
      }

      & .inputWrap .inputList:not(:last-child) {
        margin-bottom: calc(100vw * (30 / 375));
      }

      & .inputContainer {
        margin-bottom: calc(100vw * (50 / 375));
      }

      & .buttonWrap {
        gap: calc(100vw * (20 / 375));
        margin-top: calc(100vw * (10 / 375));
      }

      & .logoutButton,
      .accountDeleteButton {
        font-size: calc(100vw * (14 / 375));
      }

      & .logoutButton {
        &::after {
          right: calc(100vw * (-10 / 375));
          height: calc(100vw * (11 / 375));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
