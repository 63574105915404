import Banner from "./Banner";
import TitleContainer from "./TitleContainer";
import ScrollGuide from "./ScrollGuide";
import EmptyContainer from "./EmptyContainer";
import DownloadButtonList from "./DownloadButtonList";

export {
  Banner,
  TitleContainer,
  ScrollGuide,
  EmptyContainer,
  DownloadButtonList,
};

export default {
  Banner,
  TitleContainer,
  ScrollGuide,
  EmptyContainer,
  DownloadButtonList,
};
