import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 375px;
  min-height: 100vh;
  margin: 0 auto;

  & .thinInner {
    padding: 50px 20px 63px;

    & .logo {
      width: 158px;
      height: 46px;
      margin: 0 auto 50px;
    }

    & .tabWrap {
      display: flex;
      margin-bottom: 30px;

      & .tabList {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 70px;
        font-size: 16px;
        font-weight: 600;
        color: var(--gray01);
        background-color: var(--gray03);
        cursor: pointer;

        &.on {
          color: #fff;
          background-color: var(--main);
        }
      }
    }

    & section {
      &:not(:last-child) {
        margin-bottom: 50px;
      }
    }

    & h2 {
      padding-bottom: 20px;
      font-size: 24px;
      font-weight: bold;
      border-bottom: 3px solid var(--black);
    }

    & .specificationSection {
      & ul {
        margin: 20px 0 30px;

        & li {
          &:not(:last-child) {
            margin-bottom: 30px;
          }

          &:last-child {
            & label {
              display: inline-block;
              margin-bottom: 5px;
              font-size: 16px;
              font-weight: 600;
            }
          }

          & .inputContainer {
            display: flex;
            gap: 5px;
          }
        }
      }
    }

    & .searchReasultSection {
      & .emptyText {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 450px;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 600;
        color: var(--gray01);
      }

      & .morganSearchList {
        border-bottom: 1px solid var(--gray02);

        & a {
          padding: 21px 0;
          font-weight: 600;

          & .top {
            display: flex;
            gap: 15px;
            margin-bottom: 10px;
            font-size: 14px;
            color: var(--main);
          }

          & .actuatorName {
            display: -webkit-box;
            font-size: 18px;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }

      & .pagination {
        margin-top: 50px;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    & .thinInner {
      padding: calc(100vw * (50 / 375)) calc(100vw * (20 / 375))
        calc(100vw * (63 / 375));

      & .logo {
        width: calc(100vw * (158 / 375));
        height: calc(100vw * (46 / 375));
        margin: 0 auto calc(100vw * (50 / 375));
      }

      & .tabWrap {
        margin-bottom: calc(100vw * (30 / 375));

        & .tabList {
          height: calc(100vw * (70 / 375));
          font-size: calc(100vw * (16 / 375));
        }
      }

      & section {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (50 / 375));
        }
      }

      & h2 {
        padding-bottom: calc(100vw * (20 / 375));
        font-size: calc(100vw * (24 / 375));
        border-bottom: calc(100vw * (3 / 375)) solid var(--black);
      }

      & .specificationSection {
        & ul {
          margin: calc(100vw * (20 / 375)) 0 calc(100vw * (30 / 375));

          & li {
            &:not(:last-child) {
              margin-bottom: calc(100vw * (30 / 375));
            }

            &:last-child {
              & label {
                margin-bottom: calc(100vw * (5 / 375));
                font-size: calc(100vw * (16 / 375));
              }
            }

            & .inputContainer {
              gap: calc(100vw * (5 / 375));
            }
          }
        }
      }

      & .searchReasultSection {
        & .emptyText {
          height: calc(100vw * (450 / 375));
          margin-top: calc(100vw * (20 / 375));
          font-size: calc(100vw * (14 / 375));
        }

        & .morganSearchList {
          & a {
            padding: calc(100vw * (21 / 375)) 0;

            & .top {
              gap: calc(100vw * (15 / 375));
              margin-bottom: calc(100vw * (10 / 375));
              font-size: calc(100vw * (14 / 375));
            }

            & .actuatorName {
              font-size: calc(100vw * (18 / 375));
            }
          }
        }

        & .pagination {
          margin-top: calc(100vw * (50 / 375));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
