import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 200px;
  background-color: var(--main);

  & .inner {
    display: flex;
    align-items: flex-start;
    color: #fff;

    & .logo {
      width: 158px;
      height: 46px;
      margin-right: 60px;
    }

    & .info {
      max-width: 900px;
      font-size: 16px;
      font-weight: 600;

      & .infoList {
        display: flex;
        gap: 20px;

        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }

    & .termsLink {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 149px;
      height: 35px;
      margin-left: auto;
      font-size: 16px;
      font-weight: bold;
      border: 1px solid #fff;
      text-align: center;
    }
  }

  @media (max-width: 1740px) {
    height: calc(100vw * (200 / 1740));

    & .inner {
      & .logo {
        width: calc(100vw * (158 / 1740));
        height: calc(100vw * (46 / 1740));
        margin-right: calc(100vw * (60 / 1740));
      }

      & .info {
        max-width: calc(100vw * (900 / 1740));
        font-size: calc(100vw * (16 / 1740));

        & .infoList {
          gap: calc(100vw * (20 / 1740));

          &:not(:last-child) {
            margin-bottom: calc(100vw * (5 / 1740));
          }
        }
      }

      & .termsLink {
        width: calc(100vw * (149 / 1740));
        height: calc(100vw * (35 / 1740));
        font-size: calc(100vw * (16 / 1740));
      }
    }
  }

  @media (max-width: 768px) {
    height: calc(100vw * (350 / 375));

    & .inner {
      flex-direction: column;

      & .logo {
        width: calc(100vw * (103 / 375));
        height: calc(100vw * (30 / 375));
        margin: 0 0 calc(100vw * (24 / 375));
      }

      & .info {
        max-width: calc(100vw * (300 / 375));
        font-size: calc(100vw * (12 / 375));

        & .infoList {
          flex-wrap: wrap;
          gap: calc(100vw * (8 / 375)) calc(100vw * (20 / 375));

          &:not(:last-child) {
            margin-bottom: calc(100vw * (8 / 375));
          }
        }
      }

      & .termsLink {
        margin: calc(100vw * (24 / 375)) 0 0;
        width: calc(100vw * (120 / 375));
        height: calc(100vw * (30 / 375));
        font-size: calc(100vw * (14 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
