import { atom } from 'jotai'

const userInfo = atom({
  company: "",
  created_at: null,
  delete_yn: "N",
  division: "",
  email_regist_code: null,
  id: "",
  name: "",
  nickname: "",
  password: "",
  role: "",
  social_key: null,
  type: "",
  updated_at: null,
  username: ""
});

const introInfo = atom({
  auth: false,
});

export default { userInfo, introInfo };