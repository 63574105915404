import Styles from "./styles";

// img
import IconClose from "resources/image/icon/icon_close.svg";

function OptionModal({ modalOption, modalClose }) {
  return (
    <Styles.Container>
      <div className="modalInner" >
        <button
          type="button"
          className="closeButton"
          onClick={() => {
            modalClose();
          }}
        >
          <img src={IconClose} alt="IconClose" />
        </button>

        <p className="modalTitle">{modalOption.modalTitle}</p>

        <div className="editorContainer">
          <p
            className="modalText editor"
            dangerouslySetInnerHTML={{ __html: modalOption.modalText }}
          ></p>
        </div>
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { OptionModal };

export default OptionModal;
