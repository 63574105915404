import Styles from "./styles";
import utils from "utils";
import { useMediaQuery } from "react-responsive";
import { RelatedDataList } from "./components";
import { common, layout, ui } from "components";
import { useEffect, useState } from "react";
import { useHistory, useLanguage, useProduct } from "hooks";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

// img
import BgMainBanner from "resources/image/product/bg_main_banner.jpg";
import BgMainBannerMb from "resources/image/product/bg_main_banner_mb.jpg";
import IconWarning from "resources/image/icon/icon_warning.svg";

function RelatedDataPage() {
  const location = useLocation();
  const history = useHistory();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  const isMobile = useMediaQuery({ query: "(max-width: 728px)" });
  
  const banner = {
    img: isMobile ? BgMainBannerMb : BgMainBanner,
    title: languageQuarter ? "제품 소개" : "Product descriptions",
  };

  const [filter, setFilter] = useState({});
  const productInfo = useProduct({ supportOption: filter })
  const data = productInfo.supportQuery?.data || [];

  const relatedDataList = languageQuarter ? [
    { text: "전체" },
    { text: "카다로그" },
    { text: "회로도" },
    { text: "외형도" },
    { text: "토크 테이블" },
    { text: "소프트웨어" },
    { text: "인증사항" },
    { text: "자주 묻는 질문" },
    { text: "운영자 교육자료" },
    { text: "유지보수 지침서" },
    { text: "셋팅" },
    { text: "교체/수리" },
    { text: "원자력 A/S" },
  ] : [
    { text: "Entire" },
    { text: "Catalog" },
    { text: "Circuit diagram" },
    { text: "Appearance chart" },
    { text: "Torque Table" },
    { text: "Software" },
    { text: "Certification Matters" },
    { text: "Frequently asked questions" },
    { text: "Operator training materials" },
    { text: "Maintenance Guidelines" },
    { text: "Setting" },
    { text: "Replacement/ repair" },
    { text: "Nuclear Power A/S" },
  ];

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page ? param.page : 1,
      type: param.select ? param.select : 0,
      recordSize: 15,
      pageSize: 5,
    };
    if (searchParams.get("id")) {
      f.product_info_id = searchParams.get("id");
    }
    setFilter({ ...f });
  }, [location]);

  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Banner data={banner} />

        <div className="narrowInner">
          <div className="title">
            {languageQuarter ? "관련 자료실" : "Reference archives"}
            <p className="warningText">
              <span className="icon">
                <img src={IconWarning} alt="IconWarning" />
              </span>
              {languageQuarter ? <>
                열리지 않을 경우 PDF, CAD, Word를 <br className="mbOnly" />
                설치 후 사용하세요.
              </> : <>
                If it doesn't open, install PDF, CAD, and Word before using it.
              </>}
            </p>
            <div className="select">
              <ui.input.BasicSelect
                select={relatedDataList[filter.type]?.text}
                setSelect={(e) => {
                  navigate(
                    utils.etc.getQueryParamUrl({
                      page: 1,
                      select: relatedDataList.map(i => i.text).indexOf(e)
                    }, false),
                    { state: location.state }
                  )
                }}
                optionList={relatedDataList}
              />
            </div>
          </div>

          <div className="table">
            <ul className="thead">
              <li className="number">{languageQuarter ? "번호" : "No."}</li>
              <li className="category">{languageQuarter ? "분류" : "Classification"}</li>
              <li className="listTitle">{languageQuarter ? "제목" : "Title"}</li>
              <li className="download">{languageQuarter ? "다운로드" : "Download"}</li>
            </ul>

            {data.list?.length > 0 ? (
              <>
                <ul className="tbody">
                  {data.list?.map((item, index) => {
                    return (
                      // 번호 역순 정렬
                      <li className="tr" key={"tr" + index}>
                        <RelatedDataList
                          data={item}
                          type={relatedDataList[item.type]?.text}
                          number={data.pagination.totalRecordCountAll - index - ((data.pagination.page - 1) * 15)}
                          suffix={languageInfo.suffix}
                        />
                      </li>
                    );
                  })}
                </ul>

                {data.pagination?.totalRecordCountAll > 15 && (
                  <div className="pagination">
                    <ui.button.Pagination
                      page={data.pagination?.page}
                      list={data.pagination?.page_list}
                      maxPage={data.pagination?.totalPageCount}
                      history={location.state}
                    />
                  </div>
                )}
              </>
            ) : (
              <layout.EmptyContainer text={languageQuarter ? "자료가 존재하지 않습니다." : "Data does not exist."} />
            )}
          </div>

          <div className="goBackButton">
            <ui.button.BasicButton
              buttonText={languageQuarter ? "돌아가기" : "Return"}
              onClick={() => {
                navigate(location.state || "/product/0/0");
              }}  
            />
          </div>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default RelatedDataPage;
