import Styles from "./styles";
import { ip } from "service/connector";
import { layout } from "components";
import { useState } from "react";
import { useHistory, useLanguage, useModals, usePatent } from "hooks";

// scroll drag
import ScrollContainer from "react-indiana-drag-scroll";

// img
import IconBuildings from "resources/image/icon/icon_buildings.svg";
import IconPeople01 from "resources/image/icon/icon_people01.svg";
import IconGarage from "resources/image/icon/icon_garage.svg";
import IconGear from "resources/image/icon/icon_gear.svg";
import IconHand from "resources/image/icon/icon_hand.svg";
import IconPeople02 from "resources/image/icon/icon_people02.svg";
import IconQuick from "resources/image/icon/icon_quick.svg";
import IconAirplane from "resources/image/icon/icon_airplane.svg";
import IconHeadphone from "resources/image/icon/icon_headphone.svg";
import ImgOrganization from "resources/image/companyIntroduction/companyInfo/img_organization.png";
import ImgOrganizationEn from "resources/image/companyIntroduction/companyInfo/img_organization_en.png";

function CompanyInfo() {
  const [tabIndex, setTabIndex] = useState(0);
  const modalOption = useModals();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const histroyInfo = useHistory();
  const patentInfo = usePatent({ type: tabIndex + 1 });

  const historyData = histroyInfo.getHistory?.data?.data || [];
  const patentData = patentInfo.getPatent?.data?.data || [];


  const titleData = {
    h2: languageQuarter ? "회사 정보" : "Who we are",
    title: (
      languageQuarter ? <>
        기술혁신을 선도하며, <br className="mbOnly" />
        고객과 함께 세계로, <br />
        미래로 전진하는 (주)에너토크
      </> : <>
        Leading technological innovation, moving forward to the world
        and the future with customers, Enertork
      </>
    ),
    description: (
      languageQuarter ? <>
        (주)에너토크는 1987년 설립 이후 정수장, 하수처리장, 플랜트, 발전소 등
        국가 기반산업에 사용되는 전동 액추에이터 업계의 선두주자입니다. <br />
        지속적인 기술혁신으로 더 높은 품질의 제품 개발과{" "}
        <br className="mbOnly" />
        더 가치있는 서비스를 통하여 전 세계의 고객과 함께 더불어 성장해 나가는
        작지만 강한 강소기업입니다. <br />
        주요 제품은 전동 액추에이터, 공압 액추에이터, <br className="mbOnly" />
        유압 액추에이터, 수문권양기 등이 있으며, <br className="mbOnly" />
        3Q 철학을 바탕으로 고객 니즈 충족과 더불어 최고의 만족을{" "}
        <br className="pcOnly" />
        실현하기 위한 서비스를 지향하고 있습니다.
      </> : <>
        Enertork Co., Ltd, established in 1987, has been a leading player in the electric actuator
        industry used in national infrastructure such as water purification plants, sewage treatment,
        oil & gas facilities, and power plants. Additionally, through continuous technological innovation,
        we strive to develop higher-quality products and provide more valuable services, growing together
        with customers worldwide. Despite being a small company, we are a strong player in the industry. <br />
        Our main products include electric actuators, pneumatic actuators, hydraulic actuators, sluice gate hoists and more.
        Based on the 3Q philosophy, we strive to provide services that not only meet customer needs but also achieve the highest satisfaction.
      </>
    ),
  };
  const overviewList = [
    {
      icon: IconBuildings,
      title: languageQuarter ? "설립" : "Establishment",
      description: languageQuarter ? "1987년 5월 16일" : "May 16, 1987",
    },
    {
      icon: IconPeople01,
      title: languageQuarter ? "대표이사" : "CEO",
      description: languageQuarter ? "이충열 사장" : "Chung Yeol Lee",
    },
    {
      icon: IconGarage,
      title: languageQuarter ? "사업장" : "Venue",
      description: languageQuarter ? (
        <>
          본사 : 경기도 여주시 <br />
          제 1공장 : 경기도 여주시 <br />
          제 2공장 : 대구광역시 달성군 <br />
          제 3공장 : 베트남 빈둥 <br />
          서울사무소 : 서울특별시 강남구
        </>
      ) : (
        <>
          Head Office : Yeo-ju, Gyeonggi Province <br />
          Plant 1 : Yeoju, Gyeonggi Province <br />
          Plant 2 : Daegu Metropolitan City <br />
          Plant 3 : Vinh Duong, Vietnam <br />
          Seoul Office : Gangnam-gu, Seoul
        </>
      ),
    },
    {
      icon: IconGear,
      title: languageQuarter ? "주요 제품" : "Main Products",
      description: languageQuarter ? (
        <>
          전동 액추에이터 <br />
          공압 액추에이터 <br />
          유압 액추에이터 <br />
          수문권양기 <br />
          전자계측/제어 장비
        </>
      ) : (
        <>
          Electric actuator <br />
          Pneumatic actuator <br />
          Hydraulic actuator <br />
          Sluice gate hoist <br />
          Electronic measurement/Control equipment
        </>
      ),
    },
    {
      icon: IconHand,
      title: languageQuarter ? "재무 개요" : "Financial Overview",
      description: languageQuarter ? (
        <>
          2006년 코스닥 상장 <br />
          자본금 : 48.8억 원
        </>
      ) : (
        <>
          Listed on KOSDAQ in 2006 <br />
          Capital : 48.8 billion Korea Won
        </>
      ),
    },
    {
      icon: IconPeople02,
      title: languageQuarter ? "인적 자원" : "Employees",
      description: languageQuarter ? "100명" : "100 persons",
    },
  ];
  const threeQList = [
    {
      icon: IconQuick,
      title: "Quick Service",
      description: languageQuarter ? "가장 빠른 문제해결" : <>The fastest problem <br />resolution</>,
    },
    {
      icon: IconAirplane,
      title: "Quick Delivery",
      description: languageQuarter ? "가장 빠른 납기" : "The fastest lead time",
    },
    {
      icon: IconHeadphone,
      title: "Quick Response",
      description: languageQuarter ? "가장 빠른 대응" : "The fastest action",
    },
  ];
  const tabList = [
    {
      text: languageQuarter ? "보유특허" : "Patent",
    },
    {
      text: languageQuarter ? "인증서" : "Certificates",
    },
  ];

  return (
    <Styles.Container>
      <layout.TitleContainer data={titleData} />

      <section className="overviewSection">
        <div id="company_step_01" className="sectionTitle" >
          <p className="color">{languageQuarter ? "회사 개요" : "Overview"}</p>
        </div>

        <ul className="overview">
          {overviewList.map((item, index) => {
            return (
              <li className="overviewList" key={"overviewList" + index}>
                <div className="icon">
                  <img src={item.icon} alt="overviewIcon" />
                </div>

                <p className="listTitle">{item.title}</p>

                <p className="listDescription">{item.description}</p>
              </li>
            );
          })}
        </ul>

        <ul className="threeQ">
          {threeQList.map((item, index) => {
            return (
              <li className="threeQList" key={"threeQList" + index}>
                <div className="icon">
                  <img src={item.icon} alt="threeQIcon" />
                </div>

                <p className="listTitle">{item.title}</p>

                <p className="listDescription">{item.description}</p>
              </li>
            );
          })}
        </ul>
      </section>

      <section className="historySection">
        <div id="company_step_02" className="sectionTitle">
          <p className="color">{languageQuarter ? "주요연혁" : "History"}</p>
          <p className="sectionDescription">{languageQuarter ? "끊임없이 성장하는 (주)에너토크" : "Ever-growing Enertork"}</p>
        </div>

        {/* 기존 퍼블 */}
        {/* <ul className="history">
          {api.dummy.historyList?.map((item, index) => {
            return (
              <li className="historyList" key={"historyList" + index}>
                <div className="tag">{item.tag}</div>
                <p className="year">{item.year}</p>
                <p className="listTitle">{item.title}</p>
                <ul>
                  {item.detailList.map((current, idx) => {
                    return (
                      <li className="detailList" key={"detailList" + idx}>
                        {current}
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul> */}

        <ul className="history">
          {historyData.map((item, index) => {
            return (
              <li className="historyList" key={"historyList" + index}>
                <div className="tag">{item["tag" + languageInfo.suffix]}</div>
                <p className="year">{item.year}</p>
                <p className="listTitle">{item["title" + languageInfo.suffix]}</p>
                <ul className="detailList"
                  dangerouslySetInnerHTML={{__html: item["description" + languageInfo.suffix]}}
                >
                </ul>
              </li>
            );
          })}
        </ul>
      </section>

      <section className="organizationSection">
        <div id="company_step_03" className="sectionTitle">
          <p className="color">{languageQuarter ? "조직도" : "Organization"}</p>
          <p className="sectionDescription">
            {languageQuarter ? <>
              효과적인 지원, 효율적인 업무 <br className="mbOnly" />
              (주)에너토크의 도약
            </> : <>
              Effective Support, Efficient Workflow, Enertork's Leap
            </>}
          </p>
        </div>

        <p className="description">
          {languageQuarter ? <>
            ㈜에너토크는 가장 효율적인 업무 진행을 위해 다음과 같이 주요 사업
            조직으로 구축하고 있으며, <br className="pcOnly" />
            주요 사업 조직을 효과적으로 지원하기 위해 인사총무 및 재무 조직이
            있습니다. 아울러 ㈜에너토크의 미래 신성장을 <br className="mbOnly" />
            이끌어 갈 사업을 추진하기 위해 <br className="pcOnly" />
            신사업본부 조직을 구축하고 있습니다. 글로벌 스탠다드에 부합하는{" "}
            <br className="mbOnly" />
            경영을 완성하기 위해 2023년에는 ESG경영실을 신설하였으며, ESG경영
            도입을 통해 <br className="pcOnly" />
            세계적으로 경쟁력 있는 ㈜에너토크로 도약할 것을 확신하고 있습니다.
          </> : <>
            To ensure the most efficient workflow, we have organized our main business units as follows: We also
            have human resources and finance organizations in place to effectively support these core business units.
            Also, we have established the New Business Division to drive the future sustainable growth of Enertork.
            To achieve management in line with global standards, we established the ESG Management Office in 2023.
            Through the introduction of ESG management, we are confident in leaping into a globally competitive company
          </>}
        </p>
        

        <div className="imgWrap">
          <ScrollContainer className="scroll-container">
            <div className="img">
              <img
                src={
                  languageQuarter
                    ? ImgOrganization
                    : ImgOrganizationEn
                }
                alt="ImgOrganization"
              />
            </div>
          </ScrollContainer>

          <layout.ScrollGuide />
        </div>
      </section>

      <section className="greetingSection">
        <div id="company_step_04" className="sectionTitle">
          <p className="color">{languageQuarter ? "인사말" : "CEO Greeting"}</p>
          <p className="sectionDescription">
            {languageQuarter ? <>
              전동 액추에이터 산업의 <br className="mbOnly" />
              국내 선두주자 <br className="pcOnly" />
              (주)에너토크
            </> : <>
              A domestic leader in the electric
              actuator industry <br className="pcOnly" />
              ENERTORK LTD.
            </>}
          </p>
        </div>

        <p className="description">
          {languageQuarter ? <>
            1987년 설립한 ㈜에너토크는 전동 액추에이터 산업의 국내 선두주자로,{" "}
            <br className="pcOnly" />
            혁신적인 제품과 서비스를 선보이며 국내 기간사업의 발전을 위해 지금껏
            열정으로 달려왔습니다. <br className="pcOnly" />
            지난 30여 년간 고객으로부터의 깊은 신뢰와 사랑을 바탕으로 이 자리까지
            도달할 수 있었으며, <br className="pcOnly" />
            이제는 국내 시장을 넘어 해외시장으로의 확장을 통해 글로벌 기업들과
            당당히 어깨를 겨루고자 <br />
            끊임없는 기술과 품질혁신을 위해 매진하고 있습니다. <br />
            <br />
            ㈜에너토크는 고객의 가치를 최우선으로 생각하며, 지속적인 제품과 서비스
            혁신을 통해 <br className="pcOnly" />
            고객들에게 끊임없이 더 높은 가치를 창출해 드리고자 각고의{" "}
            <br className="mbOnly" />
            노력을 기울이고 있습니다. <br />
            “기본경영, 표준경영, 변화경영”의 경영방침을 바탕으로 끊임없이 변화하는
            비즈니스 환경에 <br className="pcOnly" />
            발맞추며 해외시장에서도 한국을 대표하는 액추에이터 기업으로 거듭나도록
            하겠습니다. <br />
            <br />
            반드시 고객과 협력회사, 파트너사들과 함께 세계로, <br />
            미래로 힘차게 걸어가 반드시 글로벌 Top 10 기업이{" "}
            <br className="mbOnly" />될 것을 굳건히 약속 드립니다.
            <br />
            <br />
            감사합니다.
          </> : <>
            Founded in 1987, Enertork has been a leading innovator in the domestic electric actuator sector,
            showcasing innovative products and services and contributing to the development of the domestic industries.
            Over the past 30 years, we have reached No.1 in Korea based on the deep trust and love from our customers.
            Now, we are dedicated to relentless technological and quality innovation, aiming to confidently compete with
            global competitors through expansion into international markets beyond the domestic market. <br />
            <br />
            We prioritize customer values above all else and are committed to continuous product and service innovation,
            dedicating our utmost efforts to constantly create higher value for our customers.
            We will strive to become a leading actuator company representing Korea in the global market, adapting to the
            ever-changing business environment based on the management principles of "Basic Management, Standard Management, and Change Management" <br />
            <br />
            We firmly promise to walk hand in hand with our customers, cooperative companies, and partners towards the world and the future.
            We are determined to become one of the global top 10 companies.
            <br />
            <br />
            Thank you so much.
          </>}
        </p>

        <div className="sign" >
          CEO <span>{languageQuarter ? "이충열" : "Chung-Yeol Lee"}</span>
        </div>
      </section>

      <section  className="patentSection">
        <div id="company_step_05" className="sectionTitle">
          <p className="color">{languageQuarter ? "보유특허/인증서" : "Certification & Patent"}</p>
          <p className="sectionDescription">{languageQuarter ? "(주)에너토크 보유 특허 현황" : "Status of Patents Held"}</p>
        </div>

        <ScrollContainer className="scroll-container tab">
          <ul>
            {tabList.map((item, index) => {
              return (
                <li
                  className={"tabList" + (tabIndex === index ? " on" : "")}
                  key={"tabList" + index}
                  onClick={() => {
                    setTabIndex(index);
                  }}
                >
                  {item.text}
                </li>
              );
            })}
          </ul>
        </ScrollContainer>

        <ul className="patent">
          {patentData.map((item, index) => {
            return (
              <li className="patentList" key={"patentList" + index}>
                <button
                  onClick={()=>{
                    modalOption.setDefaultModal((e) => {
                      e.show = true;
                      e.text = languageQuarter ? "정말 다운로드 하시겠습니까?" : "Are you sure you want to download it?";
                      e.buttonText = languageQuarter ? "다운로드" : "Download";
                      e.confirmButtonClick = () => {
                        return <a
                          href={window.location.href = ip + "s3/download?file_name=" + item.path + "&origin_file_name=" + item.origin_file_name}
                          download={item.origin_file_name}
                          key={"downloadList" + index}
                          target="_blank"
                        >
                        </a>
                      }
                      e.closeButton = true;
                      return { ...e };
                    })
                  }}
                >
                  <div className="img">
                    {item.thumbnail && <img src={item.thumbnail} alt="patentImg" />}
                  </div>

                  <p className="listTitle">{item["title" + languageInfo.suffix]}</p>

                  <p className="number">{item["description" + languageInfo.suffix]}</p>
                </button>
              </li>
            );
          })}
        </ul>
      </section>
    </Styles.Container>
  );
}

export default CompanyInfo;
