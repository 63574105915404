import Styles from "./styles";
import { common, ui } from "components";
import { useEffect, useState } from "react";
import { useAuth, useLanguage } from "hooks";
import { useLocation, useNavigate } from "react-router-dom";

// img
import Logo from "resources/image/icon/logo_gray.svg";
import IconWarning from "resources/image/icon/icon_warning.svg";

function MyPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  const authInfo = useAuth();

  const [isShow, setIsShow] = useState(false);
  const [errorText, setErrorText] = useState();
  const [inputs, setInputs] = useState({
    company: "",
    division: "",
    name: "",
    id: null,
    password: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const inputList = [
    {
      title: languageQuarter ? "소속 정보" : "Affiliation Information",
      list: [
        {
          name: "company",
          value: inputs.company,
          labelText: languageQuarter ? "회사" : "Company",
          disabled: true,
        },
        {
          name: "division",
          value: inputs.division,
          labelText: languageQuarter ? "부서" : "Department",
          disabled: true,
        },
      ],
    },
    {
      title: (
        <>
          {languageQuarter ? "개인정보" : "Personal Information"}
          <p>
            <span className="icon">
              <img src={IconWarning} alt="IconWarning" />
            </span>
            {languageQuarter
              ? "회원 정보 변경 문의는 고객센터로 문의해주세요."
              : <>
                If you have any questions about changing <br className="mbOnly" />
                your membership <br className="pcOnly" />
                information, <br className="mbOnly" />
                please contact the customer center.
              </>
            }
          </p>
        </>
      ),
      list: [
        {
          name: "name",
          value: inputs.name,
          labelText: languageQuarter ? "이름" : "Name",
          disabled: true,
        },
        {
          name: "id",
          value: inputs.id,
          labelText: languageQuarter ? "아이디(이메일)" : "ID (E-mail)",
          disabled: true,
        },
      ],
    },
    {
      title: languageQuarter ? "비밀번호 수정" : "Modifying Passwords",
      list: [
        {
          name: "password",
          value: inputs.password,
          labelText: languageQuarter ? "기존 비밀번호" : "Existing password",
          type: isShow ? "text" : "password",
          placeholder: languageQuarter ? "기존 비밀번호를 입력해주세요." : "Please enter an existing password.",
          errorText: errorText?.password,
        },
        {
          name: "newPassword",
          value: inputs.newPassword,
          labelText: languageQuarter ? "새로운 비밀번호 입력" : "Enter a new password",
          type: "password",
          guideText: languageQuarter
            ? "영문자, 숫자, 특수문자 포함 8자 이상"
            : <>
              8 or more characters, including <br className="pcOnly" />
              English, numbers, special symbol
            </>,
          placeholder: languageQuarter ? "새로운 비밀번호를 입력해주세요." : "Please enter a new password.",
          errorText: errorText?.newPassword,
        },
        {
          name: "confirmNewPassword",
          value: inputs.confirmNewPassword,
          labelText: languageQuarter ? "새로운 비밀번호 확인" : "Verifying a New Password",
          type: "password",
          placeholder: languageQuarter ? "새로운 비밀번호를 입력해주세요." : "Please enter a new password.",
          errorText: errorText?.confirmNewPassword,
        },
      ],
    },
  ];

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs((e) => {
      return { ...e, [name]: value };
    });
  };
  const validate = () => {
    const passwordRegEx = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[$&+,:;=?@#|'<>.^*()%!-])[0-9A-Za-z$&+,:;=?@#|'<>.^*()%!-]{8,30}$/;

    let isError = false;
    if (inputs.password === "") {
      setErrorText(e => {
        return { ...e,
          password: languageQuarter
            ? "비밀번호를 입력해주세요."
            : "Please enter your password."
        }
      })
      isError = true;
    } else setErrorText(e => { return { ...e, password: "" } })

    if (inputs.newPassword === "") {
      setErrorText(e => {
        return { ...e,
          newPassword: languageQuarter
            ? "비밀번호를 입력해주세요."
            : "Please enter your password."
        }
      })
      isError = true;
    } else if (passwordRegEx.test(inputs.newPassword) === false) {
      setErrorText(e => {
        return { ...e,
          newPassword: languageQuarter
            ? "비밀번호 조건을 확인해주세요."
            : "Please enter your password."
        }
      })
      isError = true;
    } else setErrorText(e => { return { ...e, newPassword: "" } })

    if (inputs.newPassword !== inputs.confirmNewPassword) {
      setErrorText(e => {
        return { ...e,
          confirmNewPassword: languageQuarter
            ? "비밀번호가 일치하지 않습니다."
            : "Please agree to the terms and conditions."
        }
      })
      isError = true;
    } else setErrorText(e => { return { ...e, confirmNewPassword: "" } })
  
    if (isError) return false;
    return true;
  }
  const onNext = () => {
    validate() && authInfo.changePassword(inputs.password, inputs.newPassword, setErrorText)
  }

  useEffect(()=>{
    !location.state && navigate("/");
  },[location]);

  useEffect(()=>{
    setInputs(e=>{ return { ...e,
      company: authInfo.userData?.company,
      division: authInfo.userData?.division,
      name: authInfo.userData?.name,
      id: authInfo.userData?.username,
    }})
  },[authInfo.userData]);

  return (
    location.state &&
    <>
      <common.Header />
      <Styles.Container>
        <section>
          <div className="formInner">
            <form>
              <div className="logo">
                <img src={Logo} alt="logo" />
              </div>
              <h2>{languageQuarter ? "회원 정보" : "Member Information"}</h2>

              {inputList.map((item, index) => {
                return (
                  <div
                    className="inputContainer"
                    key={"inputContainer" + index}
                  >
                    <div className="title">{item.title}</div>

                    <ul className="inputWrap">
                      {item.list.map((current, idx) => {
                        return (
                          <li className="inputList" key={"inputList" + idx}>
                            <ui.input.BasicInput
                              data={current}
                              onChange={onChange}
                              isShow={isShow}
                              setIsShow={setIsShow}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}

              <div className="changeButton">
                <ui.button.BasicButton
                  buttonText={languageQuarter ? "비밀번호 수정완료" : "Password modification completed"}
                  onClick={onNext}
                />
              </div>

              <div className="buttonWrap">
                <button
                  type="button"
                  className="logoutButton"
                  onClick={authInfo.logoutModal}
                >
                  {languageQuarter ? "로그아웃" : "Log out"}
                </button>

                <button
                  type="button"
                  className="accountDeleteButton"
                  onClick={authInfo.resign}
                >
                  {languageQuarter ? "탈퇴하기" : "Resign"}
                </button>
              </div>
            </form>
          </div>
        </section>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default MyPage;
