import { useEffect } from "react";
import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";
import Pages from "pages";
// 회사 소개
import {
  CompanyInfo,
  Management,
  Client,
  Place,
  Agency,
} from "pages/CompanyIntroductionPage/components";
// 검색
import { Search, Result } from "pages/SearchPage/components";
// 투자 정보
import {
  Stock,
  Public,
  Shareholder,
  Internal,
  Settlement,
} from "pages/InvestmentPage/components";
import utils from "utils";
import AccessRoute from "routes/AccessRoute";
import StockRoute from "routes/StockRoute";

export default function CustomRoute() {
  let location = useLocation();

  useEffect(() => {

    let param = utils.etc.getSearchParam();
    if (!param.disableScrollTop && !param.focus) {
      window.scrollTo(0, 0);
      document.body.scrollTo(0, 0);
    }

    if (param.focus) {
      try {
        setTimeout(() => {
          document.getElementById(utils.etc.getSearchParam().focus)?.scrollIntoView({ block: param.focus_block ? param.focus_block : "center", behavior: 'smooth' });
        }, 300);
      } catch (e) {

      }
    }
    
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Pages.MainPage />} />
      {/* 약관 */}
      <Route path="/terms/:id" element={<Pages.TermsPage />} />
      {/* 회사 소개 */}
      <Route
        path="/company/introduction"
        element={<Pages.CompanyIntroductionPage />}
      >
        <Route path="info" element={<CompanyInfo />} />
        <Route path="management" element={<Management />} />
        <Route path="client" element={<Client />} />
        <Route path="place" element={<Place />} />
        <Route path="agency" element={<Agency />} />
      </Route>
      {/* 투자 정보 */}
      <Route path="/investment" element={<Pages.InvestmentPage />}>
        <Route path="stock" element={<Stock />} />
        <Route path="public" element={<Public />} />
        <Route path="shareholder" element={<StockRoute><Shareholder /></StockRoute>} />
        <Route path="internal" element={<StockRoute><Internal /></StockRoute>} />
        <Route path="settlement" element={<StockRoute><Settlement /></StockRoute>} />
      </Route>
      {/* 채용 정보 */}
      <Route path="/recruitment" element={<Pages.RecruitmentPage />} />
      {/* 검색 */}
      <Route path="/search" element={<Pages.SearchPage />}>
        <Route path="" element={<Search />} />
        <Route path="result" element={<Result />} />
      </Route>
      {/* 제품 소개 */}
      <Route
        path="/product/:firstDepth/:secondDepth"
        element={<Pages.ProductPage />}
      />
      <Route
        path="/product/:firstDepth/:secondDepth/:thirdDepth"
        element={<Pages.ProductPage />}
      />
      <Route path="/related-data" element={<Pages.RelatedDataPage />} />
      {/* 로그인 */}
      <Route path="/login" element={<Pages.LoginPage />} />
      <Route path="/signup" element={<Pages.SignupPage />} />
      <Route path="/confirm" element={<Pages.ConfirmPage />} />
      {/* 서비스 · 지원 */}
      <Route
        path="/service/:firstDepth/:secondDepth"
        element={<Pages.ServicePage />}
      />
      {/* 마이페이지 */}
      <Route path="/mypage" element={<Pages.MyPage />} />
      {/* 모건 */}
      <Route path="/morgan" element={<AccessRoute><Pages.MorganPage /></AccessRoute>} />
      <Route path="/morgan/detail/:type/:id" element={<AccessRoute><Pages.MorganDetailPage /></AccessRoute>} />
    </Routes>
  );
}
