import styled from "styled-components";

import IconCheckOff from "resources/image/icon/icon_check_off.svg";
import IconCheckOn from "resources/image/icon/icon_check_on.svg";

const Input = styled.input`
  display: none;

  &:checked + label .checkbox {
    background-image: url(${IconCheckOn});
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  & .checkbox {
    flex-shrink: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background: url(${IconCheckOff}) no-repeat center / cover;
  }

  & .essential {
    color: var(--main);
  }

  @media (max-width: 1740px) {
    font-size: calc(100vw * (16 / 1740));

    & .checkbox {
      width: calc(100vw * (20 / 1740));
      height: calc(100vw * (20 / 1740));
      margin-right: calc(100vw * (10 / 1740));
    }
  }

  @media (max-width: 768px) {
    font-size: calc(100vw * (16 / 375));

    & .checkbox {
      width: calc(100vw * (20 / 375));
      height: calc(100vw * (20 / 375));
      margin-right: calc(100vw * (10 / 375));
    }
  }
`;

export { Input, Label };

export default { Input, Label };
