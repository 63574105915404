import Styles from "./styles";
import { useState } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom_triangle.svg";

function BasicSelect({ optionList, placeholder, select, setSelect }) {
  const [isOptionOpen, setIsOptionOpen] = useState(false);

  return (
    <Styles.Container>
      <div
        className={
          "nowSelect" +
          (select !== "" || isOptionOpen ? " on" : "") +
          (isOptionOpen ? " open" : "")
        }
        onClick={() => {
          setIsOptionOpen(!isOptionOpen);
        }}
      >
        {select !== "" ? select : placeholder}

        <div className="iconWrap">
          <img src={IconArrowBottom} alt="IconArrowBottom" />
        </div>
      </div>

      {isOptionOpen && (
        <Styles.OptionContainer>
          <OverlayScrollbarsComponent
            className="menuContainer"
            options={{ scrollbars: { autoHide: "scroll" } }}
          >
            <ul className="optionListWrap">
              {optionList?.map((item, index) => {
                return (
                  <li
                    className="optionList"
                    key={"optionList" + index}
                    onClick={() => {
                      setSelect(item.text);
                      setIsOptionOpen(false);
                    }}
                  >
                    {item.text}
                  </li>
                );
              })}
            </ul>
          </OverlayScrollbarsComponent>
        </Styles.OptionContainer>
      )}
    </Styles.Container>
  );
}

export default BasicSelect;
