import Styles from "./styles";
import { useEffect } from "react";
import { common, ui } from "components";
import { useAuth, useLanguage } from "hooks";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

// img
import Logo from "resources/image/icon/logo_gray.svg";
import IconConfirm from "resources/image/icon/icon_confirm.svg";

function ConfirmPage() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const authInfo = useAuth();

  useEffect(() => {
    authInfo.emailRegist({
      username: searchParams?.get("username"),
      email_regist_code: searchParams?.get("email_regist_code"),
    })
  }, [location]);

  return (
    location.state ?
      <>
        <common.Header />
        <Styles.Container>
          <section>
            <div className="formInner">
              <div className="logo">
                <img src={Logo} alt="logo" />
              </div>

              <h2>{languageQuarter ? "회원가입" : "Join membership"}</h2>

              <div className="icon">
                <img src={IconConfirm} alt="IconConfirm" />
              </div>

              {languageQuarter ?
                <p>
                  인증이 <span>완료</span>되었습니다. <br />
                  로그인 후 에너토크를 사용해보세요.
                </p> : <p>
                  Certification has been completed.
                  Try Enertork after logging in.
                </p>
              }

              <div className="loginButton">
                <ui.button.BasicButton
                  buttonText={languageQuarter ? "로그인 하기" : "Login"}
                  onClick={() => {
                    navigate("/login");
                  }}
                />
              </div>
            </div>
          </section>
        </Styles.Container>
        <common.Footer />
      </>
      :
      <>
        <common.Header />
        <Styles.Container>
          <section>
            <div className="formInner">
              <div className="logo">
                <img src={Logo} alt="logo" />
              </div>

              <h2>{languageQuarter ? "회원가입" : "Join membership"}</h2>

              {languageQuarter ?
                <p>
                  인증이 <span>실패</span>되었습니다. <br />
                </p> : <p>
                  Authentication <span>failed</span>
                </p>
              }
              <div className="loginButton">
                <ui.button.BasicButton
                  buttonText={languageQuarter ? "돌아가기" : "Back"}
                  onClick={() => {
                    navigate("/signup");
                  }}
                />
              </div>
            </div>
          </section>
        </Styles.Container>
        <common.Footer />
      </>
  );
}

export default ConfirmPage;
