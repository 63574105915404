import styled from "styled-components";

const Container = styled.div`
  & .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    background-color: var(--black);
    cursor: pointer;

    & .icon {
      width: 20px;
      height: 20px;
      filter: invert(100%) sepia(97%) saturate(13%) hue-rotate(237deg)
        brightness(104%) contrast(104%);
    }

    &.on .icon {
      transform: rotate(180deg);
    }
  }

  & .tableWrap {
    & .subCategoryTitle {
      margin: 20px 0;
      font-size: 20px;
      font-weight: 800;
    }

    & .table {
      border-top: 1px solid var(--gray02);
    }
  }

  & .table {
    border: 1px solid var(--gray02);
    border-top: 0;

    & .tableList {
      display: flex;
      font-weight: 600;

      &:not(:last-child) {
        border-bottom: 1px solid var(--gray02);
      }

      & div {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        min-height: 32px;
      }

      & .th {
        justify-content: center;
        width: 130px;
        padding: 7px 5px;
        font-size: 14px;
        background-color: var(--gray03);
        text-align: center;
        white-space: pre-wrap;
        word-break: keep-all;
      }

      & .td {
        width: calc(100% - 130px);
        padding: 5px 10px;
        font-size: 18px;
        word-break: break-all;
      }
    }
  }

  @media (max-width: 768px) {
    & .title {
      padding: calc(100vw * (16 / 375)) calc(100vw * (20 / 375));
      font-size: calc(100vw * (20 / 375));

      & .icon {
        width: calc(100vw * (20 / 375));
        height: calc(100vw * (20 / 375));
      }
    }

    & .tableWrap {
      & .subCategoryTitle {
        margin: calc(100vw * (20 / 375)) 0;
        font-size: calc(100vw * (20 / 375));
      }
    }

    & .table {
      & .tableList {
        & div {
          min-height: calc(100vw * (32 / 375));
        }

        & .th {
          width: calc(100vw * (130 / 375));
          padding: calc(100vw * (7 / 375)) calc(100vw * (5 / 375));
          font-size: calc(100vw * (14 / 375));
        }

        & .td {
          width: calc(100% - calc(100vw * (130 / 375)));
          padding: calc(100vw * (5 / 375)) calc(100vw * (10 / 375));
          font-size: calc(100vw * (18 / 375));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
