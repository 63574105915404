import styled from "styled-components";

// img

const Container = styled.div`
  padding: 80px 0 200px;
  min-height: 100vh;

  & .inner {
    display: flex;
    gap: 80px;

    & .narrowInner {
      & section {
        &:not(:last-child) {
          margin-bottom: 50px;
        }
      }

      & h2 {
        font-size: 36px;
        font-weight: bold;
      }

      & .detailDescription,
      .categoryDescription {
        padding: 20px 25px;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.55;
        border-top: 3px solid var(--black);
        white-space: pre-wrap;
      }

      & .productSection {
        & .productName {
          padding-bottom: 20px;
          border-bottom: 3px solid var(--black);
        }

        & .description {
          margin-bottom: 31px;
          padding: 20px 0;
          font-size: 24px;
          font-weight: 600;
          border-bottom: 1px solid var(--gray02);
        }

        & .imgContainer {
          display: flex;
          gap: 38px;
          margin-bottom: 30px;
          padding: 0 25px;

          & .thumbnail {
            width: 766px;
            height: 573px;
            background-color: var(--gray03);

            & img {
              object-fit: fill;
            }
          }

          & .imgListWrap {
            display: flex;
            align-content: flex-start;
            flex-wrap: wrap;
            gap: 35px 26px;
            width: 476px;

            & .imgList {
              position: relative;
              width: 225px;
              height: 168px;
              background-color: var(--gray03);
              cursor: pointer;

              &.on::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 3px solid var(--main);
                box-sizing: border-box;
                content: "";
              }

              & img {
                object-fit: fill;
              }
            }
          }
        }

        & .detailDescription {
          margin: 0 25px;
          white-space: pre-wrap;
        }
      }

      & .categorySection {
        padding: 0 25px;

        & .wrap {
          display: flex;
          gap: 50px;
        }

        & h2 {
          padding-bottom: 10px;
        }

        & .categoryContainer {
          flex-grow: 1;
        }

        & .itemContainer,
        .optionContainer {
          flex-shrink: 0;
          width: 225px;

          & li:not(:last-child) {
            margin-bottom: 20px;
          }
        }

        & .itemContainer {
          & .itemList {
            font-weight: 800;
            text-align: center;
            cursor: pointer;
          }

          & .itemImg {
            height: 168px;
            margin-bottom: 10px;
            background-color: var(--gray03);
          }

          & .itemName {
            margin-bottom: 10px;
            font-size: 26px;
          }

          & .itemDescription {
            font-size: 18px;
          }
        }

        & .optionContainer {
          & .optionList {
            font-weight: 600;
            cursor: pointer;
          }

          & .optionTitle {
            margin-bottom: 5px;
            color: var(--main);
          }

          & .moreText {
            font-size: 16px;
            color: var(--gray01);
          }
        }

        & .relateButton {
          width: 530px;
          margin: 83px auto 0;
        }
      }
    }
  }

  @media (max-width: 1740px) {
    padding: calc(100vw * (80 / 1740)) 0 calc(100vw * (200 / 1740));

    & .inner {
      gap: calc(100vw * (80 / 1740));

      & .narrowInner {
        & section {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (50 / 1740));
          }
        }

        & h2 {
          font-size: calc(100vw * (36 / 1740));
        }

        & .detailDescription,
        .categoryDescription {
          padding: calc(100vw * (20 / 1740)) calc(100vw * (25 / 1740));
          font-size: calc(100vw * (18 / 1740));
          border-top: calc(100vw * (3 / 1740)) solid var(--black);
        }

        & .productSection {
          & .productName {
            padding-bottom: calc(100vw * (20 / 1740));
            border-bottom: calc(100vw * (3 / 1740)) solid var(--black);
          }

          & .description {
            margin-bottom: calc(100vw * (31 / 1740));
            padding: calc(100vw * (20 / 1740)) 0;
            font-size: calc(100vw * (24 / 1740));
          }

          & .imgContainer {
            gap: calc(100vw * (38 / 1740));
            margin-bottom: calc(100vw * (30 / 1740));
            padding: 0 calc(100vw * (25 / 1740));

            & .thumbnail {
              width: calc(100vw * (766 / 1740));
              height: calc(100vw * (573 / 1740));
            }

            & .imgListWrap {
              gap: calc(100vw * (35 / 1740)) calc(100vw * (26 / 1740));
              width: calc(100vw * (476 / 1740));

              & .imgList {
                width: calc(100vw * (225 / 1740));
                height: calc(100vw * (168 / 1740));

                &.on::after {
                  border: calc(100vw * (3 / 1740)) solid var(--main);
                }
              }
            }
          }

          & .detailDescription {
            margin: 0 calc(100vw * (25 / 1740));
          }
        }

        & .categorySection {
          padding: 0 calc(100vw * (25 / 1740));

          & .wrap {
            gap: calc(100vw * (50 / 1740));
          }

          & h2 {
            padding-bottom: calc(100vw * (10 / 1740));
          }

          & .itemContainer,
          .optionContainer {
            width: calc(100vw * (225 / 1740));

            & li:not(:last-child) {
              margin-bottom: calc(100vw * (20 / 1740));
            }
          }

          & .itemContainer {
            & .itemImg {
              height: calc(100vw * (168 / 1740));
              margin-bottom: calc(100vw * (10 / 1740));
            }

            & .itemName {
              margin-bottom: calc(100vw * (10 / 1740));
              font-size: calc(100vw * (26 / 1740));
            }

            & .itemDescription {
              font-size: calc(100vw * (18 / 1740));
            }
          }

          & .optionContainer {
            & .optionTitle {
              margin-bottom: calc(100vw * (5 / 1740));
            }

            & .moreText {
              font-size: calc(100vw * (16 / 1740));
            }
          }

          & .relateButton {
            width: calc(100vw * (530 / 1740));
            margin: calc(100vw * (83 / 1740)) auto 0;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (50 / 375)) 0 calc(100vw * (150 / 375));

    & .inner {
      flex-direction: column;
      gap: calc(100vw * (50 / 375));
      width: 100%;

      & .narrowInner {
        & section {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (30 / 375));
          }
        }

        & h2 {
          font-size: calc(100vw * (24 / 375));
        }

        & .detailDescription,
        .categoryDescription {
          padding: calc(100vw * (10 / 375));
          font-size: calc(100vw * (16 / 375));
          border-top: calc(100vw * (3 / 375)) solid var(--black);
        }

        & .productSection {
          & .productName {
            padding-bottom: calc(100vw * (20 / 375));
            border-bottom: calc(100vw * (3 / 375)) solid var(--black);
          }

          & .description {
            margin-bottom: calc(100vw * (20 / 375));
            padding: calc(100vw * (20 / 375)) 0;
            font-size: calc(100vw * (18 / 375));
          }

          & .imgContainer {
            flex-direction: column;
            gap: calc(100vw * (20 / 375));
            margin-bottom: calc(100vw * (30 / 375));
            padding: 0 calc(100vw * (10 / 375));

            & .thumbnail {
              width: 100%;
              height: calc(100vw * (236 / 375));
            }

            & .imgListWrap {
              gap: calc(100vw * (20 / 375)) calc(100vw * (15 / 375));
              width: 100%;

              & .imgList {
                width: calc(100vw * (95 / 375));
                height: calc(100vw * (71 / 375));

                &.on::after {
                  border: calc(100vw * (3 / 375)) solid var(--main);
                }
              }
            }
          }

          & .detailDescription {
            margin: 0 calc(100vw * (10 / 375));
          }
        }

        & .categorySection {
          padding: 0 calc(100vw * (10 / 375));

          & .wrap {
            flex-direction: column;
            gap: calc(100vw * (30 / 375));
          }

          & h2 {
            padding-bottom: calc(100vw * (10 / 375));
          }

          & .itemContainer,
          .optionContainer {
            width: 100%;

            & li:not(:last-child) {
              margin-bottom: calc(100vw * (30 / 375));
            }
          }

          & .itemContainer {
            & .itemImg {
              height: calc(100vw * (235 / 375));
              margin-bottom: calc(100vw * (10 / 375));
            }

            & .itemName {
              margin-bottom: calc(100vw * (10 / 375));
              font-size: calc(100vw * (18 / 375));
            }

            & .itemDescription {
              font-size: calc(100vw * (14 / 375));
            }
          }

          & .optionContainer {
            & li:not(:last-child) {
              margin-bottom: calc(100vw * (20 / 375));
            }

            & .optionTitle {
              margin-bottom: calc(100vw * (5 / 375));
            }

            & .moreText {
              font-size: calc(100vw * (14 / 375));
            }
          }

          & .relateButton {
            width: calc(100vw * (250 / 375));
            margin: calc(100vw * (50 / 375)) auto 0;
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
