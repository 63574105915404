import styled from "styled-components";

// img
import BgProductSection from "resources/image/main/bg_product_section.png";
import BgNewsSection from "resources/image/main/bg_news_section.png";
import BgCustomerCenter from "resources/image/main/bg_customer_center.png";
import IconPrev from "resources/image/icon/icon_prev.svg";
import IconNext from "resources/image/icon/icon_next.svg";

const Container = styled.div`
  & .fp-overflow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-height: 100dvh;
  }

  & .fp-watermark {
    display: none;
  }

  & .bannerSection {
    & .fp-overflow {
      display: block;
    }
    & .logoWrap {
      position: absolute;
      top: 120px;
      left: calc(50% - 820px);
      width: 158px;
      height: 46px;
      z-index: 1;
    }

    & .bottomIconWrap {
      position: absolute;
      bottom: 50px;
      right: calc(50% - 820px);
      width: 83px;
      height: 83px;
      z-index: 1;
    }

    & .bannerSlider {
      height: 100%;
      z-index: 0;

      & .swiper-pagination {
        bottom: 80px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: fit-content;
        z-index: 1;
      }

      & .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        background-color: transparent;
        border: 1px solid #fff;
        opacity: 0.4;

        &.swiper-pagination-bullet-active {
          position: relative;
          width: 22px;
          height: 22px;
          background-color: transparent;
          opacity: 1;

          &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 14px;
            height: 14px;
            background-color: #fff;
            border-radius: 50%;
            content: "";
          }
        }
      }

      &.swiper-horizontal
        > .swiper-pagination-bullets
        .swiper-pagination-bullet,
      .swiper-pagination-horizontal.swiper-pagination-bullets
        .swiper-pagination-bullet {
        margin: 0;
      }
    }
  }

  & .productSection {
    background: url(${BgProductSection}) no-repeat top right / 960px 736px;

    & h2 {
      margin-bottom: calc(100vh * (55 / 1080));
      font-size: calc(100vh * (52 / 1080));
      font-weight: bold;
      color: var(--main);
      letter-spacing: -0.03em;
    }

    & .productSlider {
      width: 100%;
      padding: calc(100vh * (50 / 1080)) 0 calc(100vh * (110 / 1080));

      & .swiper-wrapper {
        align-items: center;
      }

      & .swiper-slide {
        position: relative;
        width: fit-content;
        height: fit-content;
        cursor: pointer;

        &.swiper-slide-active {
          &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100vh * (550 / 1080));
            height: calc(100vh * (550 / 1080));
            border: 3px solid var(--gray04);
            border-radius: calc(100vh * (20 / 1080));
            content: "";
          }

          & .img {
            padding: 0;
            opacity: 1;
          }

          & .info {
            top: calc(100% + calc(100vh * (45 / 1080)));
            bottom: auto;
            color: var(--black);
          }
        }

        & .img {
          width: calc(100vh * (500 / 1080));
          height: calc(100vh * (500 / 1080));
          padding: calc(100vh * (75 / 1080));
          opacity: 0.2;
        }

        & .info {
          position: absolute;
          bottom: calc(100vh * (-9 / 1080));
          left: 50%;
          transform: translateX(-50%);
          max-width: 100%;
          font-weight: 800;
          color: var(--gray02);
          text-align: center;

          & > p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          & .title {
            margin-bottom: calc(100vh * (10 / 1080));
            font-size: calc(100vh * (26 / 1080));
          }

          & .description {
            font-size: calc(100vh * (18 / 1080));
          }
        }
      }

      & .swiper-button-prev,
      .swiper-button-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 60px;
        height: 93px;
        z-index: 1;
        cursor: pointer;
      }

      & .swiper-button-prev {
        left: 0;
        background: url(${IconPrev}) no-repeat center / cover;
      }

      & .swiper-button-next {
        right: 0;
        background: url(${IconNext}) no-repeat center / cover;
      }
    }
  }

  & .newsSection {
    background: url(${BgNewsSection}) no-repeat bottom right / 960px 736px;

    & .inner {
      display: flex;
      gap: 30px;

      & > div {
        flex-shrink: 0;
      }

      & .title {
        margin-bottom: 30px;
        font-size: 36px;
        font-weight: bold;
        color: var(--main);
        letter-spacing: -0.03em;
      }

      & .left {
        width: calc(100% - 530px);

        & ul {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;

          & li {
            width: 360px;
            height: 230px;

            & iframe {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      & .right {
        width: 500px;

        & .customerCenterContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 475px;
          padding: 0 50px;
          color: #fff;
          background: url(${BgCustomerCenter}) no-repeat center bottom / 100%
            150px var(--main);

          & .contact {
            margin-bottom: 28px;

            & li {
              &:not(:last-child) {
                margin-bottom: 25px;
              }

              & .listTitle {
                margin-bottom: 5px;
                font-size: 14px;

                & span {
                  margin-left: 15px;
                }
              }

              & .description {
                font-size: 28px;
                font-weight: 600;
              }
            }
          }

          & .philosophyTitle {
            margin-bottom: 10px;
            font-size: 18px;
          }

          & .philosophyListWrap {
            display: flex;
            gap: 20px;

            & .philosophyList {
              flex: 1 1 0;

              & .icon {
                width: 60px;
                height: 60px;
                margin-bottom: 10px;
              }

              & .description {
                font-size: 14px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1740px) {
    & .bannerSection {
      & .logoWrap {
        top: calc(100vw * (120 / 1740));
        left: calc(50% - calc(100vw * (820 / 1740)));
        width: calc(100vw * (158 / 1740));
        height: calc(100vw * (46 / 1740));
      }

      & .bottomIconWrap {
        bottom: calc(100vw * (50 / 1740));
        right: calc(50% - calc(100vw * (820 / 1740)));
        width: calc(100vw * (83 / 1740));
        height: calc(100vw * (83 / 1740));
      }

      & .bannerSlider {
        & .swiper-pagination {
          bottom: calc(100vw * (80 / 1740));
          gap: calc(100vw * (20 / 1740));
        }

        & .swiper-pagination-bullet {
          width: calc(100vw * (10 / 1740));
          height: calc(100vw * (10 / 1740));

          &.swiper-pagination-bullet-active {
            width: calc(100vw * (22 / 1740));
            height: calc(100vw * (22 / 1740));

            &::before {
              width: calc(100vw * (14 / 1740));
              height: calc(100vw * (14 / 1740));
            }
          }
        }
      }
    }

    & .productSection {
      background: url(${BgProductSection}) no-repeat top right /
        calc(100vw * (960 / 1740)) calc(100vw * (736 / 1740));

      & h2 {
        margin-bottom: calc(100vw * (55 / 1740));
        font-size: calc(100vw * (52 / 1740));
      }

      & .productSlider {
        padding: calc(100vw * (50 / 1740)) 0 calc(100vw * (110 / 1740));

        & .swiper-slide {
          &.swiper-slide-active {
            &::after {
              width: calc(100vw * (550 / 1740));
              height: calc(100vw * (550 / 1740));
              border: calc(100vw * (3 / 1740)) solid var(--gray04);
              border-radius: calc(100vw * (20 / 1740));
            }

            & .info {
              top: calc(100% + calc(100vw * (45 / 1740)));
            }
          }

          & .img {
            width: calc(100vw * (500 / 1740));
            height: calc(100vw * (500 / 1740));
            padding: calc(100vw * (75 / 1740));
          }

          & .info {
            bottom: calc(100vw * (-9 / 1740));

            & .title {
              margin-bottom: calc(100vw * (10 / 1740));
              font-size: calc(100vw * (26 / 1740));
            }

            & .description {
              font-size: calc(100vw * (18 / 1740));
            }
          }
        }

        & .swiper-button-prev,
        .swiper-button-next {
          width: calc(100vw * (60 / 1740));
          height: calc(100vw * (93 / 1740));
        }
      }
    }

    & .newsSection {
      background: url(${BgNewsSection}) no-repeat bottom right /
        calc(100vw * (960 / 1740)) calc(100vw * (736 / 1740));

      & .inner {
        gap: calc(100vw * (30 / 1740));

        & .title {
          margin-bottom: calc(100vw * (30 / 1740));
          font-size: calc(100vw * (36 / 1740));
        }

        & .left {
          width: calc(100% - calc(100vw * (530 / 1740)));

          & ul {
            gap: calc(100vw * (15 / 1740));

            & li {
              width: calc(100vw * (360 / 1740));
              height: calc(100vw * (230 / 1740));
            }
          }
        }

        & .right {
          width: calc(100vw * (500 / 1740));

          & .customerCenterContainer {
            height: calc(100vw * (475 / 1740));
            padding: 0 calc(100vw * (50 / 1740));
            background: url(${BgCustomerCenter}) no-repeat center bottom / 100%
              calc(100vw * (150 / 1740)) var(--main);

            & .contact {
              margin-bottom: calc(100vw * (28 / 1740));

              & li {
                &:not(:last-child) {
                  margin-bottom: calc(100vw * (25 / 1740));
                }

                & .listTitle {
                  margin-bottom: calc(100vw * (5 / 1740));
                  font-size: calc(100vw * (14 / 1740));

                  & span {
                    margin-left: calc(100vw * (15 / 1740));
                  }
                }

                & .description {
                  font-size: calc(100vw * (28 / 1740));
                }
              }
            }

            & .philosophyTitle {
              margin-bottom: calc(100vw * (10 / 1740));
              font-size: calc(100vw * (18 / 1740));
            }

            & .philosophyListWrap {
              gap: calc(100vw * (20 / 1740));

              & .philosophyList {
                & .icon {
                  width: calc(100vw * (60 / 1740));
                  height: calc(100vw * (60 / 1740));
                  margin-bottom: calc(100vw * (10 / 1740));
                }

                & .description {
                  font-size: calc(100vw * (14 / 1740));
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .bannerSection {
      & .logoWrap {
        top: calc(100vw * (80 / 375));
        left: calc(100vw * (20 / 375));
        width: calc(100vw * (103 / 375));
        height: calc(100vw * (30 / 375));
      }

      & .bottomIconWrap {
        bottom: calc(100vw * (20 / 375));
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        width: calc(100vw * (40 / 375));
        height: calc(100vw * (40 / 375));
      }

      & .bannerSlider {
        & .swiper-pagination {
          bottom: calc(100vw * (80 / 375));
          gap: calc(100vw * (20 / 375));
        }

        & .swiper-pagination-bullet {
          width: calc(100vw * (10 / 375));
          height: calc(100vw * (10 / 375));

          &.swiper-pagination-bullet-active {
            width: calc(100vw * (22 / 375));
            height: calc(100vw * (22 / 375));

            &::before {
              width: calc(100vw * (14 / 375));
              height: calc(100vw * (14 / 375));
            }
          }
        }
      }
    }

    & .productSection {
      background: url(${BgProductSection}) no-repeat top right /
        calc(100vw * (320 / 375)) calc(100vw * (245 / 375));

      & h2 {
        margin-bottom: calc(100vw * (30 / 375));
        font-size: calc(100vw * (30 / 375));
        text-align: center;
      }

      & .productSlider {
        padding: calc(100vw * (14 / 375)) 0 calc(100vw * (90 / 375));

        & .swiper-slide {
          &.swiper-slide-active {
            &::after {
              width: calc(100vw * (210 / 375));
              height: calc(100vw * (210 / 375));
              border: calc(100vw * (3 / 375)) solid var(--gray04);
              border-radius: calc(100vw * (20 / 375));
            }

            & .info {
              top: calc(100% + calc(100vw * (40 / 375)));
            }
          }

          & .img {
            width: calc(100vw * (191 / 375));
            height: calc(100vw * (191 / 375));
            padding: calc(100vw * (10 / 375));
          }

          & .info {
            top: calc(100% + calc(100vw * (32 / 375)));
            bottom: auto;

            & .title {
              margin-bottom: calc(100vw * (5 / 375));
              font-size: calc(100vw * (20 / 375));
            }

            & .description {
              font-size: calc(100vw * (14 / 375));
            }
          }
        }

        & .swiper-button-prev,
        .swiper-button-next {
          top: 38%;
          width: calc(100vw * (40 / 375));
          height: calc(100vw * (62 / 375));
        }
      }
    }

    & .newsSection {
      background: url(${BgNewsSection}) no-repeat bottom right /
        calc(100vh * (320 / 812)) calc(100vh * (245 / 812));
      background: url(${BgNewsSection}) no-repeat bottom right /
        calc(100dvh * (320 / 812)) calc(100dvh * (245 / 812));

      & .inner {
        flex-direction: column;
        gap: calc(100vh * (60 / 812));
        gap: calc(100dvh * (60 / 812));

        & .title {
          margin-bottom: calc(100vh * (20 / 812));
          margin-bottom: calc(100dvh * (20 / 812));
          font-size: calc(100vh * (24 / 812));
          font-size: calc(100dvh * (24 / 812));
        }

        & .left {
          width: 100%;

          & ul {
            flex-wrap: nowrap;
            gap: calc(100vh * (20 / 812));
            gap: calc(100dvh * (20 / 812));
            padding: calc(100vh * (7 / 812)) 0;
            padding: calc(100dvh * (7 / 812)) 0;

            & li {
              flex-shrink: 0;
              width: calc(100vh * (260 / 812));
              width: calc(100dvh * (260 / 812));
              height: calc(100vh * (160 / 812));
              height: calc(100dvh * (160 / 812));
            }
          }
        }

        & .right {
          width: 100%;

          & .customerCenterContainer {
            height: auto;
            padding: calc(100vh * (25 / 812)) calc(100vh * (12 / 812));
            padding: calc(100dvh * (25 / 812)) calc(100dvh * (12 / 812));
            background: url(${BgCustomerCenter}) no-repeat center bottom / 100%
              calc(100vh * (100 / 812)) var(--main);
            background: url(${BgCustomerCenter}) no-repeat center bottom / 100%
              calc(100dvh * (100 / 812)) var(--main);

            & .contact {
              display: flex;
              flex-wrap: wrap;
              gap: calc(100vh * (30 / 812)) calc(100vh * (11 / 812));
              gap: calc(100dvh * (30 / 812)) calc(100dvh * (11 / 812));
              margin-bottom: calc(100vh * (30 / 812));
              margin-bottom: calc(100dvh * (30 / 812));

              & li {
                &:not(:last-child) {
                  margin-bottom: 0;
                }

                & .listTitle {
                  margin-bottom: calc(100vh * (5 / 812));
                  margin-bottom: calc(100dvh * (5 / 812));
                  font-size: calc(100vh * (14 / 812));
                  font-size: calc(100dvh * (14 / 812));

                  & span {
                    margin-left: calc(100vh * (15 / 812));
                    margin-left: calc(100dvh * (15 / 812));
                  }
                }

                & .description {
                  font-size: calc(100vh * (18 / 812));
                  font-size: calc(100dvh * (18 / 812));
                }
              }
            }

            & .philosophyTitle {
              margin-bottom: calc(100vh * (10 / 812));
              margin-bottom: calc(100dvh * (10 / 812));
              font-size: calc(100vh * (14 / 812));
              font-size: calc(100dvh * (14 / 812));
            }

            & .philosophyListWrap {
              gap: calc(100vh * (10 / 812));
              gap: calc(100dvh * (10 / 812));

              & .philosophyList {
                & .icon {
                  width: calc(100vh * (30 / 812));
                  width: calc(100dvh * (30 / 812));
                  height: calc(100vh * (30 / 812));
                  height: calc(100dvh * (30 / 812));
                  margin-bottom: calc(100vh * (10 / 812));
                  margin-bottom: calc(100dvh * (10 / 812));
                }

                & .description {
                  font-size: calc(100vh * (14 / 812));
                  font-size: calc(100dvh * (14 / 812));
                }
              }
            }
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
