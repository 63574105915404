import { api } from "service";
import { useQuery } from "react-query";

export default function useSearch({
  listOption
}) {

  const listQuery = useQuery(["searchList", listOption], () => {
    return api.search.getList(listOption);
  }, { 
    enabled: !!listOption?.keyword,
    refetchOnWindowFocus: true
  });


  return {
    listQuery,
  }
}