import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;

  & .modalInner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 590px;
    padding: 30px 30px 50px;
    background-color: #fff;

    & .closeButton {
      position: absolute;
      top: 30px;
      right: 30px;
      width: 24px;
      height: 24px;
    }

    & .title {
      padding-bottom: 30px;
      font-size: 36px;
      font-weight: bold;
      border-bottom: 3px solid var(--black);
    }

    & .confirmButton {
      margin-top: auto;
    }
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }

  @media (max-width: 1740px) {
    & .modalInner {
      width: calc(100vw * (590 / 1740));
      padding: calc(100vw * (30 / 1740)) calc(100vw * (30 / 1740))
        calc(100vw * (50 / 1740));

      & .closeButton {
        top: calc(100vw * (30 / 1740));
        right: calc(100vw * (30 / 1740));
        width: calc(100vw * (24 / 1740));
        height: calc(100vw * (24 / 1740));
      }

      & .title {
        padding-bottom: calc(100vw * (30 / 1740));
        font-size: calc(100vw * (36 / 1740));
        border-bottom: calc(100vw * (3 / 1740)) solid var(--black);
      }
    }
  }

  @media (max-width: 768px) {
    & .modalInner {
      width: calc(100vw * (334 / 375));
      padding: calc(100vw * (30 / 375)) calc(100vw * (10 / 375));

      & .closeButton {
        top: calc(100vw * (34 / 375));
        right: calc(100vw * (10 / 375));
        width: calc(100vw * (24 / 375));
        height: calc(100vw * (24 / 375));
      }

      & .title {
        padding-bottom: calc(100vw * (20 / 375));
        font-size: calc(100vw * (24 / 375));
        border-bottom: calc(100vw * (3 / 375)) solid var(--black);
      }
    }
  }
`;

export { Container };

export default { Container };
