import Styles from "./styles";
import { layout, ui } from "components";

function ServiceSearch({ data, language, disableScrollTop, type}) {
  const languageQuarter = language.suffix === "";

  return (
    <Styles.Container>
      <h3 className="searchTitle">{languageQuarter ? "검색 결과" : "Search Results"}</h3>

      {data?.list?.length > 0 ? (
        <div className="searchResult">
          <ul className="thead">
            <li className="number">{languageQuarter ? "번호" : "No."} </li>
            <li className="resultTitle">{languageQuarter ? "제목" : "Title"}</li>
            <li className="resultTitle" style={{position:"absolute", marginLeft:"auto", right:"7.5%"}}>{languageQuarter ? "다운로드" : "Download"}</li>
          </ul>

          <ul className="tbody">
            {data.list.map((item, index) => {
              return (
                <a>
                  <li className="resultList" key={"resultList" + index}>
                    <div className="number">{data.pagination.totalRecordCountAll - index - ((data.pagination.page - 1) * 5)}</div>
                    {type === 1 && item["url" + language.suffix]
                      ? <a href={item["url" + language.suffix]} target="_blank" className="linkTitle">{item["title" + language.suffix] || item.title}</a>
                      : <div className="resultTitle">{item["title" + language.suffix] || item.title}</div>
                    }
                    <div className="download">
                      <layout.DownloadButtonList data={item.file_list || [item]} type={type} />
                    </div>
                  </li>
                </a>
              );
            })}
          </ul>

          {data.pagination?.totalRecordCountAll > 5 && (
            <div className="pagination">
              <ui.button.Pagination
                page={data.pagination.page}
                list={data.pagination.page_list}
                maxPage={data.pagination.totalPageCount}
                disableScrollTop={disableScrollTop}
              />
            </div>
          )}
        </div>
      ) : (
        <layout.EmptyContainer search text={languageQuarter ? "검색 결과가 없습니다." : "No search results found."} />
      )}
    </Styles.Container>
  );
}

export default ServiceSearch;
