import Styles from "./styles";
import { layout } from "components";

function DownloadList({ data }) {
  return (
    <Styles.Container>
      <div className="listTitle">{data.title}</div>

      <layout.DownloadButtonList data={data.data_list} />
    </Styles.Container>
  );
}

export default DownloadList;
