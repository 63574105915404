import connector from "service/connector";
import utils from "utils";


const getProductDetail = async (path) => {
  return await connector.connectFetchController("product/detail?info_id=" + path, "GET");
}
const getProductList = async () => {
  return await connector.connectFetchController("product/list", "GET");
}
const getProductSupportList = async (filter) => {
  return await connector.connectFetchController("product/support/list" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}


export {
  getProductDetail,
  getProductList,
  getProductSupportList,
};


export default {
  getProductDetail,
  getProductList,
  getProductSupportList,
}