import styled from "styled-components";

const Container = styled.div`
  & .table {
    margin-bottom: 50px;
    font-weight: 600;

    & .thead {
      display: flex;
    }

    & .thead {
      padding: 20px 0;
      font-size: 16px;
      text-align: center;
      border-bottom: 1px solid var(--gray02);
    }

    & .number,
    .date {
      width: 150px;
      text-align: center;
    }

    & .listTitle {
      width: calc(100% - 300px);
      padding: 0 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & .tr a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 80px;
      border-bottom: 1px solid var(--gray02);

      & .number,
      .date {
        font-size: 18px;
      }

      & .date {
        color: var(--gray01);
      }
    }
  }

  @media (max-width: 1740px) {
    & .table {
      margin-bottom: calc(100vw * (50 / 1740));

      & .thead {
        padding: calc(100vw * (20 / 1740)) 0;
        font-size: calc(100vw * (16 / 1740));
      }

      & .number,
      .date {
        width: calc(100vw * (150 / 1740));
      }

      & .listTitle {
        width: calc(100% - calc(100vw * (300 / 1740)));
        padding: 0 calc(100vw * (20 / 1740));
      }

      & .tr a {
        height: calc(100vw * (80 / 1740));

        & .number,
        .date {
          font-size: calc(100vw * (18 / 1740));
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .table {
      margin-bottom: calc(100vw * (50 / 375));

      & .thead {
        display: none;
      }

      & .number,
      .date {
        width: 100%;
        text-align: left;
      }

      & .listTitle {
        width: 100%;
        padding: 0;
      }

      & .tr a {
        flex-direction: column;
        gap: calc(100vw * (8 / 375));
        height: auto;
        padding: calc(100vw * (15 / 375)) 0;

        & .number,
        .date {
          font-size: calc(100vw * (14 / 375));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
