import Styles from "./styles";
import { api } from "service";
import { ui } from "components";
import { useState } from "react";
import { useLanguage } from "hooks";

function Public() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  
  // const data = api.dummy.publicList;
  // const [page, setPage] = useState(1);

  return (
    <Styles.Container>
      <h2 className="title">{languageQuarter ? "공시 정보" : "Disclosure information"}</h2>

      {/* <div className="table">
        <ul className="thead">
          <li className="number">번호</li>

          <li className="listTitle">공시 제목</li>

          <li className="date">일시</li>
        </ul>

        <ul className="tbody">
          {data?.slice(0, 15).map((item, index) => {
            return (
              <li className="tr" key={"tr" + index}>
                <a href="">
                  <div className="number">{item.number}</div>

                  <div className="listTitle">{item.title}</div>

                  <div className="date">{item.date}</div>
                </a>
              </li>
            );
          })}
        </ul>
      </div> */}

      {/* {data.length > 15 && ( */}
      {/* <div className="pagination">
        <ui.button.Pagination
          page={page}
          setPage={setPage}
          list={[1, 2, 3, 4, 5]}
        />
      </div> */}
      {/* )} */}

      <iframe
        className="disclosureInfo"
        src={"https://totalstock.irplus.co.kr/StockService/page/019990_enertork_3352/disclosure.php?l=" + (languageQuarter ? "K" : "E")}
        width="100%"
        height="800px"
      ></iframe>

    </Styles.Container>
  );
}

export default Public;
