import connector from "service/connector"
import utils from "utils";


const ERP_getList = async (filter) => {
  return await connector.authConnector("morgan/list" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const ERP_getDetail = async (filter) => {
  return await connector.authConnector("morgan/detail" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const EPS_getList = async (filter) => {
  return await connector.authConnector("activeEner/list" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const EPS_getDetail = async (filter) => {
  return await connector.authConnector("activeEner/detail" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}


export {
  ERP_getList,
  ERP_getDetail,
  EPS_getList,
  EPS_getDetail,
};

export default {
  ERP_getList,
  ERP_getDetail,
  EPS_getList,
  EPS_getDetail,
}