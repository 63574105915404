import Styles from "./styles";
import { useNavigate, Link } from "react-router-dom";

// img
import IconHome from "resources/image/icon/icon_home.svg";

function MorganHeader({ backEvent }) {
  const navigate = useNavigate();

  return (
    <Styles.Container>
      <button
        type="button"
        className="prevButton"
        onClick={() => {
          backEvent
            ? backEvent()
            : navigate(-1);
        }}
      >
        이전으로
      </button>

      <Link to={"/"} className="homeButton">
        <div className="icon">
          <img src={IconHome} alt="IconHome" />
        </div>
        HOME
      </Link>
    </Styles.Container>
  );
}

export default MorganHeader;
