import Styles from "./styles";
import ui from "components/ui";
import { useAuth } from "hooks";
import { useState } from "react";

function FindPwForm({ result, setResult, language }) {
  const authInfo = useAuth();

  const [errorText, setErrorText] = useState({});
  const [inputs, setInputs] = useState({
    username: "",
    company: "",
    name: "",
  });

  const inputList = [
    {
      name: "username",
      value: inputs.username,
      labelText: language ? "아이디(이메일)" : "ID (E-mail)",
      placeholder: language
        ? "이메일을 입력해주세요."
        : "Please enter your email.",
      errorText: errorText.username,
    },
    {
      name: "company",
      value: inputs.company,
      labelText: language ? "회사" : "Company",
      placeholder: language
        ? "회사명을 입력해주세요."
        : "Please enter the company name.",
      errorText: errorText.company,
    },
    {
      name: "name",
      value: inputs.name,
      labelText: language ? "이름" : "Name",
      placeholder: language
        ? "이름을 입력해주세요."
        : "Please enter your name.",
      errorText: errorText.name,
    },
  ];

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs((e) => {
      return { ...e, [name]: value };
    });
  };

  const validate = () => {
    const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    let isError = false;
    if (inputs.username === "") {
      setErrorText(e => {
        return { ...e,
          username: language
            ? "아이디를 입력해주세요."
            : "Please enter your ID."
        }
      })
      isError = true;
    } else if (emailRegEx.test(inputs.username) === false) {
      setErrorText(e => {
        return { ...e,
          username: language
            ? "이메일 형식이 올바르지 않습니다."
            : "The email format is not valid."
        }
      })
      isError = true;
    } else setErrorText(e => { return { ...e, username: "" } })

    if (inputs.company === "") {
      setErrorText(e => {
        return { ...e,
          company: language
            ? "회사명을 입력해주세요."
            : "Please enter the company name."
        }
      })
      isError = true;
    } else setErrorText(e => { return { ...e, company: "" } })

    if (inputs.name === "") {
      setErrorText(e => {
        return { ...e,
          name: language
            ? "이름을 입력해주세요."
            : "Please enter your name."
        }
      })
      isError = true;
    } else setErrorText(e => { return { ...e, name: "" } })
  
    if (isError) return false;
    return true;
  }
  
  const onNext = async () => {
    if (validate()) {
      const dataResult = await authInfo.passwordReset(inputs);
      if (dataResult.success) setResult(!result)
      else { setErrorText(e => {
        return { ...e, find: language
          ? "일치하는 정보가 없습니다."
          : "No matching information."
        }
      })}
    }
  }

  return (
    <Styles.Container>
      <form>
        <ul className="inputWrap">
          {inputList.map((item, index) => {
            return (
              <li className="inputList" key={"inputList" + index}>
                <ui.input.BasicInput data={item} onChange={onChange} />
              </li>
            );
          })}
        </ul>

        <div className="findButton">
          <p className="errorText">{errorText.find}</p>

          <ui.button.BasicButton
            buttonText={language ? "비밀번호 찾기" : "Find a password"}
            onClick={onNext}
          />
        </div>
      </form>
    </Styles.Container>
  );
}

export { FindPwForm };

export default FindPwForm;
