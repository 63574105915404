import styled from "styled-components";

const Container = styled.div`
  & .listWrap {
    margin-bottom: 50px;

    & .list a {
      padding: 35px 20px;
      border-bottom: 1px solid var(--gray02);

      &:hover .description {
        text-decoration: underline;
        text-decoration-color: var(--main);
        text-underline-position: from-font;
        text-decoration-thickness: 1px;
      }

      & .depth {
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: 600;
        color: var(--gray01);
      }

      & .description {
        display: -webkit-box;
        font-weight: 600;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        & .highlighting {
          color: var(--main);
        }
      }
    }
  }

  @media (max-width: 1740px) {
    & .listWrap {
      margin-bottom: calc(100vw * (50 / 1740));

      & .list a {
        padding: calc(100vw * (35 / 1740)) calc(100vw * (20 / 1740));

        & .depth {
          margin-bottom: calc(100vw * (15 / 1740));
          font-size: calc(100vw * (16 / 1740));
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .listWrap {
      margin-bottom: calc(100vw * (50 / 375));

      & .list a {
        padding: calc(100vw * (18 / 375)) 0;

        & .depth {
          margin-bottom: calc(100vw * (8 / 375));
          font-size: calc(100vw * (14 / 375));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
