import styled from "styled-components";

const Container = styled.div`
  font-weight: 600;

  & .thead,
  .resultList {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--gray02);
  }

  & .number {
    flex-shrink: 0;
    width: 150px;
    text-align: center;
  }

  & .thead {
    height: 60px;
    font-size: 16px;

    & .resultTitle {
      flex-grow: 1;
      text-align: center;
    }
  }

  & .resultList {
    height: 80px;

    & .number {
      font-size: 18px;
    }

    & .resultTitle, .linkTitle{
      width: 880px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: left;
    }

    & .linkTitle {
      text-decoration: underline;
      color: blue;
    }

    & .linkTitle:hover, .linkTitle:visited {
      color: purple;
    }
  }

  & .pagination {
    margin-top: 50px;
  }

  @media (max-width: 1740px) {
    & .number {
      width: calc(100vw * (150 / 1740));
    }

    & .thead {
      height: calc(100vw * (60 / 1740));
      font-size: calc(100vw * (16 / 1740));
    }

    & .resultList {
      height: calc(100vw * (80 / 1740));

      & .number {
        font-size: calc(100vw * (18 / 1740));
      }

      & .resultTitle {
        width: calc(100vw * (880 / 1740));
      }
    }

    & .pagination {
      margin-top: calc(100vw * (50 / 1740));
    }
  }

  @media (max-width: 768px) {
    & .number {
      width: 100%;
    }

    & .thead {
      display: none;
    }

    & .resultList {
      flex-direction: column;
      align-items: flex-start;
      gap: calc(100vw * (8 / 375));
      padding: calc(100vw * (15 / 375)) 0;
      height: auto;

      & .number {
        font-size: calc(100vw * (14 / 375));
        text-align: start;
      }

      & .resultTitle {
        width: 100%;
      }
    }

    & .pagination {
      margin-top: calc(100vw * (50 / 375));
    }
  }
`;

export { Container };

export default { Container };
