import styled from "styled-components";

// img
import BgMenu from "resources/image/bg_mb_menu.png";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--main);
  z-index: 5;

  &.main {
    background-color: rgba(234, 85, 4, 0.9);
  }

  & .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;

    & .gnbWrap,
    .gnb {
      display: flex;
      align-items: center;
      gap: 38px;
    }

    & .gnbWrap {
      & .menuButton {
        display: none;
      }

      & .searchButton {
        width: 20px;
        height: 20px;
      }
    }

    & .gnb {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
    }

    & .homeButton {
      display: flex;
      align-items: center;
      gap: 5px;
      margin: 0 25px 0 auto;
      font-size: 14px;
      font-weight: bold;
      color: #fff;

      & .icon {
        width: 20px;
        height: 20px;
      }
    }

    & .utilListWrap {
      display: flex;
      align-items: center;
      gap: 25px;

      & .utilList {
        position: relative;

        & .icon {
          width: 20px;
          height: 20px;
        }

        & button {
          display: block;
        }

        &.global {
          & button {
            font-size: 14px;
            font-weight: bold;
          }

          & .globalButton {
            display: flex;
            align-items: center;
            gap: 4px;
            color: #fff;

            & .icon {
              display: inline-block;
            }
          }

          & .languageListWrap {
            position: absolute;
            top: calc(100% + 35px);
            right: 0;
            background-color: #fff;
            border-radius: 10px;

            & button {
              padding: 11px 18px;
              color: var(--gray01);

              &.on {
                color: var(--main);
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1740px) {
    & .inner {
      height: calc(100vw * (80 / 1740));

      & .gnbWrap,
      .gnb {
        gap: calc(100vw * (38 / 1740));
      }

      & .gnbWrap {
        & .searchButton {
          width: calc(100vw * (20 / 1740));
          height: calc(100vw * (20 / 1740));
        }
      }

      & .gnb {
        font-size: calc(100vw * (16 / 1740));
      }

      & .homeButton {
        gap: calc(100vw * (5 / 1740));
        margin: 0 calc(100vw * (25 / 1740)) 0 auto;
        font-size: calc(100vw * (14 / 1740));

        & .icon {
          width: calc(100vw * (20 / 1740));
          height: calc(100vw * (20 / 1740));
        }
      }

      & .utilListWrap {
        gap: calc(100vw * (25 / 1740));

        & .utilList {
          & .icon {
            width: calc(100vw * (20 / 1740));
            height: calc(100vw * (20 / 1740));
          }

          &.global {
            & button {
              font-size: calc(100vw * (14 / 1740));
            }

            & .globalButton {
              gap: calc(100vw * (4 / 1740));
            }

            & .languageListWrap {
              top: calc(100% + calc(100vw * (35 / 1740)));
              border-radius: calc(100vw * (10 / 1740));

              & button {
                padding: calc(100vw * (11 / 1740)) calc(100vw * (18 / 1740));
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .inner {
      position: relative;
      height: calc(100vw * (50 / 375));

      & .gnbWrap,
      .gnb {
        gap: calc(100vw * (20 / 375));
      }

      & .gnbWrap {
        & .searchButton,
        .menuButton {
          width: calc(100vw * (20 / 375));
          height: calc(100vw * (20 / 375));
        }

        & .menuButton {
          display: block;
        }
      }

      & .gnb {
        display: none;
        font-size: calc(100vw * (16 / 1740));
      }

      & .homeButton {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        gap: calc(100vw * (5 / 375));
        margin: 0;
        font-size: calc(100vw * (14 / 375));

        & .icon {
          width: calc(100vw * (20 / 375));
          height: calc(100vw * (20 / 375));
        }
      }

      & .utilListWrap {
        display: none;
        gap: calc(100vw * (25 / 375));

        & .utilList {
          & a {
            gap: calc(100vw * (5 / 375));
            font-size: calc(100vw * (14 / 375));
          }

          & .icon {
            width: calc(100vw * (20 / 375));
            height: calc(100vw * (20 / 375));
          }

          &.global {
            & button {
              font-size: calc(100vw * (14 / 375));
            }

            & .globalButton {
              gap: calc(100vw * (5 / 375));
            }

            & .languageListWrap {
              top: calc(100% + calc(100vw * (7 / 375)));
              border-radius: calc(100vw * (10 / 375));

              & button {
                padding: calc(100vw * (11 / 375)) calc(100vw * (18 / 375));
              }
            }
          }
        }
      }
    }
  }

  &.on {
    & .inner {
      z-index: 1;

      & .gnbWrap .searchButton {
        display: none;
      }

      & .gnb {
        position: absolute;
        top: calc(100vw * (134 / 375));
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: calc(100vw * (40 / 375));
        font-size: calc(100vw * (24 / 375));
      }

      & .homeButton {
        display: none;
      }

      & .utilListWrap {
        display: flex;
      }
    }

    & .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: linear-gradient(155deg, #ea5504 11.59%, #ea8e04 97.72%);

      &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: calc(100vw * (112 / 375));
        background: url(${BgMenu}) no-repeat center / cover;
        content: "";
      }
    }
  }
`;

export { Container };

export default { Container };
