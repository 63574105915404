import styled from "styled-components";

const Container = styled.div`
  padding: 80px 0 200px;
  min-height: 100vh;

  & .inner {
    display: flex;
    gap: 80px;

    & .narrowInner {
      & section {
        &:not(:last-child) {
          margin-bottom: 100px;
        }
      }

      & .sectionTitle,
      .searchTitle {
        font-size: 36px;
        font-weight: bold;
      }

      & .sectionTitle {
        margin-bottom: 20px;
      }

      & .searchTitle {
        padding-bottom: 20px;
        border-bottom: 3px solid var(--black);
      }

      & .subSectionTitle {
        margin-bottom: 20px;
        font-size: 26px;
        font-weight: bold;
      }

      & .inputContainer,
      .serialFilter,
      .normalFilter {
        display: flex;
        margin-bottom: 50px;

        & .inputWrap {
          display: flex;
          gap: 5px;

          & input {
            width: 85px;
            height: 70px;
            text-align: center;
          }
        }

        & .buttonWrap {
          display: flex;

          & button {
            width: 133px;
          }

          & .resetButton {
            display: block;
            color: #fff;
            background-color: var(--black);
          }
        }
      }

      & .serialSection {
        & .serialFilter {
          gap: 5px;
        }
      }

      & .totalSearchSection {
        & .totalInputContainer {
          & .input {
            width: calc(100% - 266px);

            & input {
              height: 70px;
              border-right: none;
            }
          }
        }

        & .normalFilter {
          gap: 20px;

          & .modelSelect {
            width: 149px;
          }

          & input {
            width: 80px;
          }
        }

        & .checkArea {
          margin-bottom: 50px;
          padding: 60px;
          background-color: var(--gray03);

          & .serviceListWrap {
            & > div {
              gap: 15px !important;

              & > div {
                gap: 50px !important;
              }
            }
          }

          & .serviceList {
            & .title {
              padding: 15px 30px;
              font-size: 24px;
              font-weight: bold;
              color: #fff;
              background-color: var(--main);
            }
          }
        }
      }
    }
  }

  @media (max-width: 1740px) {
    padding: calc(100vw * (80 / 1740)) 0 calc(100vw * (200 / 1740));

    & .inner {
      gap: calc(100vw * (80 / 1740));

      & .narrowInner {
        & section {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (100 / 1740));
          }
        }

        & .sectionTitle,
        .searchTitle {
          font-size: calc(100vw * (36 / 1740));
        }

        & .sectionTitle {
          margin-bottom: calc(100vw * (20 / 1740));
        }

        & .searchTitle {
          padding-bottom: calc(100vw * (20 / 1740));
          border-bottom: calc(100vw * (3 / 1740)) solid var(--black);
        }

        & .subSectionTitle {
          margin-bottom: calc(100vw * (20 / 1740));
          font-size: calc(100vw * (26 / 1740));
        }

        & .inputContainer,
        .serialFilter,
        .normalFilter {
          margin-bottom: calc(100vw * (50 / 1740));

          & .inputWrap {
            gap: calc(100vw * (5 / 1740));

            & input {
              width: calc(100vw * (85 / 1740));
              height: calc(100vw * (70 / 1740));
            }
          }

          & .buttonWrap {
            & button {
              width: calc(100vw * (133 / 1740));
            }
          }
        }

        & .serialSection {
          & .serialFilter {
            gap: calc(100vw * (5 / 1740));
          }
        }

        & .totalSearchSection {
          & .totalInputContainer {
            & .input {
              width: calc(100% - calc(100vw * (266 / 1740)));

              & input {
                height: calc(100vw * (70 / 1740));
              }
            }
          }

          & .normalFilter {
            gap: calc(100vw * (20 / 1740));

            & .modelSelect {
              width: calc(100vw * (149 / 1740));
            }

            & input {
              width: calc(100vw * (80 / 1740));
            }
          }

          & .checkArea {
            margin-bottom: calc(100vw * (50 / 1740));
            padding: calc(100vw * (60 / 1740));

            & .serviceListWrap {
              & > div {
                gap: calc(100vw * (15 / 1740)) !important;

                & > div {
                  gap: calc(100vw * (50 / 1740)) !important;
                }
              }
            }

            & .serviceList {
              & .title {
                padding: calc(100vw * (15 / 1740)) calc(100vw * (30 / 1740));
                font-size: calc(100vw * (24 / 1740));
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (50 / 375)) 0 calc(100vw * (150 / 375));

    & .inner {
      flex-direction: column;
      gap: calc(100vw * (50 / 375));
      width: 100%;

      & .narrowInner {
        & section {
          &:not(:last-child) {
            margin-bottom: calc(100vw * (80 / 375));
          }
        }

        & .sectionTitle,
        .searchTitle {
          font-size: calc(100vw * (24 / 375));
        }

        & .sectionTitle {
          margin-bottom: calc(100vw * (20 / 375));
        }

        & .searchTitle {
          padding-bottom: calc(100vw * (20 / 375));
          border-bottom: calc(100vw * (3 / 375)) solid var(--black);
        }

        & .subSectionTitle {
          margin: calc(100vw * (-13 / 375)) 0 calc(100vw * (20 / 375));
          font-size: calc(100vw * (18 / 375));
        }

        & .inputContainer,
        .serialFilter,
        .normalFilter {
          margin-bottom: calc(100vw * (30 / 375));

          & .inputWrap {
            gap: calc(100vw * (5 / 375));

            & input {
              width: calc(100vw * (80 / 375));
              height: calc(100vw * (70 / 375));
            }
          }

          & .buttonWrap {
            & button {
              width: calc(100vw * (80 / 375));
            }
          }
        }

        & .serialSection {
          & .serialFilter {
            gap: calc(100vw * (5 / 375));
          }
        }

        & .totalSearchSection {
          & .totalInputContainer {
            & .input {
              width: calc(100% - calc(100vw * (160 / 375)));

              & input {
                height: calc(100vw * (70 / 375));
              }
            }
          }

          & .normalFilter {
            flex-direction: column;
            gap: calc(100vw * (10 / 375));

            & .modelSelect {
              width: calc(100vw * (149 / 375));
            }
          }

          & .checkArea {
            margin-bottom: calc(100vw * (30 / 375));
            padding: calc(100vw * (30 / 375)) calc(100vw * (10 / 375));

            & .serviceListWrap {
              & > div {
                gap: calc(100vw * (30 / 375)) !important;

                & > div {
                  gap: calc(100vw * (30 / 375)) !important;
                }
              }
            }

            & .serviceList {
              & .title {
                padding: calc(100vw * (15 / 375)) calc(100vw * (20 / 375));
                font-size: calc(100vw * (18 / 375));
              }
            }
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
