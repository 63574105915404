import Styles from "./styles";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { common, layout } from "components";
import { useLanguage, useTerm } from "hooks";

// img
import BgMainBanner from "resources/image/terms/bg_main_banner.jpg";
import BgMainBannerMb from "resources/image/terms/bg_main_banner_mb.jpg";

function TermsPage() {
  const { id } = useParams();

  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";
  const isMobile = useMediaQuery({ query: "(max-width: 728px)" });
  const termInfo = useTerm({
    type: id === "service" ? 1 : 2
  });

  const termData = termInfo.getTerm?.data?.data || []
  const data = {
    img: isMobile ? BgMainBannerMb : BgMainBanner,
    title: languageQuarter ? "이용약관" : "Terms and Conditions",
    firstDepthList: [
      {
        text: languageQuarter ? "서비스 이용약관" : "Service Terms and Conditions",
        link: "/terms/service",
      },
      {
        text: languageQuarter ? "개인정보처리방침" : "Privacy Policy",
        link: "/terms/privacy",
      },
    ],
  };
  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Banner data={data} />

        <div className="inner">
          <common.SideMenu data={data} />

          <div className="narrowInner">
            <h2 className="title">
              {id === "service" && (languageQuarter ? "서비스 이용약관" : "Terms of Service")}
              {id === "privacy" && (languageQuarter ? "개인정보처리방침" : "Privacy Policy")}
            </h2>

            <div className="editorContainer">
              <div
                className="editor"
                dangerouslySetInnerHTML={{ __html: termData["description" + languageInfo.suffix] }}
              ></div>
            </div>
          </div>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default TermsPage;
