import styled from "styled-components";

const Container = styled.div`
  padding-top: 50px;
  text-align: center;

  & .text {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.41;

    & span {
      color: var(--main);
    }
  }

  & .grayText {
    margin-bottom: 260px;
    font-size: 14px;
    font-weight: 600;
    color: var(--gray01);
  }

  & .confirmButton {
    margin: 0 auto;
  }

  @media (max-width: 1740px) {
    padding-top: calc(100vw * (50 / 1740));

    & .text {
      margin-bottom: calc(100vw * (20 / 1740));
      font-size: calc(100vw * (24 / 1740));
    }

    & .grayText {
      margin-bottom: calc(100vw * (260 / 1740));
      font-size: calc(100vw * (14 / 1740));
    }
  }

  @media (max-width: 768px) {
    padding-top: calc(100vw * (50 / 375));

    & .text {
      margin-bottom: calc(100vw * (20 / 375));
      font-size: calc(100vw * (18 / 375));
      line-height: 1.44;
    }

    & .grayText {
      margin-bottom: calc(100vw * (237 / 375));
      font-size: calc(100vw * (14 / 375));
    }

    & .confirmButton {
      width: calc(100vw * (250 / 375));
    }
  }
`;

export { Container };

export default { Container };
