import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 375px;
  min-height: 100vh;
  margin: 0 auto;

  & .thinInner {
    padding: 50px 20px 63px;

    & .specTitle {
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
    }

    & .morganListWrap {
      margin-bottom: 50px;

      & .morganList:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    & .thinInner {
      padding: calc(100vw * (50 / 375)) calc(100vw * (20 / 375))
        calc(100vw * (63 / 375));

      & .specTitle {
        margin-bottom: calc(100vw * (20 / 375));
        font-size: calc(100vw * (24 / 375));
      }

      & .morganListWrap {
        margin-bottom: calc(100vw * (50 / 375));

        & .morganList:not(:last-child) {
          margin-bottom: calc(100vw * (20 / 375));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
