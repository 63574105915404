import utils from "utils";
import Styles from "./styles";
import { common, ui } from "components";
import { useLanguage } from "hooks";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";

function SearchPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const [inputs, setInputs] = useState({ search: "" });
  const input = {
    name: "search",
    value: inputs.search,
    placeholder: languageQuarter ? "검색어를 입력해주세요." : "Enter a search word.",
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs((e) => {
      return { ...e, [name]: value };
    });
  };

  const search = () => {
    if (inputs.search !== "") {
      navigate("result", { replace: true });
      navigate(utils.etc.getQueryParamUrl({ keyword: encodeURIComponent(inputs.search) }, false), { replace: true })
    }
  }

  useEffect(() => {
    setInputs({ search: decodeURIComponent(searchParams.get("keyword") || "") })
  }, []);

  return (
    <>
      <common.Header />
      <Styles.Container>
        <div className="banner">
          <div className="narrowInner">
            <div className="input">
              <ui.input.BasicInput
                data={input}
                onChange={onChange}
                onKeyUp={search}
              />
            </div>

            <div className="searchButton">
              <ui.button.BasicButton
                buttonText={languageQuarter ? "검색" : "Search"}
                onClick={search}
              />
            </div>
          </div>
        </div>

        <div className="searchSection">
          <div className="narrowInner">
            <Outlet
              context={{ keyword: inputs.search }}
            />
          </div>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default SearchPage;
