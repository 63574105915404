import ImgBanner01 from "resources/image/main/img_dummy_banner01.jpg";
import ImgBanner02 from "resources/image/main/img_dummy_banner02.jpg";
import ImgBanner03 from "resources/image/main/img_dummy_banner03.jpg";
import ImgBanner04 from "resources/image/main/img_dummy_banner04.jpg";
import ImgBanner05 from "resources/image/main/img_dummy_banner05.jpg";
import ImgBanner01Mb from "resources/image/main/img_dummy_banner01_mb.jpg";
import ImgProduct from "resources/image/main/img_dummy_product01.png";
import { ip } from "service/connector";

// common
const newsList = [
  "https://www.youtube.com/embed/n8fOXod2d7k?si=guUS8GxUqyUt1NeR",
  "https://www.youtube.com/embed/n8fOXod2d7k?si=guUS8GxUqyUt1NeR",
  "https://www.youtube.com/embed/n8fOXod2d7k?si=guUS8GxUqyUt1NeR",
  "https://www.youtube.com/embed/n8fOXod2d7k?si=guUS8GxUqyUt1NeR",
  "https://www.youtube.com/embed/n8fOXod2d7k?si=guUS8GxUqyUt1NeR",
  "https://www.youtube.com/embed/n8fOXod2d7k?si=guUS8GxUqyUt1NeR",
];

// main
const slideList = {
  pc: [ImgBanner01, ImgBanner02, ImgBanner03, ImgBanner04, ImgBanner05],
  mb: [
    ImgBanner01Mb,
    ImgBanner01Mb,
    ImgBanner01Mb,
    ImgBanner01Mb,
    ImgBanner01Mb,
  ],
};
const productList = [
  {
    img: ImgProduct,
    title: "[ TX 시리즈 ]",
    description: "지능형 다회전 엑츄에이터",
  },
  {
    img: ImgProduct,
    title: "[ TX 시리즈 ]",
    description: "지능형 다회전 엑츄에이터",
  },
  {
    img: ImgProduct,
    title: "[ TX 시리즈 ]",
    description: "지능형 다회전 엑츄에이터",
  },
  {
    img: ImgProduct,
    title: "[ TX 시리즈 ]",
    description: "지능형 다회전 엑츄에이터",
  },
  {
    img: ImgProduct,
    title: "[ TX 시리즈 ]",
    description: "지능형 다회전 엑츄에이터",
  },
];

// companyIntroduction
const historyList = [
  {
    tag: "도약기",
    year: "2016~",
    title: "해외시장 진출 확대 및 신제품 개발 강화",
    detailList: [
      "- 2016 SIL 인증",
      "- 2016 베트남 공장 설립",
      "- 2017 두바이 사무실 설립",
      "- 2018 소재 부품 신뢰성 인증",
      "- 2019 NEP 인증(TX Model)",
      "- 2022 대구 제2공장 준공",
      "- 2023 미국, 프랑스 J/V 설립 추진",
    ],
  },
  {
    tag: "성장기",
    year: "2002~2015",
    title: "생산 인프라 확장 및 브랜드 이미지 구축",
    detailList: [
      "- 2003 원자력용 액추에이터 Q Class 인증",
      "- 2006 코스닥 상장",
      "- 2010 포브스 선정 아시아 200대 중소기업 선정",
      "- 2013 세계 일류상품 인증",
      "- 2014 차세대 액추에이터 발표(TX)",
      "- 2015 한국남동발전 World Class 30 기업 선정",
    ],
  },
  {
    tag: "설립기",
    year: "1987~2001",
    title: "전동 액추에이터 국산화 실현",
    detailList: [
      "- 1987 회사 설립",
      "- 1990 한국전력 국산화업체 등록",
      "- 1997 ISO-9001 인증",
      "- 1998 액추에이터 신형 모델 발표(TM Model)",
    ],
  },
  {
    tag: "도약기",
    year: "2016~",
    title: "해외시장 진출 확대 및 신제품 개발 강화",
    detailList: [
      "- 2016 SIL 인증",
      "- 2016 베트남 공장 설립",
      "- 2017 두바이 사무실 설립",
      "- 2018 소재 부품 신뢰성 인증",
      "- 2019 NEP 인증(TX Model)",
      "- 2022 대구 제2공장 준공",
      "- 2023 미국, 프랑스 J/V 설립 추진",
    ],
  },
  {
    tag: "성장기",
    year: "2002~2015",
    title: "생산 인프라 확장 및 브랜드 이미지 구축",
    detailList: [
      "- 2003 원자력용 액추에이터 Q Class 인증",
      "- 2006 코스닥 상장",
      "- 2010 포브스 선정 아시아 200대 중소기업 선정",
      "- 2013 세계 일류상품 인증",
      "- 2014 차세대 액추에이터 발표(TX)",
      "- 2015 한국남동발전 World Class 30 기업 선정",
    ],
  },
  {
    tag: "설립기",
    year: "1987~2001",
    title: "전동 액추에이터 국산화 실현",
    detailList: [
      "- 1987 회사 설립",
      "- 1990 한국전력 국산화업체 등록",
      "- 1997 ISO-9001 인증",
      "- 1998 액추에이터 신형 모델 발표(TM Model)",
    ],
  },
];
const patentList = [
  {
    img: "",
    title: "밸브 액츄에이터",
    number: "제 00-00000000호",
  },
  {
    img: "",
    title: "밸브 액츄에이터",
    number: "제 00-00000000호",
  },
  {
    img: "",
    title: "밸브 액츄에이터",
    number: "제 00-00000000호",
  },
  {
    img: "",
    title: "밸브 액츄에이터",
    number: "제 00-00000000호",
  },
];
const agencyList = {
  domestic: [
    {
      title: "title",
      companyName: "company name",
      detail:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed sed Lorem ipsum d",
    },
    {
      title: "title",
      companyName: "company name",
      detail: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    },
    {
      title: "title",
      companyName: "company name",
      detail:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed sed Lorem ipsum d",
    },
    {
      title: "title",
      companyName: "company name",
      detail:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed sed Lorem ipsum d",
    },
  ],
  foreign: [
    {
      title: "title",
      companyName: "company name",
      detail:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed sed Lorem ipsum d",
    },
    {
      title: "title",
      companyName: "company name",
      detail:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed sed Lorem ipsum d",
    },
    {
      title: "title",
      companyName: "company name",
      detail:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed sed Lorem ipsum d",
    },
    {
      title: "title",
      companyName: "company name",
      detail:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed sed Lorem ipsum d",
    },
  ],
};

// search
const searchList = [
  {
    oneDepth: "회사 소개",
    twoDepth: "회사 정보",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mim veniam, quis nostrud incididunt exercitation ullamco laboris nisi ut aliquip ex ea commodo incididunt consequat.",
    url: "",
  },
  {
    oneDepth: "회사 소개",
    twoDepth: "회사 정보",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud incididunt exercitation ullamco laboris nisi ut aliquip ex ea commodo incididunt consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud incididunt exercitation ullamco laboris nisi ut aliquip ex ea commodo incididunt consequat.",
    url: "/",
  },
];

// investment
const publicList = [
  {
    number: 1,
    title: "쉽게 알 수 없는 고양이 외양간에 관한 10가지 DIY",
    date: "23.01.01",
  },
  {
    number: 2,
    title: "쉽게 알 수 없는 고양이 외양간에 관한 10가지 DIY",
    date: "23.01.01",
  },
  {
    number: 3,
    title: "쉽게 알 수 없는 고양이 외양간에 관한 10가지 DIY",
    date: "23.01.01",
  },
  {
    number: 4,
    title: "쉽게 알 수 없는 고양이 외양간에 관한 10가지 DIY",
    date: "23.01.01",
  },
  {
    number: 5,
    title: "쉽게 알 수 없는 고양이 외양간에 관한 10가지 DIY",
    date: "23.01.01",
  },
  {
    number: 6,
    title: "쉽게 알 수 없는 고양이 외양간에 관한 10가지 DIY",
    date: "23.01.01",
  },
];
const shareholderList = [
  {
    text: "제 36기 정기주주총회",
    list: [
      {
        title:
          "텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.",
        downloadList: [
          {
            type: 0,
            download: "a",
          },
          {
            type: 1,
            download: "b",
          },
        ],
      },
      {
        title:
          "텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.",
        downloadList: [
          {
            type: 13,
            download: "c",
          },
        ],
      },
    ],
  },
  {
    text: "제 35기 정기주주총회",
    list: [],
  },
];
const internalList = [
  {
    title:
      "내부정보규정관리",
    data_list: [
      {
        icon: "PDF",
        path: ip + "s3/download?file_name=data/upload/orig_a89a76ef-8d45-4ca0-9257-0247d760cb85.pdf&origin_file_name=내부정보규정관리.pdf",
        download: "내부정보규정관리.pdf",
      },
    ],
  },
];
const settlementList = [
  {
    text: "제 36기 결산공고",
    list: [
      {
        title:
          "텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.",
        downloadList: [
          {
            type: 0,
            download: "a",
          },
          {
            type: 1,
            download: "b",
          },
        ],
      },
      {
        title:
          "텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.",
        downloadList: [
          {
            type: 13,
            download: "c",
          },
        ],
      },
    ],
  },
  {
    text: "제 35기 결산공고",
    list: [],
  },
];

// product
const productMenuList = [
  {
    text: "전동 ACTUATOR",
    link: "actuator",
    secondDepthList: [
      {
        text: "TM",
        link: "TM",
        thirdDepthList: [
          {
            text: "BASIC",
            link: "BASIC",
          },
          {
            text: "INTEGRAL",
            link: "INTEGRAL",
          },
          {
            text: "SMART",
            link: "SMART",
          },
        ],
      },
      {
        text: "TX",
        link: "TX",
      },
      {
        text: "TQ",
        link: "TQ",
        thirdDepthList: [
          {
            text: "BASIC",
            link: "BASIC",
          },
        ],
      },
      {
        text: "LTMD-O",
        link: "LTMD-O",
      },
      {
        text: "WT",
        link: "WT",
      },
    ],
  },
  {
    text: "수문 권양기",
    link: "hydrological",
    secondDepthList: [
      {
        text: "TM",
        link: "TM",
      },
      {
        text: "TX",
        link: "TX",
      },
    ],
  },
];
const productData = {
  layoutType: 1,
  productName: "TM",
  description: "텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.",
  imgList: [
    ImgProduct,
    ImgBanner05,
    ImgProduct,
    ImgProduct,
    ImgProduct,
    ImgProduct,
  ],
  detailDescription:
    "NET 인증기술 200건 및 NEP 인증제품 163건을 대상으로 실시한 ‘성과분석 및 대표성과 발굴과정’을 통해 우수 인증 업체로 선정된 에너토크는 동종업체 최초로 유무선 통신을 적용한 전동액츄에이터로 2019년 NEP 인증을 받았다. 전동 구동기에 불모지였던 한국에서 국내 기간산업의 발전을 위해 1987년 설립한 에너토크는 이후 국내 전동 액츄에이터 업계의 선두주자",
  category: {
    categoryTitle: "항목명1",
    categoryDescription:
      "NET 인증기술 200건 및 NEP 인증제품 163건을 대상으로 실시한 ‘성과분석 및",
  },
  item: [
    {
      itemImg: ImgProduct,
      itemName: "[ TX 시리즈 ]",
      itemDescription: "지능형 다회전 엑츄에이터",
    },
    {
      itemImg: ImgProduct,
      itemName: "[ TX 시리즈 ]",
      itemDescription: "지능형 다회전 엑츄에이터",
    },
    {
      itemImg: ImgProduct,
      itemName: "[ TX 시리즈 ]",
      itemDescription: "지능형 다회전 엑츄에이터",
    },
  ],
  option: [
    {
      optionTitle: "타이틀 타이틀 타이틀 타이틀 타이틀 타이틀 타이틀",
    },
    {
      optionTitle: "타이틀 타이틀 타이틀 타이틀 타이틀 타이틀 타이틀",
    },
    {
      optionTitle: "타이틀 타이틀 타이틀 타이틀 타이틀 타이틀 타이틀",
    },
  ],
};
const relatedDataOptionList = [
  {
    text: "카다로그 API 부분",
  },
  {
    text: "회로도",
  },
  {
    text: "외형도",
  },
  {
    text: "토크 테이블",
  },
  {
    text: "소프트웨어",
  },
];
const relatedDataList = [
  {
    category: "카다로그",
    title:
      "텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.",
    downloadList: [
      {
        type: 0,
        download: "a",
      },
      {
        type: 1,
        download: "b",
      },
      {
        type: 3,
        download: "a",
      },
      {
        type: 4,
        download: "b",
      },
    ],
  },
  {
    category: "회로도",
    title:
      "텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.",
    downloadList: [
      {
        type: 13,
        download: "c",
      },
    ],
  },
  {
    category: "카다로그",
    title:
      "텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.",
    downloadList: [
      {
        type: 5,
        download: "c",
      },
    ],
  },
];

//service
const serviceList = [
  {
    types: "종류/직무",
    checkList: [
      {
        id: "1",
        labelText: "Enertork Dataroom",
      },
      {
        id: "2",
        labelText: "Enertork Dataroom",
      },
      {
        id: "3",
        labelText: "Enertork Dataroom",
      },
      {
        id: "4",
        labelText: "Enertork Dataroom",
        isDisabled: true,
      },
      {
        id: "2",
        labelText: "Enertork Dataroom",
      },
      {
        id: "3",
        labelText: "Enertork Dataroom",
      },
      {
        id: "4",
        labelText: "Enertork Dataroom",
        isDisabled: true,
      },
      {
        id: "2",
        labelText: "Enertork Dataroom",
      },
      {
        id: "3",
        labelText: "Enertork Dataroom",
      },
      {
        id: "4",
        labelText: "Enertork Dataroom",
        isDisabled: true,
      },
    ],
  },
  {
    types: "종류/직무",
    checkList: [
      {
        id: "5",
        labelText: "Enertork Dataroom",
      },
      {
        id: "6",
        labelText: "Enertork Dataroom",
      },
      {
        id: "7",
        labelText: "Enertork Dataroom",
        isDisabled: true,
      },
    ],
  },
  {
    types: "종류/직무",
    checkList: [
      {
        id: "8",
        labelText: "Enertork Dataroom",
      },
      {
        id: "9",
        labelText: "Enertork Dataroom",
        isDisabled: true,
      },
      {
        id: "10",
        labelText: "Enertork Dataroom",
        isDisabled: true,
      },
      {
        id: "11",
        labelText: "Enertork Dataroom",
      },
      {
        id: "10",
        labelText: "Enertork Dataroom",
      },
      {
        id: "11",
        labelText: "Enertork Dataroom",
      },
    ],
  },
  {
    types: "종류/직무",
    checkList: [
      {
        id: "1",
        labelText: "Enertork Dataroom",
      },
      {
        id: "2",
        labelText: "Enertork Dataroom",
      },
      {
        id: "3",
        labelText: "Enertork Dataroom",
      },
      {
        id: "4",
        labelText: "Enertork Dataroom",
        isDisabled: true,
      },
    ],
  },
];
const serviceSearchList = [
  {
    title:
      "텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.",
    downloadList: [
      {
        type: 0,
        download: "a",
      },
      {
        type: 1,
        download: "b",
      },
    ],
  },
  {
    title:
      "텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.",
    downloadList: [
      {
        type: 13,
        download: "c",
      },
    ],
  },
];
const serialSearchList = [
  {
    title:
      "텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.텍스트가 들어가는 자리입니다.",
    downloadList: [
      {
        type: 11,
        download: "a",
      },
      {
        type: 12,
        download: "b",
      },
    ],
  },
];
const modelOptionList = [{ text: "TM" }, { text: "TQ" }, { text: "TX" }];
const normalOptionList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

// morgan
const morganSearchList = [
  {
    type: "erp",
    ordNumber: "00-0000-0000",
    actuatorName:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud incididunt exercitation ullamc",
  },
  {
    type: "erp",
    ordNumber: "00-0000-0000",
    actuatorName:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud incididunt exercitation ullamc",
  },
  {
    type: "eps",
    ordNumber: "00-0000-0000",
    actuatorName:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud incididunt exercitation ullamc",
  },
  {
    type: "eps",
    ordNumber: "00-0000-0000",
    actuatorName:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud incididunt exercitation ullamc",
  },
];
const erpData = [
  {
    specTitle: "기계제작 사양서",
    specList: [
      {
        title: "ACTUATOR 사양",
        list: [
          {
            title: "작업번호",
          },
          {
            title: "시리얼번호",
          },
          {
            title: "모델명",
          },
          {
            title: "TYPE",
          },
          {
            title: "수량",
          },
          {
            title: "발주처",
          },
          {
            title: "건명",
          },
          {
            title: "핸들 우회전시",
          },
          {
            title: "MOTOR",
          },
          {
            title: "보호구조",
          },
          {
            title: "RPM",
          },
          {
            title: "WORM A/B GEAR",
          },
          {
            title: "수동감속기",
          },
          {
            title: "개폐시간",
          },
          {
            title: "토크값(MAX\n/OPEN/CLOSE)",
          },
          {
            title: "토크스프링",
          },
          {
            title: "재질",
          },
          {
            title: "내경가공",
          },
          {
            title: "KEY WAY",
          },
          {
            title: "STEM COVER",
          },
        ],
      },
      {
        title: "밸브 사양",
        list: [
          {
            title: "밸브TAG",
          },
          {
            title: "밸브서비스",
          },
          {
            title: "밸브타입",
          },
          {
            title: "밸브사이즈",
          },
          {
            title: "STEM DIA",
          },
          {
            title: "STROKE",
          },
          {
            title: "나사방향",
          },
          {
            title: "나사LEAD",
          },
          {
            title: "줄수",
          },
          {
            title: "TPI",
          },
          {
            title: "밸브구입 유/무",
          },
        ],
      },
      {
        title: "개도계사양",
        list: [
          {
            title: "출력회전수",
          },
          {
            title: "개도계각도",
          },
          {
            title: "개도계 감속비",
          },
          {
            title: "리미트 S/W",
          },
          {
            title: "토크 S/W",
          },
          {
            title: "POTENTIOMETER",
          },
          {
            title: "R/I CONVERTER",
          },
          {
            title: "저항",
          },
          {
            title: "ARRESTER",
          },
          {
            title: "비례제어",
          },
          {
            title: "POT GEAR",
          },
        ],
      },
      {
        title: "공급전원사양",
        list: [
          {
            title: "POWER",
          },
          {
            title: "MC",
          },
          {
            title: "CONTROL",
          },
          {
            title: "DC",
          },
          {
            title: "HEATER",
          },
          {
            title: "용량",
          },
          {
            title: "R/I CONVERTER",
          },
          {
            title: "CABLE ENTRY",
          },
          {
            title: "페인트",
          },
          {
            title: "메모1",
          },
          {
            title: "메모2",
          },
          {
            title: "메모3",
          },
          {
            title: "메모4",
          },
          {
            title: "개정내역1",
          },
          {
            title: "개정내역2",
          },
          {
            title: "개정내역3",
          },
        ],
      },
      {
        title: "감속기사양",
        list: [
          {
            title: "감속기종류",
          },
          {
            title: "감속기모델",
          },
          {
            title: "단동연동",
          },
          {
            title: "스텐드",
          },
          {
            title: "조인트",
          },
          {
            title: "감속기 KEY",
          },
        ],
      },
    ],
  },
  {
    specTitle: "전기제작 사양서",
    specList: [
      {
        title: "전기사양",
        list: [
          {
            title: "회로도 번호",
          },
          {
            title: "LIMIT S/W",
          },
          {
            title: "TORQUE S/W",
          },
          {
            title: "SPACE HEATER",
          },
          {
            title: "TERMINAL",
          },
        ],
      },
      {
        title: "옵션",
        list: [
          {
            title: "R/I CONVERTER",
          },
          {
            title: "ARRESTER",
          },
          {
            title: "E_POSITIONER",
          },
        ],
      },
      {
        title: "CONTROLLER",
        subCategory: [
          {
            subCategoryTitle: "INTEGRAL",
            list: [
              {
                title: "TRANSFORMER",
              },
              {
                title: "정역 CONTACTOR",
              },
              {
                title: "상보호기",
              },
              {
                title: "PIN-CONNECTOR",
              },
              {
                title: "OPERATING PCB",
              },
              {
                title: "LAMP",
              },
              {
                title: "조작 S/W",
              },
              {
                title: "FUSE",
              },
              {
                title: "INTERPOSING RELAY",
              },
              {
                title: "모터보호계전기",
              },
              {
                title: "SPARK KILLER",
              },
              {
                title: "CONDENSER",
              },
            ],
          },
          {
            subCategoryTitle: "SEMI INTEGRAL",
            list: [
              {
                title: "PIN-CONNECTOR",
              },
              {
                title: "OPERATING PCB",
              },
              {
                title: "조작 S/W",
              },
              {
                title: "LAMP",
              },
            ],
          },
          {
            subCategoryTitle: "LOCAL PANEL",
            list: [
              {
                title: "PANEL 재질",
              },
              {
                title: "PANEL 형식",
              },
              {
                title: "TRANSFORMER",
              },
              {
                title: "정역 CONTACTOR",
              },
              {
                title: "RELAY",
              },
              {
                title: "EOCR",
              },
              {
                title: "LAMP",
              },
              {
                title: "SELECTOR S/W",
              },
              {
                title: "INDICATOR",
              },
              {
                title: "NFB",
              },
              {
                title: "VOLT METER",
              },
              {
                title: "AMP METER",
              },
              {
                title: "CABLE ENTRY",
              },
            ],
          },
        ],
      },
    ],
  },
];
const epsData = [
  {
    title: "ACTUATOR 정보",
    list: [
      {
        title: "작업번호",
      },
      {
        title: "SER-NO",
      },
      {
        title: "공정 범위",
      },
      {
        title: "발주처",
      },
      {
        title: "수량",
      },
      {
        title: "납기일",
      },
      {
        title: "건명",
      },
    ],
  },

  {
    title: "밸브 사양",
    list: [
      {
        title: "TAG NO.",
      },
      {
        title: "회전방향",
      },
      {
        title: "밸브 TYPE",
      },
      {
        title: "CLASS(RATING)",
      },
      {
        title: "SIZE",
      },
      {
        title: "STEM DIA",
      },
      {
        title: "STROKE (mm)",
      },
      {
        title: "나사방향",
      },
      {
        title: "나사 LEAD",
      },
      {
        title: "줄 수",
      },
      {
        title: "밸브구매 유/무",
      },
      {
        title: "사급밸브 유/무",
      },
      {
        title: "밸브조립 유/무",
      },
      {
        title: "설치자재 공급 유/무",
      },
      {
        title: "이상식자재",
      },
      {
        title: "연동식자재",
      },
      {
        title: "공급분리형",
      },
      {
        title: "과부하계전기",
      },
      {
        title: "S.S.R",
      },
      {
        title: "외부단자함 유/무",
      },
      {
        title: "원자력",
      },
      {
        title: "램프 비표준",
      },
    ],
  },

  {
    title: "기본 ACTUATOR 사양",
    list: [
      {
        title: "MODEL",
      },
      {
        title: "MOTOR (KW)",
      },
      {
        title: "MOTOR HEATER \n유/무",
      },
      {
        title: "MOTOR TH X 3 \n유/무",
      },
      {
        title: "MOTOR S4 유/무",
      },
      {
        title: "MOTOR H종 유/무",
      },
      {
        title: "MOTOR 30분 정격 \n유/무",
      },
      {
        title: "MOTOR 저관성 \n유/무",
      },
      {
        title: "MOTOR 2 POLE \n유/무",
      },
      {
        title: "제어방식",
      },
      {
        title: "제어옵션",
      },
      {
        title: "OPERTING TYPE",
      },
      {
        title: "제어옵션",
      },
      {
        title: "보호구조",
      },
      {
        title: "주전원",
      },
      {
        title: "제어전원",
      },
      {
        title: "Hammer Blow",
      },
      {
        title: "RPM",
      },
      {
        title: "A/B GEAR",
      },
      {
        title: "WORM",
      },
      {
        title: "토크스프링",
      },
      {
        title: "세팅 범위 (klgf.m)",
      },
      {
        title: "개폐시간 (SEC)",
      },
      {
        title: "최대 TORQUE (kgf.m)",
      },
      {
        title: "SETTING TORQUE \nOPEN (kgf.m)",
      },
      {
        title: "SETTING TORQUE \nCLOSE (kgf.m)",
      },
      {
        title: "SETTING TORQUE \nOPEN",
      },
      {
        title: "SETTING TORQUE \nCLOSE",
      },
      {
        title: "Seating Method",
      },
    ],
  },

  {
    title: "Masking Part",
    list: [
      {
        title: "THRUST UNIT",
      },
      {
        title: "재질",
      },
      {
        title: "SIZE",
      },
      {
        title: "KEY",
      },
      {
        title: "공급방식",
      },
      {
        title: "STEM COVER",
      },
      {
        title: "핸들휠",
      },
    ],
  },

  {
    title: "Switch Unit Part",
    list: [
      {
        title: "출력회전수",
      },
      {
        title: "개도계기어",
      },
      {
        title: "각도",
      },
      {
        title: "SWITCH UNIT",
      },
      {
        title: "LIMIT SWITCH",
      },
      {
        title: "TORQUE SWITCH",
      },
      {
        title: "TORQUE HOLDING",
      },
    ],
  },

  {
    title: "Integral Part",
    list: [
      {
        title: "정역 접촉기",
      },
      {
        title: "정역 접촉기 용량",
      },
      {
        title: "TYPE",
      },
      {
        title: "TRANSFOMER",
      },
      {
        title: "분리형 유/무",
      },
      {
        title: "EOCR 유/무",
      },
    ],
  },

  {
    title: "Pot Part",
    list: [
      {
        title: "OP COVER",
      },
      {
        title: "OP BOARD",
      },
      {
        title: "POT PART",
      },
      {
        title: "보조 POT 유/무",
      },
      {
        title: "R/I CONV.",
      },
      {
        title: "ARRESTER 유/무",
      },
      {
        title: "TERMINAL",
      },
      {
        title: "SURGE PROTECTOR \n유/무",
      },
    ],
  },

  {
    title: "완성제품 PART",
    list: [
      {
        title: "COMMON CASE",
      },
      {
        title: "SWITCH COVER",
      },
      {
        title: "TERMINAL COVER",
      },
      {
        title: "INTEGRAL CASE",
      },
      {
        title: "표준/비표준",
      },
      {
        title: "ADAPTOR / REDUCER 조립 \n유/무",
      },
      {
        title: "ENTRY 수량",
      },
      {
        title: "ENTRY SIZE",
      },
      {
        title: "도장",
      },
      {
        title: "재질",
      },
      {
        title: "색상",
      },
      {
        title: "도막두께 (um)",
      },
    ],
  },

  {
    title: "감속기 PART",
    list: [
      {
        title: "MAKER",
      },
      {
        title: "종류",
      },
      {
        title: "감속기",
      },
      {
        title: "STEM BUSH",
      },
      {
        title: "ACT + 감속기",
      },
    ],
  },

  {
    title: "특기사항",
    list: [
      {
        title: "특기사항",
      },
    ],
  },
];

export {
  slideList,
  productList,
  newsList,
  historyList,
  patentList,
  agencyList,
  searchList,
  publicList,
  shareholderList,
  internalList,
  settlementList,
  productMenuList,
  productData,
  relatedDataOptionList,
  relatedDataList,
  serviceList,
  serviceSearchList,
  serialSearchList,
  modelOptionList,
  normalOptionList,
  morganSearchList,
  erpData,
  epsData,
};

export default {
  slideList,
  productList,
  newsList,
  historyList,
  patentList,
  agencyList,
  searchList,
  publicList,
  shareholderList,
  internalList,
  settlementList,
  productMenuList,
  productData,
  relatedDataOptionList,
  relatedDataList,
  serviceList,
  serviceSearchList,
  serialSearchList,
  modelOptionList,
  normalOptionList,
  morganSearchList,
  erpData,
  epsData,
};
