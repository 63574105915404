import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;

  & .iconArrow {
    width: 30px;
    height: 30px;
  }

  & .paginationList {
    display: flex;
    gap: 10px;
  }

  & .paginationList button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    font-size: 16px;
    font-weight: bold;
    color: var(--gray01);
  }

  & .paginationList button.on {
    color: #fff;
    background-color: var(--main);
  }

  @media (max-width: 1740px) {
    gap: calc(100vw * (10 / 1740));

    & .iconArrow {
      width: calc(100vw * (30 / 1740));
      height: calc(100vw * (30 / 1740));
    }

    & .paginationList {
      gap: calc(100vw * (10 / 1740));
    }

    & .paginationList button {
      width: calc(100vw * (30 / 1740));
      height: calc(100vw * (30 / 1740));
      font-size: calc(100vw * (16 / 1740));
    }
  }

  @media (max-width: 768px) {
    gap: calc(100vw * (10 / 375));

    & .iconArrow {
      width: calc(100vw * (30 / 375));
      height: calc(100vw * (30 / 375));
    }

    & .paginationList {
      gap: calc(100vw * (10 / 375));
    }

    & .paginationList button {
      width: calc(100vw * (30 / 375));
      height: calc(100vw * (30 / 375));
      font-size: calc(100vw * (16 / 375));
    }
  }
`;

export { Container };

export default { Container };
