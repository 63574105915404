import connector from "service/connector"
import utils from "utils";


const getInvestCategory = async (path) => {
  return await connector.connectFetchController("invest/category/list?type=" + path, "GET");
}
const getInvestList = async (filter) => {
  return await connector.authConnector("invest/list" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}


export {
  getInvestCategory,
  getInvestList,
};


export default {
  getInvestCategory,
  getInvestList,
}