import connector from "service/connector"


const getAgency = async (path) => {
  return await connector.connectFetchController("agency?type=" + path, "GET");
}


export {
  getAgency,
};

export default {
  getAgency,
}