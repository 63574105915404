import styled from "styled-components";

const Container = styled.div`
  & .title {
    display: flex;
    align-items: center;

    & .warningText {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-left: 25px;
      font-size: 16px;
      font-weight: 500;
      color: var(--gray01);

      & .icon {
        display: inline-block;
        width: 20px;
        height: 20px;
      }
    }

    & .select {
      width: 270px;
      margin-left: auto;
    }
  }

  & .table {
    font-weight: 600;

    & .thead {
      display: flex;
    }

    & .thead {
      padding: 20px 0;
      font-size: 16px;
      text-align: center;
      border-bottom: 1px solid var(--gray02);

      & .download {
        width: 300px;
      }

      & .listTitle {
        width: calc(100% - 300px);
      }
    }

    & .pagination {
      margin-top: 50px;
    }
  }

  @media (max-width: 1740px) {
    & .title {
      & .warningText {
        gap: calc(100vw * (5 / 1740));
        margin-left: calc(100vw * (25 / 1740));
        font-size: calc(100vw * (16 / 1740));

        & .icon {
          width: calc(100vw * (20 / 1740));
          height: calc(100vw * (20 / 1740));
        }
      }

      & .select {
        width: calc(100vw * (270 / 1740));
      }
    }

    & .table {
      & .thead {
        padding: calc(100vw * (20 / 1740)) 0;
        font-size: calc(100vw * (16 / 1740));

        & .download {
          width: calc(100vw * (300 / 1740));
        }

        & .listTitle {
          width: calc(100% - calc(100vw * (300 / 1740)));
        }
      }

      & .pagination {
        margin-top: calc(100vw * (50 / 1740));
      }
    }
  }

  @media (max-width: 768px) {
    & .title {
      flex-direction: column;
      align-items: flex-start;
      gap: calc(100vw * (15 / 375));

      & .warningText {
        gap: calc(100vw * (5 / 375));
        align-items: flex-start;
        margin-left: 0;
        font-size: calc(100vw * (14 / 375));

        & .icon {
          width: calc(100vw * (20 / 375));
          height: calc(100vw * (20 / 375));
        }
      }

      & .select {
        width: calc(100vw * (270 / 375));
        margin-left: 0;
      }
    }

    & .table {
      & .thead {
        display: none;
      }

      & .pagination {
        margin-top: calc(100vw * (50 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
