import { useQuery } from "react-query";
import { api } from "service";

export default function useMain({
  productList,
  id,
  youtubeList,
}) {

  const getProductList = useQuery(["mainList"], () => {
    return api.main.getList();
  }, {
    enabled: !!productList,
    refetchOnWindowFocus: false,
  });

  const getProductDetail = useQuery(["mainDetail", id], () => {
    return api.main.getList(id);
  }, {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  const getYoutubeList = useQuery(["youtubeList"], () => {
    return api.main.getYoutubeList();
  }, {
    enabled: !!youtubeList,
    refetchOnWindowFocus: false,
  });


	return {
    getProductList,
    getProductDetail,
    getYoutubeList,
	};
}