import styled from "styled-components";

// img
import ImgDecoWord from "resources/image/companyIntroduction/management/img_deco_word.png";

const Container = styled.div`
  & .word {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-bottom: 100px;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 141px;
      height: 91px;
      background: url(${ImgDecoWord}) no-repeat center / cover;
      content: "";
    }

    & .wordList {
      width: auto;
      height: 364px;
    }
  }

  & section:not(:last-child, .explainSection) {
    margin-bottom: 100px;
  }

  & .sectionTitle {
    & .color {
      margin-bottom: 20px;
      font-weight: 600;
      color: var(--main);
    }

    & .sectionDescription {
      margin-bottom: 30px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1.38;
    }
  }

  & .explainSection,
  .necessitySection {
    padding: 80px 55px;
    background-color: #f8f8f9;
    border-radius: 20px;

    & .description {
      line-height: 1.6;
    }
  }

  & .explainSection {
    margin-bottom: 50px;

    & .description:nth-of-type(2) {
      margin-bottom: 30px;
    }

    & .img {
      width: 1090px;
      height: 477px;
      margin: 50px auto;
    }
  }

  & .necessitySection {
    & .imgWrap {
      margin: 50px 0;

      & .img {
        height: 752px;
      }
    }
  }

  @media (max-width: 1740px) {
    & .word {
      gap: calc(100vw * (50 / 1740));
      margin-bottom: calc(100vw * (100 / 1740));

      &::before {
        width: calc(100vw * (141 / 1740));
        height: calc(100vw * (91 / 1740));
      }

      & .wordList {
        height: calc(100vw * (364 / 1740));
      }
    }

    & section:not(:last-child, .explainSection) {
      margin-bottom: calc(100vw * (100 / 1740));
    }

    & .sectionTitle {
      & .color {
        margin-bottom: calc(100vw * (20 / 1740));
      }

      & .sectionDescription {
        margin-bottom: calc(100vw * (30 / 1740));
        font-size: calc(100vw * (36 / 1740));
      }
    }

    & .explainSection,
    .necessitySection {
      padding: calc(100vw * (80 / 1740)) calc(100vw * (55 / 1740));
      border-radius: calc(100vw * (20 / 1740));
    }

    & .explainSection {
      margin-bottom: calc(100vw * (50 / 1740));

      & .description:nth-of-type(2) {
        margin-bottom: calc(100vw * (30 / 1740));
      }

      & .img {
        width: calc(100vw * (1090 / 1740));
        height: calc(100vw * (477 / 1740));
        margin: calc(100vw * (50 / 1740)) auto;
      }
    }

    & .necessitySection {
      & .imgWrap {
        margin: calc(100vw * (50 / 1740)) 0;

        & .img {
          height: calc(100vw * (752 / 1740));
        }
      }
    }
  }

  @media (max-width: 768px) {
    & .description {
      word-break: keep-all;
    }

    & .word {
      flex-direction: column;
      gap: calc(100vw * (30 / 375));
      width: calc(100vw * (270 / 375));
      margin: calc(100vw * (64 / 375)) auto calc(100vw * (78 / 375));

      &::before {
        top: calc(100vw * (-34 / 375));
        left: calc(100vw * (-32 / 375));
        width: calc(100vw * (62 / 375));
        height: calc(100vw * (40 / 375));
      }

      & .wordList {
        height: calc(100vw * (263 / 375));
      }
    }

    & section:not(:last-child, .explainSection) {
      margin-bottom: calc(100vw * (80 / 375));
    }

    & .sectionTitle {
      & .color {
        margin-bottom: calc(100vw * (10 / 375));
      }

      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 375));
        font-size: calc(100vw * (24 / 375));
      }
    }

    & .scroll-container {
      margin-bottom: calc(100vw * (20 / 375));
    }

    & .explainSection,
    .necessitySection {
      padding: calc(100vw * (50 / 375)) calc(100vw * (20 / 375));
      border-radius: calc(100vw * (20 / 375));
    }

    & .explainSection {
      margin-bottom: calc(100vw * (30 / 375));

      & .description:nth-of-type(2) {
        margin-bottom: calc(100vw * (20 / 375));
      }

      & .img {
        width: 100%;
        height: calc(100vw * (707 / 375));
        margin: calc(100vw * (30 / 375)) auto;
      }
    }

    & .necessitySection {
      & .imgWrap {
        margin: calc(100vw * (30 / 375)) 0;

        & .img {
          width: calc(100vw * (1220 / 375));
          height: calc(100vw * (702 / 375));
        }
      }
    }

    @media (max-width: 450px) {
        & .necessitySection {
          & .imgWrap {
            & .img {
              width: calc(100vw * (800 / 375));
              height: calc(100vw * (460.32 / 375));
            }
          }
        }
    }
  }
`;

export { Container };

export default { Container };
