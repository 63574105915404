import BasicInput from "./BasicInput";
import BasicTextarea from "./BasicTextarea";
import BasicCheckbox from "./BasicCheckbox";
import BasicSelect from "./BasicSelect";
import ServiceCheckbox from "./ServiceCheckbox";
import ModelSelect from "./ModelSelect";
import InputSelect from "./InputSelect";

export {
  BasicInput,
  BasicTextarea,
  BasicCheckbox,
  BasicSelect,
  ServiceCheckbox,
  ModelSelect,
  InputSelect,
};

export default {
  BasicInput,
  BasicTextarea,
  BasicCheckbox,
  BasicSelect,
  ServiceCheckbox,
  ModelSelect,
  InputSelect,
};
