import React from "react";

//숫자 3자리수 콤마 코드
function numberWithCommas(x) {
  try {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (e) {
    return "0";
  }
}

// 검색어 하이라이팅
const highlighting = (v, keyword) => {
  const text = v.split(keyword);

  return text.map((item, index) => {
    return (
      <React.Fragment key={"text" + index}>
        {index > 0 && <span className="highlighting">{keyword}</span>}
        {item}
      </React.Fragment>
    );
  });
};

// 검색어 하이라이팅 대소문자 무시
const highlightIgnoreCase = (v, keyword) => {
  const lowerCaseV = v.toLowerCase();
  const lowerCaseKeyword = keyword.toLowerCase();
  const text = lowerCaseV.split(lowerCaseKeyword);

  let currentIndex = 0;
  
  return text.map((item, index) => {
    const originalText = v.substring(currentIndex, currentIndex + item.length);
    currentIndex += item.length + keyword.length;

    return (
      <React.Fragment key={"text" + index}>
        {originalText}
        {index < text.length - 1 && <span className="highlighting">{v.substring(currentIndex - keyword.length, currentIndex)}</span>}
      </React.Fragment>
    );
  });
};

function getFilterStr(parameter) {
  let filter = new URLSearchParams(parameter).toString();
  return decodeURIComponent(filter);
}

function getSearchParam(){
  try{
    return JSON.parse('{"' + decodeURI(window.location.search.substring(1).replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}')
  }catch(e){
    return {};
  }
}

//keyArray = { key : page, value : 1 };
function getQueryParamUrl(obj, isClear, isParamOnly){
  try{
    obj = obj ? obj : {};
    let searchParam = isClear ? {} : getSearchParam();
    for(let key in obj){
      if(obj[key] == null){
        delete searchParam[key];
        continue;
      };
      searchParam[key] = obj[key];
    }
    if(isParamOnly){
      return getFilterStr(searchParam);
    }
    return `${window.location.pathname}?${getFilterStr(searchParam)}`;
  }catch(e){
    return {};
  }
}

export {
  numberWithCommas,
  highlighting,
  highlightIgnoreCase,
  getFilterStr,
  getQueryParamUrl,
  getSearchParam,
};

export default {
  numberWithCommas,
  highlighting,
  highlightIgnoreCase,
  getFilterStr,
  getQueryParamUrl,
  getSearchParam,
};
