import Styles from "./styles";
import { useLanguage } from "hooks";

function BasicCheckbox({ data, onChange, checked }) {
  const { id, labelText, essential } = data;
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  return (
    <>
      <Styles.Input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />

      <Styles.Label htmlFor={id}>
        <span className="checkbox"></span>

        {labelText && (
          <span className="labelText">
            {essential && <span className="essential">[{languageQuarter ? "필수" : "Required"}]</span>}

            {labelText}
          </span>
        )}
      </Styles.Label>
    </>
  );
}

export default BasicCheckbox;
