import styled from "styled-components";

// img
import BgOverview from "resources/image/companyIntroduction/companyInfo/bg_overview_section.jpg";
import BgOverviewMb from "resources/image/companyIntroduction/companyInfo/bg_overview_section_mb.jpg";
import BgGreeting from "resources/image/companyIntroduction/companyInfo/bg_greeting_section.jpg";

const Container = styled.div`
  & section:not(:last-of-type) {
    margin-bottom: 100px;
  }

  & .sectionTitle {
    & .color {
      margin-bottom: 20px;
      font-weight: 600;
      color: var(--main);
    }

    & .sectionDescription {
      margin-bottom: 30px;
      font-size: 36px;
      font-weight: bold;
      line-height: 1.38;
    }
  }

  & .overviewSection {
    & .overview {
      display: flex;
      margin-bottom: 50px;

      & .overviewList {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(100% / 6);

        & .icon {
          width: 50px;
          height: 50px;
          margin-bottom: 20px;
        }

        & .listTitle {
          margin-bottom: 20px;
          font-size: 16px;
          font-weight: bold;
          color: var(--main);
        }

        & .listDescription {
          font-size: 16px;
          text-align: center;
          line-height: 1.5;
        }
      }
    }

    & .threeQ {
      display: flex;
      justify-content: center;
      gap: 126px;
      height: 420px;
      padding-top: 60px;
      background: url(${BgOverview}) no-repeat center / cover;

      & .threeQList {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 230px;
        height: 230px;
        color: #fff;
        border-radius: 50%;
        background-color: var(--main);

        &:not(:last-child)::after {
          position: absolute;
          top: 50%;
          right: -66px;
          transform: translateY(-50%);
          width: 6px;
          height: 6px;
          background-color: #fff;
          border-radius: 50%;
          content: "";
        }

        & .icon {
          width: 50px;
          height: 50px;
          margin-bottom: 20px;
        }

        & .listTitle {
          margin-bottom: 10px;
          font-size: 24px;
          font-weight: 900;
        }

        & .listDescription {
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }

  & .historySection {
    & .sectionDescription {
      margin-bottom: 50px;
    }

    & .history {
      display: flex;
      flex-wrap: wrap;
      gap: 50px;

      & .historyList {
        width: calc(calc(100% - 100px) / 3);

        & .tag {
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;
          min-width: 120px;
          height: 33px;
          padding: 0 39px;
          margin-bottom: 5px;
          font-size: 16px;
          font-weight: 800;
          color: #fff;
          background-color: var(--gray02);
          border-radius: 500px;
        }

        & .year {
          margin-bottom: 30px;
          font-size: 68px;
          font-weight: 800;
          color: var(--gray02);
        }

        & .listTitle {
          margin-bottom: 20px;
          font-size: 24px;
          font-weight: bold;
        }

        & ul {
          line-height: 1.6;
          white-space: pre-wrap;
        }
      }
    }
  }

  & .organizationSection {
    & .description {
      margin-bottom: 50px;
      line-height: 1.6;
    }

    & .img {
      height: 493px;
    }
  }

  & .greetingSection {
    height: 980px;
    padding: 100px 80px;
    background: url(${BgGreeting}) no-repeat bottom right / 923px 471px #f8f8f9;
    border-radius: 20px;

    & .description {
      margin-bottom: 58px;
      font-size: 16px;
      line-height: 1.5;
    }

    & .sign {
      font-size: 16px;
      font-weight: 600;

      & span {
        margin-left: 8px;
        font-family: "KimjungchulScript-Light";
        font-size: 36px;
      }
    }
  }

  & .patentSection {
    & .tab {
      margin-bottom: 50px;
      border-bottom: 1px solid var(--gray02);

      & ul {
        display: flex;
        gap: 30px;
        padding-bottom: 15px;

        & .tabList {
          position: relative;
          font-weight: 600;
          cursor: pointer;

          &.on {
            color: var(--main);

            &::after {
              position: absolute;
              bottom: -16px;
              left: 0;
              width: 100%;
              height: 3px;
              background-color: var(--main);
              content: "";
            }
          }
        }
      }
    }

    & .patent {
      display: flex;
      flex-wrap: wrap;
      gap: 50px 42px;

      & .patentList {
        width: calc(calc(100% - 126px) / 4);

        & .img {
          height: 370px;
          margin-bottom: 20px;
          background-color: var(--gray03);
        }

        & .listTitle {
          margin-bottom: 5px;
          font-weight: bold;
          text-align: center;
        }

        & .number {
          font-size: 16px;
          font-weight: 600;
          color: var(--gray01);
          text-align: center;
        }
      }
    }
  }

  @media (max-width: 1740px) {
    & section:not(:last-of-type) {
      margin-bottom: calc(100vw * (100 / 1740));
    }

    & .sectionTitle {
      & .color {
        margin-bottom: calc(100vw * (20 / 1740));
      }

      & .sectionDescription {
        margin-bottom: calc(100vw * (30 / 1740));
        font-size: calc(100vw * (36 / 1740));
      }
    }

    & .overviewSection {
      & .overview {
        margin-bottom: calc(100vw * (50 / 1740));

        & .overviewList {
          & .icon {
            width: calc(100vw * (50 / 1740));
            height: calc(100vw * (50 / 1740));
            margin-bottom: calc(100vw * (20 / 1740));
          }

          & .listTitle {
            margin-bottom: calc(100vw * (20 / 1740));
            font-size: calc(100vw * (16 / 1740));
          }

          & .listDescription {
            font-size: calc(100vw * (16 / 1740));
          }
        }
      }

      & .threeQ {
        gap: calc(100vw * (126 / 1740));
        height: calc(100vw * (420 / 1740));
        padding-top: calc(100vw * (60 / 1740));

        & .threeQList {
          width: calc(100vw * (230 / 1740));
          height: calc(100vw * (230 / 1740));

          &:not(:last-child)::after {
            right: calc(100vw * (-66 / 1740));
            width: calc(100vw * (6 / 1740));
            height: calc(100vw * (6 / 1740));
          }

          & .icon {
            width: calc(100vw * (50 / 1740));
            height: calc(100vw * (50 / 1740));
            margin-bottom: calc(100vw * (20 / 1740));
          }

          & .listTitle {
            margin-bottom: calc(100vw * (10 / 1740));
            font-size: calc(100vw * (24 / 1740));
          }

          & .listDescription {
            font-size: calc(100vw * (16 / 1740));
          }
        }
      }
    }

    & .historySection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (50 / 1740));
      }

      & .history {
        gap: calc(100vw * (50 / 1740));

        & .historyList {
          width: calc(calc(100% - calc(100vw * (100 / 1740))) / 3);

          & .tag {
            min-width: calc(100vw * (120 / 1740));
            height: calc(100vw * (33 / 1740));
            padding: 0 calc(100vw * (39 / 1740));
            margin-bottom: calc(100vw * (5 / 1740));
            font-size: calc(100vw * (16 / 1740));
            border-radius: calc(100vw * (500 / 1740));
          }

          & .year {
            margin-bottom: calc(100vw * (30 / 1740));
            font-size: calc(100vw * (68 / 1740));
          }

          & .listTitle {
            margin-bottom: calc(100vw * (20 / 1740));
            font-size: calc(100vw * (24 / 1740));
          }
        }
      }
    }

    & .organizationSection {
      & .description {
        margin-bottom: calc(100vw * (50 / 1740));
      }

      & .img {
        height: calc(100vw * (493 / 1740));
      }
    }

    & .greetingSection {
      height: calc(100vw * (980 / 1740));
      padding: calc(100vw * (100 / 1740)) calc(100vw * (80 / 1740));
      background: url(${BgGreeting}) no-repeat bottom right /
        calc(100vw * (923 / 1740)) calc(100vw * (471 / 1740)) #f8f8f9;
      border-radius: calc(100vw * (20 / 1740));

      & .description {
        margin-bottom: calc(100vw * (58 / 1740));
        font-size: calc(100vw * (16 / 1740));
      }

      & .sign {
        font-size: calc(100vw * (16 / 1740));

        & span {
          margin-left: calc(100vw * (8 / 1740));
          font-size: calc(100vw * (36 / 1740));
        }
      }
    }

    & .patentSection {
      & .tab {
        margin-bottom: calc(100vw * (50 / 1740));

        & ul {
          gap: calc(100vw * (30 / 1740));
          padding-bottom: calc(100vw * (15 / 1740));

          & .tabList {
            &.on {
              &::after {
                bottom: calc(100vw * (-16 / 1740));
                height: calc(100vw * (3 / 1740));
              }
            }
          }
        }
      }

      & .patent {
        gap: calc(100vw * (50 / 1740)) calc(100vw * (42 / 1740));

        & .patentList {
          width: calc(calc(100% - calc(100vw * (126 / 1740))) / 4);

          & .img {
            height: calc(100vw * (370 / 1740));
            margin-bottom: calc(100vw * (20 / 1740));
          }

          & .listTitle {
            margin-bottom: calc(100vw * (5 / 1740));
          }

          & .number {
            font-size: calc(100vw * (16 / 1740));
          }
        }
      }
    }
  }



  @media (max-width: 768px) {
    & section:not(:last-of-type) {
      margin-bottom: calc(100vw * (80 / 375));
    }

    & .sectionTitle {
      & .color {
        margin-bottom: calc(100vw * (10 / 375));
      }

      & .sectionDescription {
        margin-bottom: calc(100vw * (20 / 375));
        font-size: calc(100vw * (24 / 375));
      }
    }

    & .scroll-container {
      margin-bottom: calc(100vw * (20 / 375));
    }

    & .overviewSection {
      & .overview {
        flex-wrap: wrap;
        gap: calc(100vw * (30 / 375)) 0;
        margin-bottom: calc(100vw * (50 / 375));

        & .overviewList {
          width: 50%;

          & .icon {
            width: calc(100vw * (40 / 375));
            height: calc(100vw * (40 / 375));
            margin-bottom: calc(100vw * (10 / 375));
          }

          & .listTitle {
            margin-bottom: calc(100vw * (10 / 375));
            font-size: calc(100vw * (14 / 375));
          }

          & .listDescription {
            font-size: calc(100vw * (14 / 375));
          }
        }
      }

      & .threeQ {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: calc(100vw * (46 / 375));
        height: calc(100vw * (860 / 375));
        padding-top: calc(100vw * (49 / 375));
        background-image: url(${BgOverviewMb});

        & .threeQList {
          width: calc(100vw * (200 / 375));
          height: calc(100vw * (200 / 375));

          &:not(:last-child)::after {
            top: auto;
            bottom: calc(100vw * (-26 / 375));
            left: 50%;
            right: auto;
            transform: translateX(-50%);
            width: calc(100vw * (6 / 375));
            height: calc(100vw * (6 / 375));
          }

          & .icon {
            width: calc(100vw * (50 / 375));
            height: calc(100vw * (50 / 375));
            margin-bottom: calc(100vw * (10 / 375));
          }

          & .listTitle {
            margin-bottom: calc(100vw * (10 / 375));
            font-size: calc(100vw * (18 / 375));
          }

          & .listDescription {
            font-size: calc(100vw * (14 / 375));
          }
        }
      }
    }

    & .historySection {
      & .sectionDescription {
        margin-bottom: calc(100vw * (30 / 375));
      }

      & .history {
        flex-direction: column;
        gap: calc(100vw * (20 / 375));

        & .historyList {
          width: 100%;

          & .tag {
            min-width: calc(100vw * (110 / 375));
            height: calc(100vw * (30 / 375));
            padding: 0 calc(100vw * (36 / 375));
            margin-bottom: calc(100vw * (5 / 375));
            font-size: calc(100vw * (14 / 375));
            border-radius: calc(100vw * (500 / 375));
          }

          & .year {
            margin-bottom: calc(100vw * (10 / 375));
            font-size: calc(100vw * (46 / 375));
          }

          & .listTitle {
            margin-bottom: calc(100vw * (20 / 375));
            font-size: calc(100vw * (18 / 375));
          }
        }
      }
    }

    & .organizationSection {
      & .description {
        margin-bottom: calc(100vw * (30 / 375));
        word-break: keep-all;
      }

      & .img {
        width: calc(100vw * (1328 / 375));
        height: calc(100vw * (493 / 375));
      }
    }

    & .greetingSection {
      height: calc(100vw * (845 / 375));
      padding: calc(100vw * (50 / 375)) calc(100vw * (20 / 375));
      background: url(${BgGreeting}) no-repeat bottom right / 100%
        calc(100vw * (171 / 375)) #f8f8f9;
      border-radius: calc(100vw * (20 / 375));

      & .description {
        margin-bottom: calc(100vw * (30 / 375));
        font-size: calc(100vw * (14 / 375));
        line-height: 1.43;
        word-break: keep-all;
      }

      & .sign {
        font-size: calc(100vw * (14 / 375));

        & span {
          margin-left: calc(100vw * (8 / 375));
          font-size: calc(100vw * (24 / 375));
        }
      }
    }

    & .patentSection {
      & .tab {
        margin-bottom: calc(100vw * (50 / 375));

        & ul {
          gap: calc(100vw * (25 / 375));
          padding-bottom: calc(100vw * (10 / 375));

          & .tabList {
            flex-shrink: 0;

            &.on {
              &::after {
                bottom: calc(100vw * (-11 / 375));
                height: calc(100vw * (3 / 375));
              }
            }
          }
        }
      }

      & .patent {
        gap: calc(100vw * (20 / 375)) calc(100vw * (19 / 375));

        & .patentList {
          width: calc(calc(100% - calc(100vw * (19 / 375))) / 2);

          & .img {
            height: calc(100vw * (194 / 375));
            margin-bottom: calc(100vw * (20 / 375));
          }

          & .listTitle {
            margin-bottom: calc(100vw * (5 / 375));
          }

          & .number {
            font-size: calc(100vw * (14 / 375));
          }
        }
      }
    }


    @media (max-width: 450px) {
      & .organizationSection {

      & .img {
        width: calc(100vw * (1024 / 375));
        height: calc(100vw * (380.1 / 375));
      }
    }
  }
  }
`;

export { Container };

export default { Container };
