import styled from "styled-components";

const Container = styled.div`
  & .agencySection {
    margin-top: 80px;

    & .img {
      height: 350px;
      margin-bottom: 50px;
    }

    & .titleContainer,
    .agencyListContainer {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      & .left {
        font-size: 36px;
        font-weight: bold;
      }

      & .right {
        width: 1010px;
      }
    }

    & .titleContainer {
      margin-bottom: 80px;
    }

    & .description {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.33;
    }

    & .agency01 {
      margin-bottom: 100px;
    }

    & .agency02 {
      margin-bottom: 80px;
    }

    & .agencyListWrap {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;

      & .agencyList {
        display: flex;
        flex-direction: column;
        width: 490px;
        height: 270px;
        padding: 30px;
        font-size: 16px;
        border: 1px solid var(--gray02);

        & .listTitle {
          color: var(--main);
          letter-spacing: normal;
        }

        & .companyName {
          margin: auto 0 10px;
          font-size: 24px;
          font-weight: bold;
          word-break: break-all;
        }

        & .detail {
          color: var(--gray01);
          white-space: pre-wrap;
        }
      }
    }

    & .mapImg {
      height: 587px;
    }
  }

  @media (max-width: 1740px) {
    & .agencySection {
      margin-top: calc(100vw * (80 / 1740));

      & .img {
        height: calc(100vw * (350 / 1740));
        margin-bottom: calc(100vw * (50 / 1740));
      }

      & .titleContainer,
      .agencyListContainer {
        & .left {
          font-size: calc(100vw * (36 / 1740));
        }

        & .right {
          width: calc(100vw * (1010 / 1740));
        }
      }

      & .titleContainer {
        margin-bottom: calc(100vw * (80 / 1740));
      }

      & .description {
        font-size: calc(100vw * (24 / 1740));
      }

      & .agency01 {
        margin-bottom: calc(100vw * (100 / 1740));
      }

      & .agency02 {
        margin-bottom: calc(100vw * (80 / 1740));
      }

      & .agencyListWrap {
        gap: calc(100vw * (30 / 1740));

        & .agencyList {
          width: calc(100vw * (490 / 1740));
          height: calc(100vw * (270 / 1740));
          padding: calc(100vw * (30 / 1740));
          font-size: calc(100vw * (16 / 1740));

          & .companyName {
            margin: auto 0 calc(100vw * (10 / 1740));
            font-size: calc(100vw * (24 / 1740));
          }
        }
      }

      & .mapImg {
        height: calc(100vw * (587 / 1740));
      }
    }
  }

  @media (max-width: 768px) {
    word-break: keep-all;

    & .agencySection {
      margin-top: calc(100vw * (45 / 375));

      & .img {
        height: calc(100vw * (88 / 375));
        margin-bottom: calc(100vw * (30 / 375));
      }

      & .titleContainer,
      .agencyListContainer {
        flex-direction: column;
        gap: calc(100vw * (20 / 375));

        & .left {
          font-size: calc(100vw * (24 / 375));
        }

        & .right {
          width: 100%;
        }
      }

      & .titleContainer {
        margin-bottom: calc(100vw * (50 / 375));
      }

      & .description {
        font-size: calc(100vw * (18 / 375));
      }

      & .agency01 {
        margin-bottom: calc(100vw * (80 / 375));
      }

      & .agency02 {
        margin-bottom: calc(100vw * (50 / 375));
      }

      & .agencyListWrap {
        flex-direction: column;
        gap: calc(100vw * (20 / 375));

        & .agencyList {
          width: 100%;
          height: calc(100vw * (194 / 375));
          padding: calc(100vw * (10 / 375));
          font-size: calc(100vw * (14 / 375));

          & .companyName {
            margin: auto 0 calc(100vw * (10 / 375));
            font-size: calc(100vw * (18 / 375));
          }
        }
      }

      & .mapImg {
        width: calc(100vw * (793 / 375));
        height: calc(100vw * (350 / 375));
        margin-bottom: calc(100vw * (20 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
