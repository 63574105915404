import styled from "styled-components";

// img
import IconDisabeld from "resources/image/icon/icon_disabled.svg";

const Container = styled.div`
  position: relative;

  & .inputLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;

    & .essential {
      color: var(--main);
    }

    & .guideText {
      font-weight: 500;
      color: var(--gray01);
    }
  }

  & input {
    width: 100%;
    height: 60px;
    padding: 0 20px;
    border: 1px solid var(--gray01);

    &:disabled {
      background: url(${IconDisabeld}) no-repeat calc(100% - 20px) center / 26px
        var(--gray03);
      border: 0;
    }
  }

  & .eyeButton {
    position: absolute;
    right: 20px;
    bottom: 17px;
    width: 26px;
    height: 26px;
  }

  & .errorText {
    position: absolute;
    top: calc(100% + 5px);
    font-size: 16px;
    color: var(--red);
  }

  @media (max-width: 1740px) {
    & .inputLabel {
      margin-bottom: calc(100vw * (5 / 1740));
      font-size: calc(100vw * (16 / 1740));
    }

    & input {
      height: calc(100vw * (60 / 1740));
      padding: 0 calc(100vw * (20 / 1740));

      &:disabled {
        background: url(${IconDisabeld}) no-repeat
          calc(100% - calc(100vw * (20 / 1740))) center /
          calc(100vw * (26 / 1740)) var(--gray03);
      }
    }

    & .eyeButton {
      right: calc(100vw * (20 / 1740));
      bottom: calc(100vw * (17 / 1740));
      width: calc(100vw * (26 / 1740));
      height: calc(100vw * (26 / 1740));
    }

    & .errorText {
      top: calc(100% + calc(100vw * (5 / 1740)));
      font-size: calc(100vw * (16 / 1740));
    }
  }

  @media (max-width: 768px) {
    & .inputLabel {
      margin-bottom: calc(100vw * (5 / 375));
      font-size: calc(100vw * (14 / 375));
    }

    & input {
      height: calc(100vw * (60 / 375));
      padding: 0 calc(100vw * (10 / 375));

      &:disabled {
        background: url(${IconDisabeld}) no-repeat
          calc(100% - calc(100vw * (20 / 375))) center /
          calc(100vw * (26 / 375)) var(--gray03);
      }
    }

    & .eyeButton {
      right: calc(100vw * (10 / 375));
      bottom: calc(100vw * (17 / 375));
      width: calc(100vw * (26 / 375));
      height: calc(100vw * (26 / 375));
    }

    & .errorText {
      top: calc(100% + calc(100vw * (5 / 375)));
      font-size: calc(100vw * (14 / 375));
    }
  }
`;

export { Container };

export default {
  Container,
};
