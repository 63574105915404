import { useQuery } from "react-query";
import { api } from "service";

export default function useHistory() {

  const getHistory = useQuery(["history"], () => {
    return api.history.getHistory();
  }, {
    enabled: !!true,
    refetchOnWindowFocus: false,
  });


	return {
		getHistory,
	};
}