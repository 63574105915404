import Styles from "./styles";

function Banner({ data }) {

  const isMain = true;
  const { title, img } = data;

  return (
    <Styles.Container
      style={{
        backgroundImage: `url(${img})`,
      }}
      isMain={isMain}
    >
      {title}
    </Styles.Container>
  );
}

export default Banner;
