import useModals from "hooks/useModals";
import useLanguage from "hooks/useLanguage";
import { api } from "service";
import { DOMAIN } from "service/connector";
import { useAtom } from "jotai";
import { authAtom } from "store/atoms";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

export default function useAuth() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const modalOption = useModals();
  const navigate = useNavigate();

  const [userData, setUserData] = useAtom(authAtom.userInfo);
  const isLogin = (userData.username == "" || userData.username == null) ? false : true;

  const userQuery = useQuery("userData", api.auth.getUser, {
    onSuccess: (e) => {
      if (e != null) {
        if (window.location.pathname.includes("login")) navigate("/");
        setUserData(e);
      }
    },
    onError: error => console.log(error),
    refetchOnWindowFocus: false,
  });

  const certification = () => {
    if (!isLogin) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.text = (languageQuarter ? 
          <>
            회원가입 후 사용해 주세요.<br />
            권한이 있는 일부 회원만 사용 가능합니다.
          </> : <>
            Only registered member can use it <br />
            Please log in after signing up
          </>
        );
        e.buttonText = languageQuarter ? "회원가입" : "Join membership";
        e.confirmButtonClick = () => {
          navigate("/signup");
        };
        e.closeButton = true;

        return { ...e };
      });
      return false;
    }
    return true;
  }

  const logout = async () => {
    setUserData(authAtom.userInfo);
    document.cookie = `jwt=;path=/;domain=${DOMAIN};max-age:-1`;
  }

  // 회원가입 페이지
  const emailRegist = async (object) => {
    const result = await api.auth.emailRegist(object);
    result.success && navigate("/confirm", { state: true });
  }

  // 로그인 페이지
  const login = async (password, username, setErrorText) => {
    const postData = new FormData();
    postData.append("flag", true);
    postData.append("password", password);
    postData.append("username", username);

    const result = await api.auth.login(postData)
    if (result.success) {
      document.cookie = `jwt=${result.data};path=/;domain=${DOMAIN};max-age=4377600` //7600*24*24
      userQuery.refetch();
      return true;
    }
    else if (result.data == 1) {
      setErrorText(e => {
        return { ...e,
          id: languageQuarter
            ? "아이디가 일치하지 않습니다."
            : "The ID does not match."
        }
      })
    }
    else if (result.data == 2) {
      setErrorText(e => {
        return { ...e,
          password: languageQuarter
            ? "비밀번호가 일치하지 않습니다."
            : "Please agree to the terms and conditions."
        }
      })
    }
  }

  const findId = async (data) => {
    return await api.auth.findId(data);
  };
  const passwordReset = async (data) => {
    return await api.auth.passwordReset(data);
  };
  const signUp = async (postData) => {
    const result = await api.auth.signUp(postData);
    if (result?.success) {
      navigate("/");
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.closeButton = false;
        e.text = (languageQuarter ?
          <>
            이메일로 인증메일이 발송되었습니다. <br />
            인증 확인 후 가입이 완료됩니다.
          </> : <>
            A certified email has been sent to you. <br />
            Your subscription will be completed after verification.
          </>
        );
        e.buttonText = languageQuarter ? "확인" : "Check";
        e.confirmButtonClick = () => {
          navigate("/");
        };

        return { ...e };
      });
    }
    else if (!result?.success) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.closeButton = false;
        e.text = (languageQuarter ?
          <>
            이미 가입된 회원입니다.
          </> : <>
            You are already a registered member.
          </>
        );
        e.buttonText = languageQuarter ? "확인" : "Check";
        e.confirmButtonClick = () => {
          navigate("/login");
        };

        return { ...e };
      });
    }
  }

  // 마이 페이지
  const changePassword = async (nowPassword, newPassword, setErrorText) => {
    const postData = new FormData();
    postData.append("old_password", nowPassword);
    postData.append("new_password", newPassword);
    
    const result = await api.auth.changePassword(postData);
    if (result?.success) {
      logout();
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.closeButton = false;
        e.text = (languageQuarter ? 
          <>
            비밀번호가 변경되었습니다. <br />
            새로운 비밀번호로 재로그인해주세요.
          </> : <>
            The password has been changed. <br />
            Please log back in with the new password.
          </>
        );
        e.buttonText = languageQuarter ? "로그인 하기" : "Login";
        e.confirmButtonClick = () => {
          navigate("/login");
        };
        
        document.cookie = `jwt=;path=/;domain=${DOMAIN};max-age:-1`;
        return { ...e };
      });
    }
    else if (result.data == 1) { // 기존 패스워드와 동일한 경우
      setErrorText(e => {
        return { ...e,
          password: languageQuarter
            ? "비밀번호가 일치하지 않습니다."
            : "Please agree to the terms and conditions."
        }
      })
    }
    else {
      setErrorText(e => {
        return { ...e,
          password: languageQuarter
            ? "비밀번호가 일치하지 않습니다."
            : "Please agree to the terms and conditions."
        }
      })
    };
  }

  const logoutModal = async () => {
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.text = languageQuarter ? "로그아웃 하시겠습니까?" : "Do you want to log out?";
      e.buttonText = languageQuarter ? "로그아웃" : "Logout";
      e.closeButton = true;
      e.confirmButtonClick = () => {
        logout();
        modalOption.setDefaultModal((e) => {
          e.show = true;
          e.text = languageQuarter ? "로그아웃 되었습니다." : "You have been logged out.";
          e.buttonText = languageQuarter ? "확인" : "Check";
          e.closeButton = false;
          e.confirmButtonClick = () => {
            navigate("/");
          };
          return { ...e };
        });
      };
      return { ...e };
    });
  };

  const resign = async () => {
    modalOption.setDefaultModal((e) => {
      e.show = true;
      e.text = languageQuarter ? "정말 탈퇴 신청 하시겠습니까?" : "Are you sure you want to apply for withdrawal?";
      e.buttonText = languageQuarter ? "탈퇴" : "Withdrawal";
      e.closeButton = true;
      e.confirmButtonClick = async () => {
        const result = await api.auth.resign("탈퇴하겠습니다.");
        if (result.success) {
          logout();
          modalOption.setDefaultModal((e) => {
            e.show = true;
            e.text = languageQuarter ? "탈퇴가 완료되었습니다." : "Your withdrawal has been completed.";
            e.buttonText = languageQuarter ? "확인" : "Check";
            e.closeButton = false;
            e.confirmButtonClick = () => {
              navigate("/");
            };
            return { ...e };
          });
        };
      }
      return { ...e };
    });
  }


  return {
    isLogin,
    userData, setUserData,
    isLoad: userQuery.isLoading,
    
    certification,
    
    // 회원가입 페이지
    emailRegist,
    
    // 로그인 페이지
    login,
    findId,
    passwordReset,
    signUp,

    // 마이 페이지
    changePassword,
    logoutModal,
    resign,
  }
}