import styled from "styled-components";

const Container = styled.div`
  margin-bottom: 50px;

  & h2 {
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: 600;
    color: var(--main);
  }

  & .mainTitle {
    margin-bottom: 30px;
    font-size: 36px;
    font-weight: bold;
    line-height: 1.38;
  }

  & .mainDescription {
    line-height: 1.6;
    word-break: keep-all;
  }

  @media (max-width: 1740px) {
    margin-bottom: calc(100vw * (50 / 1740));

    & h2 {
      margin-bottom: calc(100vw * (20 / 1740));
      font-size: calc(100vw * (26 / 1740));
    }

    & .mainTitle {
      margin-bottom: calc(100vw * (30 / 1740));
      font-size: calc(100vw * (36 / 1740));
    }
  }

  @media (max-width: 768px) {
    margin-bottom: calc(100vw * (30 / 375));
    word-break: keep-all;

    & h2 {
      margin-bottom: calc(100vw * (10 / 375));
      font-size: calc(100vw * (20 / 375));
    }

    & .mainTitle {
      margin-bottom: calc(100vw * (20 / 375));
      font-size: calc(100vw * (24 / 375));
    }

    & .mainDescription {
      line-height: 1.5;
    }
  }
`;

export { Container };

export default { Container };
