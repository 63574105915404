import styled from "styled-components";

const Container = styled.div`
  padding-top: 30px;

  & .inputWrap {
    margin-bottom: 50px;

    & .inputList:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  & .findButton {
    position: relative;

    & .errorText {
      position: absolute;
      bottom: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
      font-size: 16px;
      color: var(--red);
    }
  }

  @media (max-width: 1740px) {
    padding-top: calc(100vw * (30 / 1740));

    & .inputWrap {
      margin-bottom: calc(100vw * (50 / 1740));

      & .inputList:not(:last-child) {
        margin-bottom: calc(100vw * (40 / 1740));
      }
    }

    & .findButton {
      & .errorText {
        bottom: calc(100% + calc(100vw * (10 / 1740)));
        font-size: calc(100vw * (16 / 1740));
      }
    }
  }

  @media (max-width: 768px) {
    padding-top: calc(100vw * (20 / 375));

    & .inputWrap {
      margin-bottom: calc(100vw * (50 / 375));

      & .inputList:not(:last-child) {
        margin-bottom: calc(100vw * (30 / 375));
      }
    }

    & .findButton {
      width: calc(100vw * (250 / 375));
      margin: 0 auto;

      & .errorText {
        bottom: calc(100% + calc(100vw * (10 / 375)));
        width: max-content;
        font-size: calc(100vw * (14 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
