import Styles from "./styles";
import ui from "components/ui";

// img
import IconClose from "resources/image/icon/icon_close.svg";

function DefaultModal({ modalOption, modalClose }) {
  return (
    <Styles.Container>
      <div className="modalInner">
        {modalOption.closeButton && (
          <button
            type="button"
            className="closeButton"
            onClick={() => {
              modalClose();
            }}
          >
            <img src={IconClose} alt="IconClose" />
          </button>
        )}

        <p className="modalText">{modalOption.text}</p>

        <div className="confirmButton">
          <ui.button.BasicButton
            buttonText={modalOption.buttonText}
            onClick={() => {
              modalClose();
              {
                modalOption.confirmButtonClick &&
                  modalOption.confirmButtonClick();
              }
            }}
          />
        </div>
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { DefaultModal };

export default DefaultModal;
