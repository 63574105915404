import Styles from "./styles";
import { useLanguage } from "hooks";

function Stock() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  return (
    <Styles.Container>
      <h2 className="title">{languageQuarter ? "주가 정보" : "Stock price information"}</h2>

      <div className="chart">
        <iframe
          className="disclosureInfo"
          src={"https://totalstock.irplus.co.kr/StockService/page/019990_enertork_3352/dailystock.php?l=" + (languageQuarter ? "K" : "E")}
          width="100%"
          height="2100px"
        ></iframe>


      </div>
    </Styles.Container>
  );
}

export default Stock;
