import styled from "styled-components";

import IconCheckDisabled from "resources/image/icon/icon_check_circle_disabled.svg";
import IconCheckOff from "resources/image/icon/icon_check_circle_off.svg";
import IconCheckOn from "resources/image/icon/icon_check_circle_on.svg";

const Input = styled.input`
  display: none;

  &:checked + label {
    border-color: var(--black);

    & .checkbox {
      background-image: url(${IconCheckOn});
    }
  }

  &:disabled + label {
    color: var(--gray01);

    & .checkbox {
      background-image: url(${IconCheckDisabled});
    }
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid var(--gray01);
  cursor: pointer;

  & .checkbox {
    flex-shrink: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background: url(${IconCheckOff}) no-repeat center / cover;
  }

  @media (max-width: 1740px) {
    padding: calc(100vw * (10 / 1740)) calc(100vw * (30 / 1740));
    font-size: calc(100vw * (16 / 1740));

    & .checkbox {
      width: calc(100vw * (20 / 1740));
      height: calc(100vw * (20 / 1740));
      margin-right: calc(100vw * (10 / 1740));
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (10 / 375)) calc(100vw * (20 / 375));
    font-size: calc(100vw * (16 / 375));

    & .checkbox {
      width: calc(100vw * (20 / 375));
      height: calc(100vw * (20 / 375));
      margin-right: calc(100vw * (10 / 375));
    }
  }
`;

export { Input, Label };

export default { Input, Label };
