import styled from "styled-components";

const Container = styled.div`
  padding: 80px 0 200px;
  min-height: 100vh;

  & section {
    padding-top: 80px;

    & .logo {
      width: 262px;
      height: 76px;
      margin: 0 auto 20px;
    }

    & h2 {
      margin-bottom: 80px;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
    }

    & .icon {
      width: 148px;
      height: 148px;
      margin: 0 auto 50px;
    }

    & p {
      margin-bottom: 50px;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.41;
      text-align: center;

      & span {
        color: var(--main);
      }
    }
  }

  @media (max-width: 1740px) {
    padding: calc(100vw * (80 / 1740)) 0 calc(100vw * (200 / 1740));

    & section {
      padding-top: calc(100vw * (80 / 1740));

      & .logo {
        width: calc(100vw * (262 / 1740));
        height: calc(100vw * (76 / 1740));
        margin: 0 auto calc(100vw * (20 / 1740));
      }

      & h2 {
        margin-bottom: calc(100vw * (80 / 1740));
        font-size: calc(100vw * (24 / 1740));
      }

      & .icon {
        width: calc(100vw * (148 / 1740));
        height: calc(100vw * (148 / 1740));
        margin: 0 auto calc(100vw * (50 / 1740));
      }

      & p {
        margin-bottom: calc(100vw * (50 / 1740));
        font-size: calc(100vw * (24 / 1740));
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (50 / 375)) 0 calc(100vw * (150 / 375));

    & section {
      padding-top: calc(100vw * (50 / 375));

      & .logo {
        width: calc(100vw * (158 / 375));
        height: calc(100vw * (46 / 375));
        margin: 0 auto calc(100vw * (20 / 375));
      }

      & h2 {
        margin-bottom: calc(100vw * (50 / 375));
        font-size: calc(100vw * (18 / 375));
      }

      & .icon {
        width: calc(100vw * (108 / 375));
        height: calc(100vw * (108 / 375));
        margin: 0 auto calc(100vw * (50 / 375));
      }

      & p {
        margin-bottom: calc(100vw * (50 / 375));
        font-size: calc(100vw * (18 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
