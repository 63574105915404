import { useLanguage } from "hooks";
import Styles from "./styles";

// img
import IconEyeOn from "resources/image/icon/icon_eye_on.svg";
import IconEyeOff from "resources/image/icon/icon_eye_off.svg";

function BasicInput({ data, onChange, onKeyUp, isShow, setIsShow }) {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const {
    id,
    labelText,
    guideText,
    essential,
    type,
    name,
    value,
    placeholder,
    errorText,
    disabled,
  } = data;

  return (
    <Styles.Container>
      {labelText && (
        <div className="inputLabel">
          <label htmlFor={id ? id : name}>
            {essential && (
              <span className="essential">
                [{languageQuarter ? "필수" : "Required"}]
              </span>
            )}
            {labelText}
          </label>

          {guideText && <div className="guideText">{guideText}</div>}
        </div>
      )}

      <input
        type={type ? type : "text"}
        id={id ? id : name}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        onKeyUp={(e) => {
          if (e.key == "Enter") {
            onKeyUp && onKeyUp();
          }
        }}
      />

      {(name === "password" || name === "confirmPassword") && (
        <button
          type="button"
          className="eyeButton"
          onClick={() => {
            setIsShow(!isShow);
          }}
        >
          <img src={isShow ? IconEyeOff : IconEyeOn} alt="IconEye" />
        </button>
      )}

      {errorText && <p className="errorText">{errorText}</p>}
    </Styles.Container>
  );
}

export default BasicInput;
