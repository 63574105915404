import Styles from "./styles";
import components from "./components";
import { useState } from "react";
import { useLanguage } from "hooks";

// img
import IconClose from "resources/image/icon/icon_close.svg";

function FindIdModal({ modalClose }) {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const [result, setResult] = useState("");

  return (
    <Styles.Container>
      <div className="modalInner">
        <button
          type="button"
          className="closeButton"
          onClick={() => {
            modalClose();
          }}
        >
          <img src={IconClose} alt="IconClose" />
        </button>

        <p className="title">{languageQuarter ? "아이디 찾기" : "Find your ID"}</p>

        {result ? (
          <components.FindIdResult
            result={result}
            modalClose={modalClose}
            language={languageQuarter}
          />
        ) : (
          <components.FindIdForm
            setResult={setResult}
            language={languageQuarter}
          />
        )}
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { FindIdModal };

export default FindIdModal;
