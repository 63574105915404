import styled from "styled-components";

const Container = styled.div`
  padding: 80px 0 200px;
  min-height: 100vh;

  & .narrowInner {
    margin: 0 auto;

    & .title {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      font-size: 36px;
      font-weight: 700;
      letter-spacing: normal;
      border-bottom: 3px solid var(--black);

      & .warningText {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-left: 25px;
        font-size: 16px;
        font-weight: 500;
        color: var(--gray01);

        & .icon {
          display: inline-block;
          width: 20px;
          height: 20px;
        }
      }

      & .select {
        width: 270px;
        margin-left: auto;
      }
    }

    & .table {
      font-weight: 600;

      & .thead {
        display: flex;
      }

      & .thead {
        padding: 20px 0;
        font-size: 16px;
        text-align: center;
        border-bottom: 1px solid var(--gray02);

        & .download {
          width: 300px;
        }

        & .number,
        .category {
          width: 100px;
        }

        & .listTitle {
          width: calc(100% - 500px);
        }
      }

      & .pagination {
        margin-top: 50px;
      }
    }

    & .goBackButton {
      width: 440px;
      margin: 50px auto 0;
    }
  }

  @media (max-width: 1740px) {
    padding: calc(100vw * (80 / 1740)) 0 calc(100vw * (200 / 1740));

    & .narrowInner {
      & .title {
        padding-bottom: calc(100vw * (20 / 1740));
        font-size: calc(100vw * (36 / 1740));
        border-bottom: calc(100vw * (3 / 1740)) solid var(--black);

        & .warningText {
          gap: calc(100vw * (5 / 1740));
          margin-left: calc(100vw * (25 / 1740));
          font-size: calc(100vw * (16 / 1740));

          & .icon {
            width: calc(100vw * (20 / 1740));
            height: calc(100vw * (20 / 1740));
          }
        }

        & .select {
          width: calc(100vw * (270 / 1740));
        }
      }

      & .table {
        & .thead {
          padding: calc(100vw * (20 / 1740)) 0;
          font-size: calc(100vw * (16 / 1740));

          & .download {
            width: calc(100vw * (300 / 1740));
          }

          & .number,
          .category {
            width: calc(100vw * (100 / 1740));
          }

          & .listTitle {
            width: calc(100% - calc(100vw * (500 / 1740)));
          }
        }

        & .pagination {
          margin-top: calc(100vw * (50 / 1740));
        }
      }

      & .goBackButton {
        width: calc(100vw * (440 / 1740));
        margin: calc(100vw * (50 / 1740)) auto 0;
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (50 / 375)) 0 calc(100vw * (150 / 375));

    & .narrowInner {
      padding-top: calc(100vw * (50 / 375));

      & .title {
        flex-direction: column;
        align-items: flex-start;
        gap: calc(100vw * (15 / 375));
        padding-bottom: calc(100vw * (20 / 375));
        font-size: calc(100vw * (24 / 375));
        border-bottom: calc(100vw * (3 / 375)) solid var(--black);

        & .warningText {
          gap: calc(100vw * (5 / 375));
          align-items: flex-start;
          margin-left: 0;
          font-size: calc(100vw * (14 / 375));

          & .icon {
            width: calc(100vw * (20 / 375));
            height: calc(100vw * (20 / 375));
          }
        }

        & .select {
          width: calc(100vw * (270 / 375));
          margin-left: 0;
        }
      }

      & .table {
        & .thead {
          display: none;
        }

        & .pagination {
          margin-top: calc(100vw * (50 / 375));
        }
      }

      & .goBackButton {
        width: 100%;
        margin: calc(100vw * (50 / 375)) auto 0;
      }
    }
  }
`;

export { Container };

export default { Container };
