import connector from "service/connector"


const getList = async () => {
  return await connector.connectFetchController("product/banner/list", "GET");
}
const getDetail = async (path) => {
  return await connector.connectFetchController("product/banner/detail=" + path, "GET");
}
const getYoutubeList = async () => {
  return await connector.connectFetchController("youtube/list", "GET");
}

export {
  getList,
  getDetail,
  getYoutubeList,
};


export default {
  getList,
  getDetail,
  getYoutubeList,
}