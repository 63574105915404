import connector from "service/connector";
import utils from "utils";


const getListText = async (filter) => {
  return await connector.authConnector("support/list/text" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const getOptionList = async (filter) => {
  return await connector.connectFetchController("support/option/list" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}
const getSerial = async (filter) => {
  return await connector.connectFetchController("support/serial" + (filter ? "?" + utils.etc.getFilterStr(filter) : ""), "GET");
}


export {
  getListText,
  getOptionList,
  getSerial,
};


export default {
  getListText,
  getOptionList,
  getSerial,
}