import product from "./product";
import dummy from "./dummy";
import patent from "./patent";
import history from "./history";
import auth from "./auth";
import morgan from "./morgan";
import support from "./support";
import search from "./search";
import banner from "./banner";
import agency from "./agency";
import term from "./term";
import invest from "./invest";
import main from "./main";

export default {
  product,
  dummy,
  patent,
  history,
  auth,
  morgan,
  support,
  search,
  banner,
  agency,
  term,
  invest,
  main,
};

export {
  product,
  dummy,
  patent,
  history,
  auth,
  morgan,
  support,
  search,
  banner,
  agency,
  term,
  invest,
  main,
};
