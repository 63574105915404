import { atom } from "jotai";

const modalFlagAtom = atom(false);
const defaultModalAtom = atom({ show: false, zIndex: 20 });
const findIdModalAtom = atom({ show: false, zIndex: 20 });
const findPwModalAtom = atom({ show: false, zIndex: 20 });
const optionModalAtom = atom({ show: false, zIndex: 20 });

export {
  modalFlagAtom,
  defaultModalAtom,
  findIdModalAtom,
  findPwModalAtom,
  optionModalAtom,
};

export default {
  modalFlagAtom,
  defaultModalAtom,
  findIdModalAtom,
  findPwModalAtom,
  optionModalAtom,
};
