import { useAuth } from "hooks";

const AccessRoute = ({ children }) => {
  let authInfo = useAuth();

  if (authInfo.isLoad === false) {
    if (authInfo.userData.access_yn === "Y") {
      return children;
    } else {
      window.location.replace("/");
      return <></>;
    }
  }
}

export default AccessRoute