import styled from "styled-components";

// img
import Bg from "resources/image/bg_side_menu.png";

const Container = styled.div`
  position: sticky;
  top: 160px;
  left: 0;
  width: 230px;
  height: fit-content;
  z-index: 1;

  & .title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    font-size: 26px;
    font-weight: bold;
    color: #fff;
    background: linear-gradient(124deg, #ea5504 35.34%, #ff8f0c 95.24%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;

    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 69px;
      background: url(${Bg}) no-repeat center / cover;
      content: "";
    }
  }

  & .pageName {
    display: none;
  }

  & .icon {
    width: 20px;
    height: 20px;
    margin-left: auto;
    filter: invert(24%) sepia(0%) saturate(1%) hue-rotate(173deg)
      brightness(94%) contrast(98%);

    &.on {
      transform: rotate(180deg);
    }
  }

  & .firstDepthList {
    font-weight: 600;
    border-bottom: 1px solid var(--gray01);

    & a {
      display: flex;
      align-items: center;
      height: 60px;
      cursor: pointer;
    }

    & > a {
      padding: 0 20px;
      font-size: 18px;
    }

    & .secondDepth {
      border-top: 1px solid var(--gray01);

      & .secondDepthList {
        &:not(:last-child) {
          border-bottom: 1px solid var(--gray01);
        }

        & a {
          padding: 0 35px;
          font-size: 16px;
          cursor: pointer;
        }

        & > a {
          background-color: #fafafa;
        }
      }
    }
  }

  @media (max-width: 1740px) {
    top: calc(100vw * (160 / 1740));
    width: calc(100vw * (230 / 1740));

    & .title {
      height: calc(100vw * (100 / 1740));
      font-size: calc(100vw * (26 / 1740));
      border-top-left-radius: calc(100vw * (10 / 1740));
      border-top-right-radius: calc(100vw * (10 / 1740));

      &::before {
        height: calc(100vw * (69 / 1740));
      }
    }

    & .icon {
      width: calc(100vw * (20 / 1740));
      height: calc(100vw * (20 / 1740));
    }

    & .firstDepthList {
      & a {
        height: calc(100vw * (60 / 1740));
      }

      & > a {
        padding: 0 calc(100vw * (20 / 1740));
        font-size: calc(100vw * (18 / 1740));
      }

      & .secondDepth {
        & .secondDepthList {
          & a {
            padding: 0 calc(100vw * (35 / 1740));
            font-size: calc(100vw * (16 / 1740));
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    top: calc(100vw * (50 / 375));
    width: 100%;

    &.on {
      & .pageName .icon {
        transform: rotate(180deg);
      }

      & .firstDepth {
        display: block;
      }
    }

    & .title {
      display: none;
    }

    & .pageName {
      display: flex;
      align-items: center;
      height: calc(100vw * (57 / 375));
      padding: 0 calc(100vw * (20 / 375));
      font-size: calc(100vw * (18 / 375));
      font-weight: 600;
      color: var(--main);
      background-color: #fff;
      border-bottom: 1px solid var(--gray01);
      cursor: pointer;
    }

    & .icon {
      width: calc(100vw * (20 / 375));
      height: calc(100vw * (20 / 375));
    }

    & .firstDepth {
      position: absolute;
      top: calc(100vw * (57 / 375));
      left: 0;
      display: none;
      width: 100%;
      max-height: calc(100vw * (300 / 375));
      overflow: auto;
      background-color: #fff;
      box-shadow: 0 calc(100vw * (15 / 375)) calc(100vw * (30 / 375)) 0
        rgba(0, 0, 0, 0.1);
    }

    & .firstDepthList {
      & a {
        height: calc(100vw * (60 / 375));
      }

      & > a {
        padding: 0 calc(100vw * (20 / 375));
        font-size: calc(100vw * (18 / 375));
      }

      & .secondDepth {
        & .secondDepthList {
          & a {
            padding: 0 calc(100vw * (30 / 375));
            font-size: calc(100vw * (16 / 375));
          }
        }
      }
    }
  }

  @media (hover: hover) {
    & .firstDepthList a:hover {
      color: #fff;
      background-color: var(--main) !important;

      & .icon {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(272deg)
          brightness(101%) contrast(103%);
      }
    }
  }
`;

export { Container };

export default { Container };
