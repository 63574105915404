import { useQuery } from "react-query";
import { api } from "service";

export default function useProduct({
  id,
  product,
  supportOption,
}) {

  const detailQuery = useQuery(["productDetail", id], () => {
    return api.product.getProductDetail(id);
  }, {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  const listQuery = useQuery(["productList"], () => {
    return api.product.getProductList();
  }, {
    enabled: !!product,
    refetchOnWindowFocus: false,
  });

  const supportQuery = useQuery(["productSupport", supportOption], () => {
    return api.product.getProductSupportList(supportOption);
  }, {
    enabled: !!supportOption,
    refetchOnWindowFocus: false,
  });


	return {
		detailQuery,
    listQuery,
    supportQuery,
	};
}