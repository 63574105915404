import Styles from "./styles";
import { Link } from "react-router-dom";
import { useLanguage } from "hooks";

// img
import Logo from "resources/image/icon/logo_white.svg";
import IconTopButton from "resources/image/icon/icon_top.svg";

function Footer({ main }) {
  const scrollTopClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  // map
  const infoList = languageQuarter ? [
    ["(주)에너토크", "대표이사 : 이충열"],
    [
      "본사 : 경기도 여주시 세종대왕면 능여로 344",
      "사업자등록번호 : 128-81-05641",
      "TEL : +82-31-880-2800",
      "FAX : +82-31-881-5860",
    ],
    [
      "서울사무소 : 서울시 강남구 언주로 430 윤익빌딩 18층",
      "TEL : +82-2-555-0883",
      "FAX : +82-2-556-0326",
    ],
    [
      "대구공장 : 대구시 달성군 구지면 국가산단대로28길 91",
      "사업자등록번호 : 148-85-02025",
    ],
    ["Copyright. 2024 Enertork Co., Ltd. All rights reserved."],
  ] : [
    ["ENERTORK LTD.", "CEO : Chung Yeol Lee"],
    [
      "HQ : 344, Neungyouro, Sejeongdaewangmyeon, Yeoju City, Kyunggy",
      "Business Registration Number : 128-81-05641",
      "TEL : +82-31-880-2800",
      "FAX : +82-31-881-5860",
    ],
    [
      "Seoul Office: 430, Eonju-ro, Gangnam-gu, Seoul",
      "TEL : +82-2-555-0883",
      "FAX : +82-2-556-0326",
    ],
    [
      "Daegu Factory : 91, Gukgasandan-daero 28-gil, Guji-myeon, Dalseong-gun, Daegu",
      "Business Registration Number : 148-85-02025",
    ],
    ["Copyright. 2024 Enertork Co., Ltd. All rights reserved."],
  ];

  return (
    <Styles.Container>
      <div className="inner">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>

        <ul className="info">
          {infoList.map((item, index) => {
            return (
              <li className="infoList" key={"infoList" + index}>
                {item.map((current, idx) => {
                  return <span key={"infoDetail" + idx}>{current}</span>;
                })}
              </li>
            );
          })}
        </ul>

        <Link to={"/terms/service"} className="termsLink">
          {languageQuarter ? "이용약관" : "Terms and Conditions"}
        </Link>
      </div>

      {!main && (
        <button
          type="button"
          className="topButton"
          onClick={() => {
            scrollTopClick();
          }}
        >
          <img src={IconTopButton} alt="IconTopButton" />
        </button>
      )}
    </Styles.Container>
  );
}

export default Footer;
