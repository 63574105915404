import utils from "utils";
import Styles from "./styles";
import { layout, ui } from "components";
import { highlightIgnoreCase } from "utils/etc";
import { useEffect, useState } from "react";
import { useLanguage, useSearch } from "hooks";
import { useOutletContext, Link, useLocation } from "react-router-dom";

function Result() {
  const location = useLocation();

  const { keyword } = useOutletContext();
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const [filter, setFilter] = useState({});
  const searchInfo = useSearch({ listOption: filter });
  const data = searchInfo.listQuery?.data || [];

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page || 1,
      keyword: param.keyword,
      recordSize: 15,
      pageSize: 5,
      language: languageQuarter ? "KOR" : "ENG"
    };
    setFilter({ ...f });
  }, [location]);

  const calculateContent = (text) => {

    let param = utils.etc.getSearchParam();
    let index = text.indexOf(param.keyword);
    let textLimit = 30;
    index = index - textLimit < 0 ? 0 : index - textLimit

    return text.slice(index ,text.length);
  }
  
  return (
    <Styles.Container>
      <h2>{languageQuarter ? "검색 결과" : "Search Results"}</h2>

      {data.list?.length > 0 ? (
        <>
          <ul className="listWrap">
            {data.list?.map((item, index) => {
              return (
                <li className="list" key={"list" + index}>
                  <Link to={item.url}>
                    <p className="depth">
                      <span>{item.type}</span> >{" "}
                      <span>{item["title" + languageInfo.suffix]}</span>
                    </p>

                    <p className="description">
                      {highlightIgnoreCase(calculateContent(item["description" + languageInfo.suffix] || ""), keyword)}
                    </p>
                  </Link>
                </li>
              );
            })}
          </ul>

          {data.pagination?.totalRecordCountAll > 15 && (
            <ui.button.Pagination
              page={data.pagination?.page}
              list={data.pagination?.page_list}
              maxPage={data.pagination?.totalPageCount}
            />
          )}
        </>
      ) : (
        <layout.EmptyContainer text={languageQuarter ? "검색 결과가 없습니다." : "No search results found."} search />
      )}
    </Styles.Container>
  );
}

export default Result;
