import connector from "service/connector";


const getPatent = async (data) => {
  return await connector.connectFetchController(`patent?type=${data}`, 'GET');
}

export {
  getPatent,
};


export default {
  getPatent,
}