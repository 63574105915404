import utils from "utils";
import Styles from "./styles";
import { ip } from "service/connector";
import { useAuth, useLanguage, useModals } from "hooks";

// img
import IconKor from "resources/image/icon/icon_kor.svg";
import IconEng from "resources/image/icon/icon_eng.svg";
import IconRus from "resources/image/icon/icon_rus.svg";
import IconAra from "resources/image/icon/icon_ara.svg";
import IconChi from "resources/image/icon/icon_chi.svg";
import IconVie from "resources/image/icon/icon_vie.svg";
import IconJpn from "resources/image/icon/icon_jpn.svg";
import IconInd from "resources/image/icon/icon_ind.svg";
import IconEpo from "resources/image/icon/icon_epo.svg";
import IconFre from "resources/image/icon/icon_fre.svg";
import IconEtc from "resources/image/icon/icon_etc.svg";
import IconCad from "resources/image/icon/icon_cad.svg";
import IconPdf from "resources/image/icon/icon_pdf.svg";
import IconGsd from "resources/image/icon/icon_gsd.svg";
import IconMov from "resources/image/icon/icon_mov.png";

function DownloadButtonList({ data, type }) {
  // const authInfo = useAuth();
  // const modalOption = useModals();
  // const languageInfo = useLanguage();
  // const languageQuarter = languageInfo.suffix === "";
  
  const doFtpDownload = (item) => {
    var anchor = document.createElement('a');
    anchor.setAttribute('href', ip + "ftpdownload?" + utils.etc.getFilterStr(item)); //path
    anchor.setAttribute('download', item.filename); //file name
    document.body.appendChild(anchor);
    anchor.click(); //<a> tag click
    anchor.parentNode.removeChild(anchor);
  }

  const renderIcon = (type) => {
    switch (type) {
      case "KOR":
        return <img src={IconKor} alt="IconKor" />;
      case "ENG":
        return <img src={IconEng} alt="IconEng" />;
      case "RUS":
        return <img src={IconRus} alt="IconRus" />;
      case "ARA":
        return <img src={IconAra} alt="IconAra" />;
      case "CHI":
        return <img src={IconChi} alt="IconChi" />;
      case "VIE":
        return <img src={IconVie} alt="IconVie" />;
      case "JPN":
        return <img src={IconJpn} alt="IconJpn" />;
      case "IND":
        return <img src={IconInd} alt="IconInd" />;
      case "EPO":
        return <img src={IconEpo} alt="IconEpo" />;
      case "FRE":
        return <img src={IconFre} alt="IconFre" />;
      case "ETC":
        return <img src={IconEtc} alt="IconEtc" />;
      case "CAD":
        return <img src={IconCad} alt="IconCad" />;
      case "PDF":
        return <img src={IconPdf} alt="IconPdf" />;
      case "GSD":
        return <img src={IconGsd} alt="IconGsd" />;
      case "MOV":
        return <img src={IconMov} alt="IconMov" />;
    }
  };

  // const cadNotModal = () => {
  //   modalOption.setDefaultModal((e) => {
  //     e.show = true;
  //     e.text = languageQuarter ? "다운 권한이 없습니다." : "You do not have permission to download."
  //     e.buttonText = languageQuarter ? "확인" : "Check";
  //     e.confirmButtonClick = false;
  //     e.closeButton = false;
      
  //     return { ...e };
  //   })
  // }

  const utf8ToBase64 = (str) => {
    return window.btoa(
      new Uint8Array(new TextEncoder().encode(str))
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
  }

  return (
    <Styles.Container className="download">
      {data?.map((item, index) => {
        if (type === 0) {
          return (
            <button
              key={"downloadList" + index}
              className="downloadList"
              onClick={() => {
                doFtpDownload({ filename: utf8ToBase64(item.title), url: utf8ToBase64(item.url) })
              }}
            >
              {renderIcon("PDF")}
            </button>
          )
        } else {
          return (
            <button
              className="downloadList"
              onClick={()=>{
                // if (item.icon === "CAD" && authInfo.userData.cad_yn === "N") {
                //   cadNotModal();
                //   return;
                // }
                window.location.href = ip + "s3/download?file_name=" + item.file_name + "&origin_file_name=" + item.origin_file_name
              }}
              download={item.origin_file_name}
              key={"downloadList" + index}
              target="_blank"
            >
              {renderIcon(item.icon)}
            </button>
          );
        }
      })}
    </Styles.Container>
  );
}

export default DownloadButtonList;
