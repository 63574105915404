import styled from "styled-components";

const Container = styled.div`
  padding-top: 50px;

  & .icon {
    width: 148px;
    height: 148px;
    margin: 0 auto 30px;
  }

  & p {
    margin-bottom: 119px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    line-height: 1.41;

    & span {
      color: var(--main);
    }
  }

  & .loginButton {
    margin: 0 auto;
  }

  @media (max-width: 1740px) {
    padding-top: calc(100vw * (50 / 1740));

    & .icon {
      width: calc(100vw * (148 / 1740));
      height: calc(100vw * (148 / 1740));
      margin: 0 auto calc(100vw * (30 / 1740));
    }

    & p {
      margin-bottom: calc(100vw * (119 / 1740));
      font-size: calc(100vw * (24 / 1740));
    }
  }

  @media (max-width: 768px) {
    padding-top: calc(100vw * (50 / 375));

    & .icon {
      width: calc(100vw * (108 / 375));
      height: calc(100vw * (108 / 375));
      margin: 0 auto calc(100vw * (20 / 375));
    }

    & p {
      margin-bottom: calc(100vw * (146 / 375));
      font-size: calc(100vw * (18 / 375));
      line-height: 1.44;
    }

    & .loginButton {
      width: calc(100vw * (250 / 375));
    }
  }
`;

export { Container };

export default { Container };
