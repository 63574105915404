import Header from "./Header";
import Footer from "./Footer";
import SideMenu from "./SideMenu";
import ProductSideMenu from "./ProductSideMenu";
import MorganHeader from "./MorganHeader";

export { Header, Footer, SideMenu, ProductSideMenu, MorganHeader };

export default {
  Header,
  Footer,
  SideMenu,
  ProductSideMenu,
  MorganHeader,
};
