import Styles from "./styles";
import { layout } from "components";
import { useLanguage } from "hooks";

// img
import ImgClient01 from "resources/image/companyIntroduction/client/img_client01.jpg";
import ImgClient02 from "resources/image/companyIntroduction/client/img_client02.jpg";
import ImgClient03 from "resources/image/companyIntroduction/client/img_client03.jpg";
import ImgClient04 from "resources/image/companyIntroduction/client/img_client04.jpg";
import ImgClient01Logo01 from "resources/image/companyIntroduction/client/img_client01_logo01.png";
import ImgClient01Logo02 from "resources/image/companyIntroduction/client/img_client01_logo02.png";
import ImgClient01Logo03 from "resources/image/companyIntroduction/client/img_client01_logo03.png";
import ImgClient01Logo04 from "resources/image/companyIntroduction/client/img_client01_logo04.png";
import ImgClient01Logo05 from "resources/image/companyIntroduction/client/img_client01_logo05.png";
import ImgClient01Logo06 from "resources/image/companyIntroduction/client/img_client01_logo06.png";
import ImgClient01Logo07 from "resources/image/companyIntroduction/client/img_client01_logo07.png";
import ImgClient01Logo08 from "resources/image/companyIntroduction/client/img_client01_logo08.png";
import ImgClient02Logo01 from "resources/image/companyIntroduction/client/img_client02_logo01.png";
import ImgClient02Logo02 from "resources/image/companyIntroduction/client/img_client02_logo02.png";
import ImgClient02Logo03 from "resources/image/companyIntroduction/client/img_client02_logo03.png";
import ImgClient02Logo04 from "resources/image/companyIntroduction/client/img_client02_logo04.png";
import ImgClient02Logo05 from "resources/image/companyIntroduction/client/img_client02_logo05.png";
import ImgClient02Logo06 from "resources/image/companyIntroduction/client/img_client02_logo06.png";
import ImgClient03Logo01 from "resources/image/companyIntroduction/client/img_client03_logo01.png";
import ImgClient03Logo02 from "resources/image/companyIntroduction/client/img_client03_logo02.png";
import ImgClient03Logo03 from "resources/image/companyIntroduction/client/img_client03_logo03.png";
import ImgClient03Logo04 from "resources/image/companyIntroduction/client/img_client03_logo04.png";
import ImgClient03Logo05 from "resources/image/companyIntroduction/client/img_client03_logo05.png";
import ImgClient03Logo06 from "resources/image/companyIntroduction/client/img_client03_logo06.png";
import ImgClient03Logo07 from "resources/image/companyIntroduction/client/img_client03_logo07.png";
import ImgClient04Logo01 from "resources/image/companyIntroduction/client/img_client04_logo01.png";
import ImgClient04Logo02 from "resources/image/companyIntroduction/client/img_client04_logo02.png";
import ImgClient04Logo03 from "resources/image/companyIntroduction/client/img_client04_logo03.png";
import ImgClient04Logo04 from "resources/image/companyIntroduction/client/img_client04_logo04.png";
import ImgClient04Logo05 from "resources/image/companyIntroduction/client/img_client04_logo05.png";
import ImgClient04Logo06 from "resources/image/companyIntroduction/client/img_client04_logo06.png";
import ImgClient04Logo07 from "resources/image/companyIntroduction/client/img_client04_logo07.png";

function Client() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const titleData = {
    h2: languageQuarter ? "주요 고객사" : "Our Customers",
    title: (languageQuarter ? 
      <>
        발전, 석유가스, 상하수도, 원자력 등 <br />
        국내외 기간산업 시장에 진출
      </> : <>
        Currently we have domestic and international market for power generation,
        oil and gas, water and sewage and nuclear energy sectors.
      </>
    ),
    description: (languageQuarter ? 
      <>
        ㈜에너토크는 국내 뿐 아니라 20개 개국의 국가에서 발전분야, 수처리분야,
        석유가스분야, 원자력 등의 50여 개의 글로벌 고객사와 협업을 통해 성장하고
        있습니다. <br className="pcOnly" />
        글로벌 경쟁사와 동일한 품질의 제품을 내부의 혁신을 통한 원가절감으로
        경쟁력 있는 가격과 서비스로 당당히 글로벌 시장에서 경쟁하고 있습니다.
      </> : <>
        Enertork is growing through collaboration with over 50 global clients in various
        business fields not only domestically but also in 20 different countries.
        Also, we compete in the global market with competitive prices and services,
        offering products of the same quality as our global competitors. Through internal
        innovation leading to cost savings, we maintain competitiveness in the global market.
      </>
    ),
  };

  const imgList = [ImgClient01, ImgClient02, ImgClient03, ImgClient04];

  const clientList = [
    {
      id : "client_step_01" ,
      title: languageQuarter ? "석유, 가스 분야" : "Oil and Gas Sector",
      description: (languageQuarter ? 
        <>
          국내 고객사로서 한국석유공사, 한국가스공사, SK에너지, S-Oil이
          대표적이며, <br className="pcOnly" />
          해외 고객사로서 <br className="mbOnly" />
          이란 가스공사, 이란 석유공사, 아부다비 석유공사, 쿠웨이트 석유공사 등이 대표적입니다.
          <br className="pcOnly" />
          안전을 최우선으로 하는 석유, 가스 분야에서
          고객으로부터 신뢰할 수 있는 제품과 서비스라는 호평을 받고 있으며,{" "}
          <br className="pcOnly" />
          지금까지의 기술력을 바탕으로 산유국이 많이 분포된 지역에서의 마케팅을
          강화할 예정입니다.
        </> : <>
          As domestic clients, we work with prominent companies such as Korea National Oil Corporation, Korea Gas Corporation, SK Energy,
          and S-Oil and Internationally, our representative clients include Iran Gas Company, National Iranian Oil Company,
          Abu Dhabi National Oil Company, and Kuwait Petroleum Corporation.
          We have been recognized by customers in the oil and gas sector for prioritizing safety and providing
          trustworthy products and services. Building on our expertise, we plan to strengthen marketing efforts
          in regions with a significant presence of oil-producing countries.
        </>
      ),
      logoList: [
        ImgClient01Logo01,
        ImgClient01Logo02,
        ImgClient01Logo03,
        ImgClient01Logo04,
        ImgClient01Logo05,
        ImgClient01Logo06,
        ImgClient01Logo07,
        ImgClient01Logo08,
      ],
    },
    {
      id : "client_step_02" ,
      title: languageQuarter ? "발전 분야" : "Power plant Sectors",
      description: (languageQuarter ? 
        <>
          국내 고객사로서 한국전력, 남동발전, 한국수력원자력이 대표적이며, 해외
          고객사로서 일본 MHPS, 베트남 전력, 사우디아라비아 전력 등이
          대표적입니다. <br className="pcOnly" />
          발전분야는 우리의 미래를 위한 기후위기를 극복하기 위한 전환점을
          맞이하고 <br className="mbOnly" />
          있습니다. <br className="pcOnly" />
          기존의 화력발전을 넘어 친환경에너지 <br className="mbOnly" />
          기반의 발전사업에 중점을 두어 시장확대와 더불어{" "}
          <br className="mbOnly" />
          이에 더욱 적합한 제품과 서비스 개발에 심혈을 기울이고 있습니다.{" "}
          <br className="pcOnly" />
          특히, 수자원이 풍부한 동남아지역과 남미지역 시장으로 확대를 위해
          고객과의 협력을 강화하고 있으며, 더욱 적극적인 공략을 위해 Joint
          Venture, <br className="pcOnly" />
          지사 설립 등을 고려하고 있습니다.
        </> : <>
          As domestic clients, we collaborate with prominent companies such as Korea Electric Power Corporation (KEPCO),
          Korea South-East Power Co., Ltd. (KOEN), and Korea Hydro & Nuclear Power Co., Ltd. (KHNP). Internationally,
          our representative clients include Japan's MHPS (Mitsubishi Hitachi Power Systems), Vietnam Electricity (EVN), and Saudi Electricity Company.
          The power generation business sector is at a turning point to overcome the climate crisis for the future for us.
          To overcome the crisis, we are focusing on environmentally friendly energy-based power generation projects beyond the conventional
          thermal power generation methods. We are wholeheartedly dedicated to the development of products and services that are more suitable for this initiative
          In particular, to expand into markets in water-rich Southeast Asia and South America, we are strengthening collaboration
          with customers and In pursuit of a more proactive approach, we are considering options such as Joint Ventures and establishing branches.
        </>
      ),
      logoList: [
        ImgClient02Logo01,
        ImgClient02Logo02,
        ImgClient02Logo03,
        ImgClient02Logo04,
        ImgClient02Logo05,
        ImgClient02Logo06,
      ],
    },
    {
      id : "client_step_03" ,
      title: languageQuarter ? "상하수도 분야" : "Waterworks sector",
      description: (languageQuarter ? 
        <>
          국내 고객사로서 수자원공사, 농어촌공사 및 각 지자체가 대표적이며, 해외
          고객사로서 태국 지방상수도공사, 오만 수자원공사, 이스탄불 수처리공사,
          아부다비 폐수처리공사, <br className="pcOnly" />
          두바이 정부 등이 대표적입니다. 수처리분야는 식수, 농수, 폐수 등 인류의
          가장 기본권에 대한 사업영역으로 개발도상국 이하의 모든 국가의 보편적
          기본 복지를 생각하며 사업을 영위하고 있습니다. 깨끗한 물의 안정적
          공급, 오염된 물의 안전한 처리를 위해 에너토크는 항상 앞장서고
          있습니다.
        </> : <>
          As domestic clients, we collaborate with representative organizations such as the Korea Water Resources Corporation,
          the Rural Development Administration, and various local governments. Internationally, our notable clients include
          Thailand Provincial Waterworks Authority, Oman Water Resources Authority, Istanbul Water and Sewerage Administration,
          Abu Dhabi Sewerage Services Company, and the Dubai Government.
          The water treatment sector is an area of business that addresses the fundamental rights of humanity, including clean drinking water,
          agricultural water, and wastewater. We conduct our business with the consideration of providing universal basic welfare for all countries,
          especially those in the developing world.
          To ensure the stable supply of clean water and the safe treatment of contaminated water, Enerterk always takes a leading role.
        </>
      ),
      logoList: [
        ImgClient03Logo01,
        ImgClient03Logo02,
        ImgClient03Logo03,
        ImgClient03Logo04,
        ImgClient03Logo05,
        ImgClient03Logo06,
        ImgClient03Logo07,
      ],
    },
    {
      id : "client_step_04" ,
      title: languageQuarter ? "기타 분야" : "Other Sectors",
      description: (languageQuarter ? 
        <>
          국내 고객사로서 삼성엔지니어링, 포스코, 두산중공업, 지역난방공사가
          대표적이며, 해외 고객사로서 일본 코베철강, 에미레이트 알미늄, 이집트
          알칼리 슈가 등이 대표적입니다. <br className="pcOnly" />
          국내 EPC기업과의 협업을 통해 원자력 발전소, 각종 생산시설, 처리시설,
          프랜테이션 등에 공급하고 있으며, 해외기업의 공장 등의 원료 프로세스
          시설 등에 공급하고 있습니다. <br className="pcOnly" />
          기존 사업영역을 넘어 액추에이터가 필요로 하는 분야를 끊임없이 발굴,
          도전하고 있으며, 이를 통해 우리의 기술력을 증명하고 있습니다.
        </> : <>
          As domestic clients, we collaborate with representative companies such as Samsung Engineering, POSCO,
          Doosan Heavy Industries, and Korea District Heating Cororation. Internationally, our notable clients
          include Japan's Kobe Steel, Emirates Aluminium, and Egypt Al Khaleej Sugar etc.
          We supply our products to domestic EPC companies for various facilities such as nuclear power plants,
          production facilities, treatment facilities, and plantations and additionally, we provide our products
          for raw material processing facilities in overseas companies' factories and other industrial facilities through collaboration.
          We are constantly exploring and challenging new business fields that require actuators beyond our
          existing business areas. Through these efforts, we are demonstrating the extent of our technological capabilities.
        </>
      ),
      logoList: [
        ImgClient04Logo01,
        ImgClient04Logo02,
        ImgClient04Logo03,
        ImgClient04Logo04,
        ImgClient04Logo05,
        ImgClient04Logo06,
        ImgClient04Logo07,
      ],
    },
  ];

  return (
    <Styles.Container>
      <layout.TitleContainer data={titleData} />

      <div className="clientSection">
        <ul className="imgListWrap">
          {imgList.map((item, index) => {
            return (
              <li className="imgList" key={"imgList" + index}>
                <img src={item} alt="clientImg" />
              </li>
            );
          })}
        </ul>

        <ul className="client">
          {clientList.map((item, index) => {
            return (
              <li id={item.id} className="clientList" key={"clientList" + index}>
                <p className="title">{item.title}</p>

                <p className="description">{item.description}</p>

                <ul className="logoWrap">
                  {item.logoList.map((current, idx) => {
                    return (
                      <li className="logo" key={"logo" + idx}>
                        <img src={current} alt="logo" />
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
    </Styles.Container>
  );
}

export default Client;
