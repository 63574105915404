import Styles from "./styles";
import components from "./components";
import { useState } from "react";
import { useLanguage } from "hooks";

// img
import IconClose from "resources/image/icon/icon_close.svg";

function FindPwModal({ modalClose }) {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const [result, setResult] = useState("");

  return (
    <Styles.Container>
      <div className="modalInner">
        <button
          type="button"
          className="closeButton"
          onClick={() => {
            modalClose();
          }}
        >
          <img src={IconClose} alt="IconClose" />
        </button>

        <p className="title">{languageQuarter ? "비밀번호 찾기" : "Find a password"}</p>

        {result ? (
          <components.FindPwResult
            modalClose={modalClose}
            language={languageQuarter}
          />
        ) : (
          <components.FindPwForm
            result={result}
            setResult={setResult}
            language={languageQuarter}
          />
        )}
      </div>

      <div className="overlay"></div>
    </Styles.Container>
  );
}

export { FindPwModal };

export default FindPwModal;
