import styled from "styled-components";

const Container = styled.div`
  padding: 80px 0 200px;
  min-height: 100vh;

  & .inner {
    display: flex;
    gap: 80px;

    & .narrowInner {
      & .title {
        padding-bottom: 20px;
        font-size: 36px;
        font-weight: 700;
        letter-spacing: normal;
        border-bottom: 3px solid var(--black);
      }
    }
  }

  @media (max-width: 1740px) {
    padding: calc(100vw * (80 / 1740)) 0 calc(100vw * (200 / 1740));

    & .inner {
      gap: calc(100vw * (80 / 1740));

      & .narrowInner {
        & .title {
          padding-bottom: calc(100vw * (20 / 1740));
          font-size: calc(100vw * (36 / 1740));
          border-bottom: calc(100vw * (3 / 1740)) solid var(--black);
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: calc(100vw * (50 / 375)) 0 calc(100vw * (150 / 375));

    & .inner {
      flex-direction: column;
      width: 100%;
      gap: calc(100vw * (50 / 375));

      & .narrowInner {
        & .title {
          padding-bottom: calc(100vw * (20 / 375));
          font-size: calc(100vw * (24 / 375));
          border-bottom: calc(100vw * (3 / 375)) solid var(--black);
        }
      }
    }
  }
`;

export { Container };

export default { Container };
