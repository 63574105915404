import styled from "styled-components";

const Container = styled.div`
  & .imgListWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 41px 50px;
    margin-bottom: 100px;

    & .imgList {
      width: 640px;
      height: 430px;
    }
  }

  & .clientList {
    &:not(:last-child) {
      margin-bottom: 80px;
    }

    & .title {
      margin-bottom: 20px;
      font-size: 36px;
      font-weight: bold;
      color: var(--main);
      line-height: 1.38;
    }

    & .description {
      margin-bottom: 50px;
      line-height: 1.6;
      word-break: keep-all;
    }

    & .logoWrap {
      display: flex;
      gap: 30px;
    }

    & .logo {
      width: 130px;
      height: 70px;
    }
  }

  @media (max-width: 1740px) {
    & .imgListWrap {
      gap: calc(100vw * (41 / 1740)) calc(100vw * (50 / 1740));
      margin-bottom: calc(100vw * (100 / 1740));

      & .imgList {
        width: calc(100vw * (640 / 1740));
        height: calc(100vw * (430 / 1740));
      }
    }

    & .clientList {
      &:not(:last-child) {
        margin-bottom: calc(100vw * (80 / 1740));
      }

      & .title {
        margin-bottom: calc(100vw * (20 / 1740));
        font-size: calc(100vw * (36 / 1740));
      }

      & .description {
        margin-bottom: calc(100vw * (50 / 1740));
      }

      & .logoWrap {
        gap: calc(100vw * (30 / 1740));
      }

      & .logo {
        width: calc(100vw * (130 / 1740));
        height: calc(100vw * (70 / 1740));
      }
    }
  }

  @media (max-width: 768px) {
    & .imgListWrap {
      flex-direction: column;
      gap: calc(100vw * (20 / 375));
      margin-bottom: calc(100vw * (80 / 375));

      & .imgList {
        width: 100%;
        height: calc(100vw * (238 / 375));
      }
    }

    & .clientList {
      &:not(:last-child) {
        margin-bottom: calc(100vw * (80 / 375));
      }

      & .title {
        margin-bottom: calc(100vw * (20 / 375));
        font-size: calc(100vw * (24 / 375));
      }

      & .description {
        margin-bottom: calc(100vw * (30 / 375));
        line-height: 1.5;
      }

      & .logoWrap {
        flex-wrap: wrap;
        justify-content: center;
        gap: calc(100vw * (10 / 375)) calc(100vw * (20 / 375));
      }

      & .logo {
        width: calc(100vw * (130 / 375));
        height: calc(100vw * (70 / 375));
      }
    }
  }
`;

export { Container };

export default { Container };
