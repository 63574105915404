import Styles from "./styles";
import ScrollContainer from "react-indiana-drag-scroll";
import { layout } from "components";
import { useLanguage } from "hooks";
import { useMediaQuery } from "react-responsive";

// img
import ImgWord01 from "resources/image/companyIntroduction/management/img_word01.png";
import ImgWord01Mb from "resources/image/companyIntroduction/management/img_word01_mb.png";
import ImgWord02 from "resources/image/companyIntroduction/management/img_word02.png";
import ImgWord02Mb from "resources/image/companyIntroduction/management/img_word02_mb.png";
import ImgWord03 from "resources/image/companyIntroduction/management/img_word03.png";
import ImgWord03Mb from "resources/image/companyIntroduction/management/img_word03_mb.png";
import ImgExplain from "resources/image/companyIntroduction/management/img_explain.png";
import ImgExplainEn from "resources/image/companyIntroduction/management/img_explain_en.png";
import ImgExplainMb from "resources/image/companyIntroduction/management/img_explain_mb.png";
import ImgExplainMbEn from "resources/image/companyIntroduction/management/img_explain_mb_en.png";
import ImgNecessity from "resources/image/companyIntroduction/management/img_necessity.png";
import ImgNecessityEn from "resources/image/companyIntroduction/management/img_necessity_en.png";
import ImgNecessityMb from "resources/image/companyIntroduction/management/img_necessity_mb.png";
import ImgNecessityMbEn from "resources/image/companyIntroduction/management/img_necessity_mb_en.png";

function Management() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const titleData = {
    h2: languageQuarter ? "ESG 경영" : "ESG Management",
    title: languageQuarter ? "미래 인류를 위한 현재 인류의 따뜻한 선택, ESG" : "The warm choice of present humanity for the future of humanity, ESG",
    description: (languageQuarter ? 
      <>
        ESG 경영은 환경(Environmental), 사회(Social), 지배구조(Governance)
        이슈를 고려하여 삶터(E), 사람(S), 기업(G) 모두가 지속가능 하도록{" "}
        <br className="pcOnly" />
        경영을 <br className="mbOnly" />
        하는 것으로 전 세계의 국가와 기업들에게 뜨거운 화두입니다.
        <br />
        <br />
        ㈜에너토크도 국가기간산업에 기여하는 한 구성원이자 해외사업을 영위하는
        사업체로서의 의무적 관점을 넘어
        <br />더 안전한 환경 보전과 더 행복한 미래의 인류를 위해 ESG경영 도입에
        적극 동참하고 있습니다.
      </> : <>
        ESG management involves considering environmental (E), social (S),
        and governance (G) issues to ensure sustainable management for both the environment,
        people, and businesses. It is currently a hot topic for countries and companies worldwide.
        <br />
        <br />
        Enertork, beyond its obligatory role as a contributor to the national industrial sector and
        as a business engaged in overseas operations, actively participates in ESG anagement not only
        from an obligatory perspective but also to contribute to a safer environment and a happier future for humanity.
      </>
    ),
  };

  const isMobile = useMediaQuery({ query: "(max-width: 728px)" });

  const wordList = {
    pc: [ImgWord01, ImgWord02, ImgWord03],
    mb: [ImgWord01Mb, ImgWord02Mb, ImgWord03Mb],
  };

  return (
    <Styles.Container>
      <layout.TitleContainer data={titleData} />

      <ul className="word">
        {(isMobile ? wordList.mb : wordList.pc).map((item, index) => {
          return (
            <li className="wordList" key={"wordList" + index}>
              <img src={item} alt="wordkImg" />
            </li>
          );
        })}
      </ul>

      <section className="explainSection">
        <div id="esg_step_01" className="sectionTitle">
          <p className="color">{languageQuarter ? "ESG 경영이란?" : "ESG Management"}</p>
          <p className="sectionDescription">
            {languageQuarter ? <>
              Corporate Social Responsibility, <br />
              기업의 사회적 책임
            </> : <>
              Corporate Social Responsibility
            </>}
          </p>
        </div>

        <p className="description">
          {languageQuarter ? <>
            기존 CSR(Corporate Social Responsibility, 기업의 사회적 책임)은 ‘하면
            좋은 것’이나, <br className="mbOnly" />
            ESG는 글로벌 기업 시민으로서 ‘해야 하는 것’
            <br className="mbOnly" />
            으로 전환되어 <br className="pcOnly" />
            새로운 규범(New Normal)으로 급속 확산 중에 있습니다.
          </> : <>
            While traditional Corporate Social Responsibility (CSR) was seen as ‘NICE TO DO,
            but ESG managmen has transitioned into a ‘MUST-DO' for global corporate citizens,
            rapidly spreading as the new normal.
          </>}
        </p>

        <div className="img">
          <img className="pcOnly" src={languageQuarter ? ImgExplain : ImgExplainEn} alt="ImgExplain" />
          <img className="mbOnly" src={languageQuarter ? ImgExplainMb : ImgExplainMbEn} alt="ImgExplain" />
        </div>

        <p className="description">
          {languageQuarter ? <>
            ESG경영은 한시적 Trend가 아닌 기업경영의 상수가 될 것으로 예상되며,
            EU, 선진국, 금융회사, NGO 등에서 각종 규범과 제도로 기업에{" "}
            <br className="pcOnly" />
            요구가 확대 될 것으로 예상됩니다. <br className="mbOnly" />
            이미 일부 글로벌 기업의 경우에는 그들의 <br className="mbOnly" />
            산업 생태계 내에 있는 하청기업들에게 요구가 시작되어 새로운‘수출 장벽,{" "}
            <br className="pcOnly" />
            대기업 협력 장벽, <br className="mbOnly" />
            금융기관 거래 장벽’으로 대두되고 있습니다.
          </> : <>
            ESG management is expected to become a constant in corporate governance rather than a temporary
            trend. Anticipated demands on companies are expanding globally through various norms and regulations
            from the EU, advanced countries, financial institutions, NGOs, and other entities. Some global companies
            have already initiated requirements for subcontractors within their industrial ecosystems, presenting new
            challenges such as 'export barriers, collaboration barriers with major companies, and financial institution
            transaction barriers.
          </>}
        </p>

        <p className="description">
          {languageQuarter ? <>
            국내의 경우에도 2021년에 이미 자산 2조원 이상의 기업에게 ESG경영
            도입과 실천사항에 대해서 공시를 의무화 했으며,{" "}
            <br className="pcOnly" />
            2030년에는 전 코스피 상장사에게도 ESG 경영의 도입과{" "}
            <br className="mbOnly" />
            공시 의무를 예고하고 있습니다.
          </> : <>
            In Korea, as of 2021, companies with assets exceeding 2 trillion Korean Won are already obligated to disclose
            their adoption and implementation of ESG management. Additionally, there are announcements indicating that by 2030,
            all KOSPI-listed companies will be required to introduce and disclose ESG management practices.
          </>}
        </p>
      </section>

      <section className="necessitySection">
        <div id="esg_step_02" className="sectionTitle">
          <p className="color">{languageQuarter ? "도입 필요성" : "Importance of ESG Management"}</p>
          <p className="sectionDescription">
            {languageQuarter ? <>
              ESG경영은 일시적 유행이 아닌 “거대한 변화의 물결”과도{" "}
              <br className="mbOnly" />
              같습니다
            </> : <>
              ESG management is not a temporary trend but rather a 'Mega Trend.'
            </>}
          </p>
        </div>

        <p className="description">
          {languageQuarter ? <>
            지난 10여 년 간 ESG 경영에 대한 국가 간의 국제적 합의와 실천에 대한
            천명이 있었으며, 이제는 눈 앞에 다가온 상황이 되었습니다. <br />
            글로벌 공급망 상의 모든 기업들에게 ESG 경영의 도입과 실천은 선택이
            아닌 필수 입니다.
          </> : <>
            Over the past decade, there has been an international call for agreement and
            implementation on ESG management among nations but  now, the situation is imminent.
            For all companies in the global supply chain, the adoption and implementation
            of ESG management are not choices but necessities.
          </>}
        </p>

        <div className="imgWrap">
          <ScrollContainer className="scroll-container">
            <div className="img">
              <img className="pcOnly" src={languageQuarter ? ImgNecessity : ImgNecessityEn} alt="ImgNecessity" />
              <img className="mbOnly" src={languageQuarter ? ImgNecessityMb : ImgNecessityMbEn} alt="ImgNecessity" />
            </div>
          </ScrollContainer>

          <layout.ScrollGuide />
        </div>

        <p className="description">
          {languageQuarter ? <>
            (주)에너토크는 이러한 시대적 흐름과 사회적 요구에 부응하여 사업적
            성과뿐 아니라 환경, 사회적 책임, 지배구조 등 <br />
            ESG경영을 통해 이해관계자의 기대에 부합하고 사회에 대한 기여를 높여
            가겠습니다.
          </> : <>
            Enertork Corporation will strive to meet the expectations of stakeholders and contribute to society not only through
            business performance but also through ESG management including environmental, social responsibility, and governance,
            in line with these societal trends and demands.
          </>}
        </p>
      </section>
    </Styles.Container>
  );
}

export default Management;
