import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 450px;
  font-size: 18px;
  font-weight: bold;
  color: var(--gray02);

  &.search {
    height: 300px;
    font-size: 20px;
    color: var(--gray01);
  }

  @media (max-width: 1740px) {
    height: calc(100vw * (450 / 1740));
    font-size: calc(100vw * (18 / 1740));

    &.search {
      height: calc(100vw * (300 / 1740));
      font-size: calc(100vw * (20 / 1740));
    }
  }

  @media (max-width: 768px) {
    height: calc(100vw * (450 / 375));
    font-size: calc(100vw * (14 / 375));

    &.search {
      height: calc(100vw * (450 / 375));
      font-size: calc(100vw * (14 / 375));
    }
  }
`;

export { Container };

export default { Container };
