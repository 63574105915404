import Styles from "./styles";
import utils from "utils";
import { common, ui } from "components";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// img
import Logo from "resources/image/icon/logo_gray.svg";
import useMorgan from "hooks/useMorgan";

function MorganPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState({});
  const [filter, setFilter] = useState();

  const morganInfo = useMorgan(location.search && {
    ERP_listOption: filter?.type === "ERP" && filter,
    EPS_listOption: filter?.type === "EPS" && filter,
  });
  const data = filter?.type === "ERP"
    ? morganInfo.ERP_listQuery?.data || []
    : morganInfo.EPS_listQuery?.data || []

  const tabList = [
    { text: "ERP" },
    { text: "EPS" },
  ];

  const inputData = [
    {
      name: "location",
      value: keyword.location,
      labelText: "건명",
      placeholder: "건명을 입력해 주세요.",
    },
    {
      name: "ordFirstNumber",
      value: keyword.ordFirstNumber,
      placeholder: "00",
      type: "number",
    },
    {
      name: "ordSecondNumber",
      value: keyword.ordSecondNumber,
      placeholder: "0000",
      type: "number",
    },
  ];

  const onChange = (e) => {
    const { name, value } = e.target;
    if (String(name).includes("ord") && value == "-") return;
    if (name === "ordFirstNumber" && value.length > 2) return;
    if (name === "ordSecondNumber" && value.length > 4) return;
    setKeyword((e) => {
      return { ...e, [name]: value };
    });
  };

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    let f = {
      page: param.page || 1,
      recordSize: 30,
      pageSize: 5,
    };
    if (param.name) f.location = param.name;
    if (param.ord) f.ord = param.ord;
    if (param.type) f.type = param.type;

    setFilter({ ...f });
  }, [location]);

  useEffect(() => {
    let param = utils.etc.getSearchParam();
    setKeyword({
      type: param.type || "ERP",
      location: decodeURIComponent(param.name || ""),
      ordFirstNumber: param.ord?.split("-")[0],
      ordSecondNumber: param.ord?.split("-")[1],
    })
  }, [location]);

  const search = () => {
    navigate(utils.etc.getQueryParamUrl({
      type: keyword.type, name: encodeURIComponent(keyword.location), page: 1,
      ord: (keyword.ordFirstNumber || "") + (keyword.ordSecondNumber ? `-${keyword.ordSecondNumber}` : "") || undefined,
    }, false),
      { state: location.state }
    )
  }

  return (
    <Styles.Container>
      <common.MorganHeader
        backEvent={() => { navigate(location.state) }}
      />

      <div className="thinInner">
        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>

        <ul className="tabWrap">
          {tabList.map((item, index) => {
            return (
              <li
                className={"tabList" + (keyword?.type === item.text ? " on" : "")}
                key={"tabList" + index}
                onClick={() => {
                  setKeyword(e => { return { ...e, type: item.text } })
                }}
              >
                {item.text}
              </li>
            );
          })}
        </ul>

        <section className="specificationSection">
          <h2>제작사양서</h2>

          <ul>
            <li>
              <ui.input.BasicInput data={inputData[0]} onChange={onChange} onKeyUp={search}/>
            </li>

            <li>
              <label>ORD</label>
              <div className="inputContainer">
                <ui.input.BasicInput data={inputData[1]} onChange={onChange} onKeyUp={search}/>
                <ui.input.BasicInput data={inputData[2]} onChange={onChange} onKeyUp={search}/>
              </div>
            </li>
          </ul>

          <ui.button.BasicButton
            buttonText={"검색"}
            onClick={search}
          />
        </section>

        <section className="searchReasultSection">
          <h2>검색 결과</h2>
          {location.search ? (
            data?.list?.length > 0 ? (
              <>
                <ul>
                  {data?.list?.map((item, index) => {
                    return (
                      <li
                        className="morganSearchList"
                        key={"morganSearchList" + index}
                      >
                        <Link
                          to={`/morgan/detail/${filter.type}/${item.작업번호}`}
                          state={location.state}
                        >
                          <div className="top">
                            <div className="number">{item.순번}</div>
                            <p className="ord">{item.작업번호}</p>
                          </div>

                          <p className="actuatorName">{item.건명}</p>
                        </Link>
                      </li>
                    );
                  })}
                </ul>

                <div className="pagination">
                  <ui.button.Pagination
                    page={data.pagination?.page}
                    list={data.pagination?.page_list}
                    maxPage={data.pagination?.totalPageCount}
                  />
                </div>
              </>
            ) : (
              <p className="emptyText">검색 결과가 없습니다.</p>
            )
          ) : (
            <p className="emptyText">검색어를 입력해 주세요.</p>
          )}
        </section>
      </div>
    </Styles.Container>
  );
}

export default MorganPage;
