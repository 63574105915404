import { useState } from "react";
import Styles from "./styles";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom.svg";

function InputSelect({ data, onChange }) {
  const { name, value, list } = data;
  const [show,setShow] = useState(false);

  return (
    <Styles.Container>
      <input
        // type="number"
        id={name}
        name={name}
        // placeholder="00"
        readOnly={true}
        value={value}
        onClick={()=>{
          setShow(e=> !e);
        }}
        onChange={onChange}
      />

      <div className="icon">
        <img src={IconArrowBottom} alt="IconArrowBottom" />
      </div>
      {
        show ? 
        <ul className="option" >
          {list?.map((item, index) => {
            return (
              <li
                className="optionList"
                key={"optionList" + index}
                onClick={()=>{
                  setShow(e => !e);
                  onChange(item);
                }}
              >
                {item.title}
              </li>
            );
          })}
        </ul>
        :
        null
      }
    </Styles.Container>
  );
}

export default InputSelect;
