import Styles from "./styles";

function ServiceCheckbox({ data, onChange, checked, suffix }) {
  const { id, show_yn } = data;

  return (
    <>
      <Styles.Input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={show_yn === "N"}
      />

      <Styles.Label htmlFor={id}>
        <span className="checkbox"></span>

        <span className="labelText">{data["title" + suffix]}</span>
      </Styles.Label>
    </>
  );
}

export default ServiceCheckbox;
