import Styles from "./styles";
import { Outlet } from "react-router-dom";
import { useLanguage } from "hooks";
import { useMediaQuery } from "react-responsive";
import { common, layout } from "components";

// img
import BgMainBanner from "resources/image/investment/bg_main_banner.jpg";
import BgMainBannerMb from "resources/image/investment/bg_main_banner_mb.jpg";

function InvestmentPage() {
  const languageInfo = useLanguage();
  const languageQuarter = languageInfo.suffix === "";

  const isMobile = useMediaQuery({ query: "(max-width: 728px)" });

  const data = {
    img: isMobile ? BgMainBannerMb : BgMainBanner,
    title: languageQuarter ? "투자 정보" : "Investment information",
    firstDepthList: languageQuarter ? [
      {
        text: "주가 정보",
        link: "/investment/stock",
      },
      {
        text: "공시 정보",
        link: "/investment/public",
      },
      {
        text: "주주총회 정보",
        link: "/investment/shareholder",
      },
      {
        text: "내부정보 규정관리",
        link: "/investment/internal",
      },
      {
        text: "결산공고",
        link: "/investment/settlement",
      },
    ] : [
      {
        text: "Stock price information",
        link: "/investment/stock",
      },
      {
        text: "Disclosure information",
        link: "/investment/public",
      },
    ],
  };
  return (
    <>
      <common.Header />
      <Styles.Container>
        <layout.Banner data={data} />

        <div className="inner">
          <common.SideMenu data={data} />

          <div className="narrowInner">
            <Outlet />
          </div>
        </div>
      </Styles.Container>
      <common.Footer />
    </>
  );
}

export default InvestmentPage;
